import {
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  TableBody,
  useTheme,
} from "@mui/material";
import "./board.scss";
import React, { useEffect, useState } from "react";
import RemoveBoardMemberModal from "../../../Component/Modals/AssociationModals/Board/RemoveBoardMemberModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import {
  getAllBoard,
  getGeneralMembers,
  removeBoardMember,
  GetBOIInformation,
} from "../../../Redux/Feature/Roster-slice";
import AddMemberModal from "../../../Component/Modals/AssociationModals/Board/AddMemberModal";
import BOIInformationModal from "../../../Component/Modals/AssociationModals/Board/BOIInformationModal";
import ProfileImageTemplate from "../../../Component/Common/ProfileImageTemplate/ProfileImageTemplate";
import { Clear, Search } from "@mui/icons-material";
import BoardSkeleton from "../../../Component/Skeletons/BoardSkeleton";
import { ERROR_MESSAGES } from "../../../Utils/utils";
import Toast from "../../../Component/Common/Toast/Toast";
import { useTranslation } from "react-i18next";
import BoardVotes from "./BoardVotes";
import DeleteIcon from "@mui/icons-material/Delete";

interface Member {
  ProfileImage: string;
  Name: string;
  Position: string;
  personId: string;
}

function Board() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [isAddMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [isBOISubmitModalOpen, setBOISubmitModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );
  const AllBoards = useSelector((state: RootState) => state?.Manage?.allBoards);

  const boardVoter = currentAssociation?.role === "board";
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";

  const isUSAssociation = currentAssociation?.country === "US";

  const openRemoveModal = (member: Member) => {
    setRemoveModalOpen(true);
    setSelectedMember(member);
  };

  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
    setSelectedMember(null);
  };

  const handleRemoveMember = async () => {
    setLoading(true);
    try {
      if (selectedMember) {
        const addDataObj = {
          associationId: currentAssociation?.associationId,
          personId: selectedMember?.personId,
        };
        const data = await dispatch(removeBoardMember(addDataObj));
        if (data?.payload?.statusCode === 200) {
          showToast(t("Board Member removed successfully"), "success");
          dispatch(getGeneralMembers(currentAssociation?.associationId));
        } else if (data.error) {
          handleError(data.payload.data);
        }
        if (!loading) {
          dispatch(getAllBoard(currentAssociation?.associationId));
        }
      }
      closeRemoveModal();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpenBOIModal = () => {
    dispatch(GetBOIInformation(currentAssociation?.associationId));
    setBOISubmitModalOpen(true);
  };

  const handleBOISubmission = () => {
    setAddMemberModalOpen(true);
  };

  const handleAddMemberModalOpen = () => {
    setAddMemberModalOpen(true);
  };

  const handleAddMemberModalClose = () => {
    setAddMemberModalOpen(false);
  };
  useEffect(() => {
    fetchAllBoards();
  }, [currentAssociation?.associationId]);

  const fetchAllBoards = async () => {
    setLoading(true);
    if (currentAssociation) {
      await dispatch(getAllBoard(currentAssociation?.associationId));
    }
    setLoading(false);
  };

  const boardMembersColumn = [
    {
      id: "profilePicture",
      label: t("Profile Image"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "name",
      label: t("Name"),
      width: "500px",
      minWidth: "500px",
      maxWidth: "500px",
    },
    {
      id: "Action",
      label: t("Action"),
      width: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
  ];

  const filteredColumns = isBoardRole
    ? boardMembersColumn
    : boardMembersColumn.filter((column) => column.id !== "Action");

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedMembers = stableSort(AllBoards, getComparator(order, orderBy));

  const filteredBoardMember = sortedMembers.filter((board: any) => {
    if (!board) {
      return false;
    }
    const { name } = board;
    const lowerCaseSearch = search?.toLowerCase();
    return lowerCaseSearch
      ? name?.toLowerCase().includes(lowerCaseSearch)
      : true;
  });

  const paginatedData = filteredBoardMember.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClearSearch = () => {
    setSearch("");
  };

  const customBoardNameCell = (member: any) => {
    const displayName: any =
      typeof member.name === "undefined" ||
      member.name === "undefined undefined" ||
      member.name === null
        ? t("Name not set")
        : member.name;

    return displayName;
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  if (loading) {
    return <BoardSkeleton />;
  }
  return (
    <Box className="board board-wrapper board-member-title">
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Box className="boardBox__title">
        <Typography className="boardBox__title__heading page_title">
          {t("Board Members")}
        </Typography>
      </Box>
      <AddMemberModal
        isOpen={isAddMemberModalOpen}
        onClose={handleAddMemberModalClose}
      />
      <BOIInformationModal
        isOpen={isBOISubmitModalOpen}
        onClose={() => setBOISubmitModalOpen(false)}
        onRemove={handleBOISubmission}
      />
      <Box
        className="TableConnect board_wrapper board-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="board-wrap gridTable">
            <Box
              sx={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <OutlinedInput
                sx={{
                  margin: "0 !important",
                  border: "unset !important",
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.activeColor}`,
                  },
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                    },
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                      borderWidth: "1px",
                    },
                  },
                }}
                placeholder={t("Search")}
                value={search}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    {search ? (
                      <IconButton
                        onClick={handleClearSearch}
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Clear
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Search
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
              {isBoardRole && (
                <Button
                  className="btn add-member btn-white"
                  variant="outlined"
                  onClick={handleAddMemberModalOpen}
                >
                  {t("Add Member")}
                </Button>
              )}
              {/* {isBoardRole && isUSAssociation && (
                <Button
                  className="btn add-member btn-white"
                  variant="outlined"
                  onClick={() => {
                    handleOpenBOIModal();
                  }}
                >
                  {t("Refresh Beneficial Owner Information")}
                </Button>
              )} */}
            </Box>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {filteredColumns.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            {t("No Board member Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedData.map((row: any) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&. MuiTableCell-root": {
                                height: "70px",
                              },
                            }}
                          >
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              <ProfileImageTemplate
                                profilePicture={row.profilePicture}
                              />
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {customBoardNameCell(row)}
                            </TableCell>
                            {isBoardRole && (
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Button
                                  onClick={() => openRemoveModal(row)}
                                  sx={{
                                    color: `${theme.palette.text.primary}`,
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredBoardMember.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
      {boardVoter && <BoardVotes isBoard={boardVoter} />}
      <RemoveBoardMemberModal
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onRemove={handleRemoveMember}
      />
    </Box>
  );
}

export default Board;
