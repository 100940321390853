import { Link } from "react-router-dom";

export const ERROR_MESSAGES = {
  UNAUTHORIZED: "Unauthorized: You are not authorized to perform this action.",
  FORBIDDEN: "Forbidden: You don't have permission to access this resource.",
  NOT_FOUND: "Not Found: The requested resource was not found.",
  INTERNAL_SERVER_ERROR:
    "Internal Server Error: Something went wrong on the server.",
  UNEXPECTED_ERROR: "An unexpected error occurred. Please try again later.",
};

export const MEMBER_TYPE = {
  BOARD: "board",
  GENERAL: "general",
  TENANT: "tenant",
  MANAGER: "manager",
  VENDOR: "vendor",
};
export const PRIORITY = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};

export const PRICE_TIERS = {
  STANDARD: "standard_",
  PREMIUM: "premium_",
  ELITE: "elite_",
};

export const verificationTypes: any = [
  { key: "37", value: "State/Local/Tribe issued ID" },
  { key: "38", value: "State issued driver's license," },
  { key: "39", value: "US Passport" },
  { key: "40", value: "Foreign Passport" },
];

export const translations: any = [
  { key: "en", value: "English" },
  { key: "es", value: "Spanish" },
  // { key: "pt", value: "Portuguese" },
];

export const letters: any = [
  { key: "-", value: "-" },
  { key: "a", value: "A" },
  { key: "b", value: "B" },
  { key: "c", value: "C" },
  { key: "d", value: "D" },
  { key: "e", value: "E" },
  { key: "f", value: "F" },
  { key: "g", value: "G" },
  { key: "h", value: "H" },
  { key: "i", value: "I" },
  { key: "j", value: "J" },
  { key: "k", value: "K" },
  { key: "l", value: "L" },
  { key: "m", value: "M" },
  { key: "n", value: "N" },
  { key: "o", value: "O" },
  { key: "p", value: "P" },
  { key: "q", value: "Q" },
  { key: "r", value: "R" },
  { key: "s", value: "S" },
  { key: "t", value: "T" },
  { key: "u", value: "U" },
  { key: "v", value: "V" },
  { key: "w", value: "W" },
  { key: "x", value: "X" },
  { key: "y", value: "Y" },
  { key: "z", value: "Z" },
];

export const COUNTRY_CODES: any = [
  { key: "AT", value: "Austria", currencySymbol: "€" },
  { key: "AU", value: "Australia", currencySymbol: "A$" },
  { key: "BE", value: "Belgium", currencySymbol: "€" },
  { key: "BG", value: "Bulgaria", currencySymbol: "лв" },
  { key: "BR", value: "Brazil", currencySymbol: "R$" },
  { key: "CA", value: "Canada", currencySymbol: "C$" },
  { key: "CH", value: "Switzerland", currencySymbol: "CHF" },
  { key: "CY", value: "Cyprus", currencySymbol: "€" },
  { key: "CZ", value: "Czech Republic", currencySymbol: "Kč" },
  { key: "DE", value: "Germany", currencySymbol: "€" },
  { key: "DK", value: "Denmark", currencySymbol: "kr" },
  { key: "EE", value: "Estonia", currencySymbol: "€" },
  { key: "ES", value: "Spain", currencySymbol: "€" },
  { key: "FI", value: "Finland", currencySymbol: "€" },
  { key: "FR", value: "France", currencySymbol: "€" },
  { key: "GB", value: "United Kingdom", currencySymbol: "£" },
  { key: "GR", value: "Greece", currencySymbol: "€" },
  { key: "HK", value: "Hong Kong", currencySymbol: "HK$" },
  { key: "HR", value: "Croatia", currencySymbol: "kn" },
  { key: "HU", value: "Hungary", currencySymbol: "Ft" },
  { key: "IE", value: "Ireland", currencySymbol: "€" },
  { key: "IT", value: "Italy", currencySymbol: "€" },
  { key: "JP", value: "Japan", currencySymbol: "¥" },
  { key: "LI", value: "Liechtenstein", currencySymbol: "CHF" },
  { key: "LT", value: "Lithuania", currencySymbol: "€" },
  { key: "LU", value: "Luxembourg", currencySymbol: "€" },
  { key: "LV", value: "Latvia", currencySymbol: "€" },
  { key: "MT", value: "Malta", currencySymbol: "€" },
  { key: "MX", value: "Mexico", currencySymbol: "MX$" },
  { key: "MY", value: "Malaysia", currencySymbol: "RM" },
  { key: "NL", value: "Netherlands", currencySymbol: "€" },
  { key: "NO", value: "Norway", currencySymbol: "kr" },
  { key: "NZ", value: "New Zealand", currencySymbol: "NZ$" },
  { key: "PL", value: "Poland", currencySymbol: "zł" },
  { key: "PT", value: "Portugal", currencySymbol: "€" },
  { key: "RO", value: "Romania", currencySymbol: "lei" },
  { key: "SE", value: "Sweden", currencySymbol: "kr" },
  { key: "SG", value: "Singapore", currencySymbol: "S$" },
  { key: "SI", value: "Slovenia", currencySymbol: "€" },
  { key: "SK", value: "Slovakia", currencySymbol: "€" },
  { key: "TH", value: "Thailand", currencySymbol: "฿" },
  { key: "US", value: "United States", currencySymbol: "$" },
  { key: "AE", value: "United Arab Emirates", currencySymbol: "د.إ" },
];

export const allowedFileTypes = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".txt",
  ".csv",
  ".ppt",
  ".pptx",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".zip",
  ".mp4",
  ".mp3",
  ".html",
  ".htm",
  ".json",
  ".xml",
];

export const imageFileExtensions: any = ["jpg", "jpeg", "png", "gif"];

export const bannerData = [
  {
    title: "Set Your Name",
    data: (
      <span>
        Go to <Link to="/setting">Settings</Link>
        <br />
        Enter your 'First Name Last Name' in Name
        <br />
        Click save!
      </span>
    ),
  },
  {
    title: "Add Common Assets",
    data: (
      <span>
        Go to the{" "}
        <Link to="/assets/common-assets-directory">
          Common Assets Directory
        </Link>
        <br />
        Click <em>Add Common Asset</em>
        <br />
        Enter your common assets specifications and click submit!
      </span>
    ),
  },
  {
    title: "Subscribe Association Units",
    data: (
      <span>
        Go to
        <Link to="/association/details"> Details</Link>
        <br />
        In the subscription row click <em>Manage</em>
        <br />
        Subscribe to Zayuk Safe Premium or Elite to register your units!
      </span>
    ),
  },
];
