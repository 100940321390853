import {
  Box,
  Typography,
  Drawer,
  useTheme,
  Popper,
  Grid,
  List,
  ListItemText,
  ListItem,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../Pages/Settings/setting.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getVote } from "../../Redux/Feature/MyAccount-slice";
import AddAttachmentModal from "../Modals/VotesModals/AddAttachmentModal";
import DownloadIcon from "@mui/icons-material/Download";

import { MEMBER_TYPE } from "../../Utils/utils";
ChartJS.register(ArcElement, Tooltip, Legend);

const CompletedDrawer = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    selectedVoteId,
    completedDrawer,
    toggleCompletedDrawer,
    showToast,
    owners,
    handleClick,
    isAddAttachmentModalOpen,
    closeAddVoteAttachmentModal,
    voteId,
  } = props;
  const selectedVote = useSelector(
    (state: RootState) => state?.Governance?.selectedVote
  );
  console.log("selectedVote", selectedVote);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const groupOptions = [
    { value: MEMBER_TYPE.BOARD, label: "Board" },
    { value: MEMBER_TYPE.GENERAL, label: "General" },
  ];
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  useEffect(() => {
    if (selectedVoteId) {
      console.log("selectedRow", selectedVoteId);
      fetchVote();
    }
  }, [selectedVoteId]);

  const fetchVote = async () => {
    console.log("selectedRow1", selectedVoteId);

    await dispatch(getVote(selectedVoteId));
  };
  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.style.display = "none";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={completedDrawer}
        onClose={() => toggleCompletedDrawer(false)}
        sx={{
          zIndex: "9",
          "& .MuiBackdrop-root": {
            opacity: "0 !important",
          },
        }}
      >
        <div
          className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
          style={{ width: "280px" }}
        >
          <Box className="sidebarVote__details" sx={{ marginLeft: "20px" }}>
            <div className="detailsSidebar__head">
              <Box
                className="sticky-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="cross"
                  onClick={() => toggleCompletedDrawer(false)}
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ marginLeft: "20px" }}
                  >
                    <strong>{t("Title")}:</strong>{" "}
                    {selectedVote?.title ? selectedVote.title : t("No Title")}
                  </Typography>
                </div>
              </Box>
              <Grid item></Grid>
            </div>
            <div className="detailsSidebar__content">
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    <strong>{t("Type")}:</strong>{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {selectedVote?.type ? t(selectedVote.type) : t("No Type")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    <strong>{t("Description")}:</strong>{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {selectedVote?.description
                      ? selectedVote.description
                      : t("No Description")}
                  </Typography>
                </Grid>
              </Grid>
              {selectedVote?.type === "Rule Change" && selectedVote?.rule && (
                <Grid container direction="row">
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      <strong>{t("Rule")}:</strong>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8} md={8} sm={6}>
                    <Typography variant="h5" gutterBottom>
                      {selectedVote.rule.title}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedVote.rule.text,
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {selectedVote?.type === "Assessment" && (
                <Grid container direction="row">
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      <strong>{t("Amount")}:</strong>{" "}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12} lg={3} md={6} sm={6}>
                    <Typography variant="h5" gutterBottom>
                      {selectedVote?.amount
                        ? formatter.format(selectedVote.amount)
                        : t("No Amount")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("Voting Dates")}:</strong>{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {selectedVote?.startDate
                      ? new Date(selectedVote.startDate).toLocaleDateString()
                      : t("No Start Date")}{" "}
                    -{" "}
                    {selectedVote?.endDate
                      ? new Date(selectedVote.endDate).toLocaleDateString()
                      : t("No End Date")}
                  </Typography>
                </Grid>
              </Grid>
              {selectedVote?.type === "Position Change" && (
                <Grid container direction="row">
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      <strong>{t("Persons")}:</strong>
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12} lg={3} md={6} sm={6}>
                    <List>
                      {selectedVote?.persons?.length > 0 ? (
                        selectedVote?.persons.map((person: any, index: any) => (
                          <ListItem key={index}>
                            <ListItemText primary={t(person.name)} />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText primary={t("No Persons")} />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("Ballot Counts")}:</strong>
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <List>
                    {selectedVote?.ballotCounts?.length > 0 ? (
                      selectedVote?.ballotCounts.map(
                        (ballot: any, index: any) => (
                          <ListItem key={index}>
                            <ListItemText
                              primary={t(ballot.name)}
                              secondary={ballot.count}
                            />
                          </ListItem>
                        )
                      )
                    ) : (
                      <ListItem>
                        <ListItemText primary={t("No Ballots")} />
                      </ListItem>
                    )}
                  </List>
                </Grid>
                {selectedVote?.attachments?.length > 0 && (
                  <Box sx={{ marginTop: 20 }}>
                    <Typography variant="h6" component="h6">
                      {t("Attachments")}
                    </Typography>
                    <List>
                      {selectedVote.attachments.map(
                        (attachment: any, index: number) => (
                          <ListItem key={index}>
                            <ListItemText primary={attachment.fileName} />
                            <Button
                              variant="outlined"
                              startIcon={<DownloadIcon />}
                              onClick={() =>
                                handleDownload(
                                  attachment.signedUrl,
                                  attachment.fileName
                                )
                              }
                            >
                              {t("Download")}
                            </Button>
                          </ListItem>
                        )
                      )}
                    </List>
                  </Box>
                )}
              </Grid>
            </div>
            {/* <Box>
              <div className="SectionBox">
                <div className="attachmentBtn_section">
                  <button
                    type="button"
                    className="attachmentBtn"
                    onClick={handleClick}
                  >
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M10.2702 1.75775C12.6117 -0.585841 16.4107 -0.585842 18.7538 1.7573C21.0369 4.04037 21.0954 7.70558 18.9294 10.0595L18.7413 10.2569L9.94127 19.0551L9.9047 19.0853C8.44346 20.4021 6.18946 20.3574 4.78208 18.95C3.46307 17.631 3.34095 15.5683 4.41573 14.1116C4.43908 14.0662 4.46733 14.0225 4.50054 13.9813L4.55409 13.9217L4.641 13.8339L4.78208 13.6861L4.785 13.6889L12.2207 6.23463C12.4867 5.96801 12.9033 5.94325 13.1972 6.16072L13.2814 6.23322C13.548 6.49913 13.5728 6.91576 13.3553 7.20967L13.2828 7.29388L5.68819 14.907C4.97056 15.7826 5.02044 17.0768 5.83783 17.8942C6.6669 18.7233 7.98655 18.7628 8.86233 18.0127L17.695 9.18221C19.4505 7.42457 19.4505 4.57532 17.6932 2.81796C15.9907 1.11552 13.2636 1.06232 11.4971 2.65836L11.3292 2.81796L11.3167 2.83228L1.78033 12.3686C1.48744 12.6615 1.01256 12.6615 0.71967 12.3686C0.453403 12.1023 0.429197 11.6857 0.647052 11.3921L0.71967 11.3079L10.2685 1.7573L10.2702 1.75775Z"
                        fill={`${theme.palette.text.primary}`}
                      />
                    </svg>
                    {t("Add Attachment")}
                  </button>
                </div>
              </div>
            </Box> */}
          </Box>
        </div>
      </Drawer>
      <AddAttachmentModal
        isOpen={isAddAttachmentModalOpen}
        onClose={closeAddVoteAttachmentModal}
        voteId={voteId ?? ""}
        attachments={selectedVote?.attachments}
      />
    </div>
  );
};

export default CompletedDrawer;
