import React, { useEffect, useState } from "react";
import { getAssociation, personDetails } from "../../Redux/Feature/Auth-slice";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { Tooltip, List, Avatar, CssBaseline } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GroupIcon from "@mui/icons-material/Group";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ContactsIcon from "@mui/icons-material/Contacts";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BalanceIcon from "@mui/icons-material/Balance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import "./menu.scss";
import { Logout, Settings } from "@mui/icons-material";
import ConfirmLogout from "../../Component/Modals/ConfirmLogout";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import RuleIcon from "@mui/icons-material/Rule";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import InfoIcon from "@mui/icons-material/Info";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BadgeIcon from "@mui/icons-material/Badge";
import PolicyIcon from "@mui/icons-material/Policy";
import MapIcon from "@mui/icons-material/Map";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MEMBER_TYPE, PRICE_TIERS } from "../../Utils/utils";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { current } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

import AssessmentIcon from "@mui/icons-material/Assessment";
import SavingsIcon from "@mui/icons-material/Savings";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRight: 0,
  borderLeft: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const userData = useSelector((state: any) => state.Auth.user);
  const pathname = window.location.pathname;
  const { t } = useTranslation();
  const userAssociations = useSelector(
    (state: any) => state.Auth.userAssociations
  );
  const testMode = JSON.parse(localStorage.getItem("testMode") || "false");

  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );

  const isUSAssociation = currentAssociation?.country === "US";

  const isGeneralRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER ||
    currentAssociation?.role === MEMBER_TYPE.GENERAL;

  React.useEffect(() => {
    getActivatedRoute();
  }, [pathname]);
  const getActivatedRoute = () => {
    const pathNameArray = pathname.split("/");
    setExpanded(pathNameArray[1]);
  };

  useEffect(() => {
    fetchPersonDetails();
  }, [userAssociations, currentAssociation?.associationId]);

  const fetchPersonDetails = async () => {
    await dispatch(personDetails(userData.email));
    if (currentAssociation?.associationId) {
      await dispatch(getAssociation(currentAssociation?.associationId));
    }
  };

  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleDrawerOpen = () => {
    document
      .getElementsByClassName("WrapperScreen_content")[0]
      .classList.add("mini-drawer-open");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    document
      .getElementsByClassName("WrapperScreen_content")[0]
      .classList.remove("mini-drawer-open");
    setOpen(false);
  };

  const isAuthorizedRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager" ||
    currentAssociation?.role === "general";

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{ "& .MuiDrawer-paper": { overflow: "hidden" } }}
      >
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {open && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <Box
          sx={{
            minHeight: open ? "calc(100vh - 310px)" : "calc(100vh - 264px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {userAssociations && userAssociations?.length > 0 && (
            <Box>
              <Accordion
                expanded={expanded === "account"}
                onChange={handleChange("account")}
                disableGutters={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ opacity: 0 }} />}
                  aria-controls="accountbh-content"
                  id="accountbh-header"
                >
                  <ListItem key={"My Account"} disablePadding>
                    <NavLink
                      to="/account"
                      className={({ isActive, isPending }) =>
                        isPending
                          ? "pending"
                          : isActive && open
                          ? "p-active-menu-outer"
                          : open
                          ? "p-in-active-menu-outer"
                          : "side-bar-menu-link"
                      }
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{ justifyContent: open ? "unset" : "right" }}
                        >
                          {userData ? (
                            <Avatar sx={{ width: 25, height: 25 }}>
                              {userData.email
                                ? userData.email[0].toUpperCase()
                                : ""}
                            </Avatar>
                          ) : (
                            <AccountCircleIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={t("My Account")}
                          sx={{ display: open ? "block" : "none" }}
                        />
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                </AccordionSummary>
              </Accordion>
              <Accordion
                expanded={expanded === "association"}
                onChange={handleChange("association")}
                disableGutters={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: theme.palette.text.primary }}
                    />
                  }
                  aria-controls="associationbh-content"
                  id="associationbh-header"
                >
                  <ListItem key={"Association"} disablePadding>
                    <ListItemButton>
                      <ListItemIcon
                        sx={{ justifyContent: open ? "unset" : "right" }}
                      >
                        {<GroupIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Association")}
                        sx={{ display: open ? "block" : "none" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails className="sub-list">
                  <List sx={{ width: "100%", maxWidth: 360, p: "0px" }}>
                    {/* {isBoardRole ? ( */}
                    {/* <> */}
                    <ListItem
                      key={t("Details")}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/association/details"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Details")
                          ) : (
                            <Tooltip
                              title={t("Details")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <InfoIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>

                    <ListItem
                      key={"Board"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/association/board"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Board")
                          ) : (
                            <Tooltip title={t("Board")} placement="right-start">
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <ContactsIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>

                    <ListItem
                      key={"Vendors"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/association/vendors"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Vendors")
                          ) : (
                            <Tooltip
                              title={t("Vendors")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <BadgeIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              {isGeneralRole &&
              isUSAssociation &&
              (currentAssociation?.subscriptionStatus === "active" ||
                currentAssociation?.subscriptionStatus === "trialing") &&
              currentAssociation?.subscriptionType &&
              (currentAssociation?.subscriptionType.startsWith(
                PRICE_TIERS.PREMIUM
              ) ||
                currentAssociation?.subscriptionType.startsWith(
                  PRICE_TIERS.ELITE
                )) ? (
                <Accordion
                  expanded={expanded === "finances"}
                  onChange={handleChange("finances")}
                  disableGutters={true}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: theme.palette.text.primary }}
                      />
                    }
                    aria-controls="financesbh-content"
                    id="financesbh-header"
                  >
                    <ListItem key={"Finances"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon
                          sx={{ justifyContent: open ? "unset" : "right" }}
                        >
                          {<AttachMoneyIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={t("Finances")}
                          sx={{ display: open ? "block" : "none" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </AccordionSummary>

                  <AccordionDetails className="sub-list">
                    <List sx={{ width: "100%", maxWidth: 360, p: "0px" }}>
                      <ListItem
                        key={"Balances"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <NavLink
                          to="/finances/balances"
                          className={({ isActive, isPending }) =>
                            isPending
                              ? "pending"
                              : isActive && open
                              ? "p-active-menu"
                              : open
                              ? "p-in-active-menu"
                              : "side-bar-menu-link"
                          }
                          children={({ isActive }) => {
                            const comp = open ? (
                              t("Balances")
                            ) : (
                              <Tooltip
                                title={t("Balances")}
                                placement="right-start"
                              >
                                <ListItemIcon
                                  sx={{
                                    background: isActive
                                      ? `${theme.palette.activeColor}`
                                      : "",
                                    minWidth: "unset",
                                  }}
                                >
                                  {
                                    <AccountBalanceWalletIcon
                                      sx={{
                                        color: isActive
                                          ? `${theme.palette.textWhite}`
                                          : "",
                                      }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            );
                            return <>{comp}</>;
                          }}
                        />
                      </ListItem>
                      <ListItem
                        key={"Transactions"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <NavLink
                          to="/finances/transactions"
                          className={({ isActive, isPending }) =>
                            isPending
                              ? "pending"
                              : isActive && open
                              ? "p-active-menu"
                              : open
                              ? "p-in-active-menu"
                              : "side-bar-menu-link"
                          }
                          children={({ isActive }) => {
                            const comp = open ? (
                              t("Transactions")
                            ) : (
                              <Tooltip
                                title={t("Transactions")}
                                placement="right-start"
                              >
                                <ListItemIcon
                                  sx={{
                                    background: isActive
                                      ? `${theme.palette.activeColor}`
                                      : "",
                                    minWidth: "unset",
                                  }}
                                >
                                  {
                                    <ReceiptLongIcon
                                      sx={{
                                        color: isActive
                                          ? `${theme.palette.textWhite}`
                                          : "",
                                      }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            );
                            return <>{comp}</>;
                          }}
                        />
                      </ListItem>
                      <ListItem
                        key={"Budgets"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <NavLink
                          to="/finances/budgets"
                          className={({ isActive, isPending }) =>
                            isPending
                              ? "pending"
                              : isActive && open
                              ? "p-active-menu"
                              : open
                              ? "p-in-active-menu"
                              : "side-bar-menu-link"
                          }
                          children={({ isActive }) => {
                            const comp = open ? (
                              t("Budgets")
                            ) : (
                              <Tooltip
                                title={t("Budgets")}
                                placement="right-start"
                              >
                                <ListItemIcon
                                  sx={{
                                    background: isActive
                                      ? `${theme.palette.activeColor}`
                                      : "",
                                    minWidth: "unset",
                                  }}
                                >
                                  {
                                    <SavingsIcon
                                      sx={{
                                        color: isActive
                                          ? `${theme.palette.textWhite}`
                                          : "",
                                      }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            );
                            return <>{comp}</>;
                          }}
                        />
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <></>
              )}

              <Accordion
                expanded={expanded === "manage"}
                onChange={handleChange("manage")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: theme.palette.text.primary }}
                    />
                  }
                  aria-controls="managebh-content"
                  id="managebh-header"
                >
                  <ListItem key={"Manage"} disablePadding>
                    <ListItemButton>
                      <ListItemIcon
                        sx={{ justifyContent: open ? "unset" : "right" }}
                      >
                        {<ManageAccountsIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Manage")}
                        sx={{ display: open ? "block" : "none" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails className="sub-list">
                  <List sx={{ width: "100%", maxWidth: 360, p: "0px" }}>
                    <ListItem
                      key={"Tickets"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/manage/tickets"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Tickets")
                          ) : (
                            <Tooltip
                              title={t("Tickets")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <SupportAgentIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                    {isGeneralRole &&
                      (currentAssociation?.subscriptionStatus === "active" ||
                        currentAssociation?.subscriptionStatus ===
                          "trialing") &&
                      currentAssociation?.subscriptionType &&
                      !testMode &&
                      currentAssociation?.subscriptionType.startsWith(
                        PRICE_TIERS.ELITE
                      ) && (
                        <ListItem
                          key={"Meetings"}
                          disablePadding
                          sx={{ display: "block" }}
                        >
                          <NavLink
                            to="/manage/meetings"
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive && open
                                ? "p-active-menu"
                                : open
                                ? "p-in-active-menu"
                                : "side-bar-menu-link"
                            }
                            children={({ isActive }) => {
                              const comp = open ? (
                                t("Meetings")
                              ) : (
                                <Tooltip
                                  title={t("Meetings")}
                                  placement="right-start"
                                >
                                  <ListItemIcon
                                    sx={{
                                      background: isActive
                                        ? `${theme.palette.activeColor}`
                                        : "",
                                      minWidth: "unset",
                                    }}
                                  >
                                    {
                                      <MeetingRoomIcon
                                        sx={{
                                          color: isActive
                                            ? `${theme.palette.textWhite}`
                                            : "",
                                        }}
                                      />
                                    }
                                  </ListItemIcon>
                                </Tooltip>
                              );
                              return <>{comp}</>;
                            }}
                          />
                        </ListItem>
                      )}
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "assets"}
                onChange={handleChange("assets")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: theme.palette.text.primary }}
                    />
                  }
                  aria-controls="managebh-content"
                  id="managebh-header"
                >
                  <ListItem key={"Assets"} disablePadding>
                    <ListItemButton>
                      <ListItemIcon
                        sx={{ justifyContent: open ? "unset" : "right" }}
                      >
                        {<DomainAddIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Assets")}
                        sx={{ display: open ? "block" : "none" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails className="sub-list">
                  <List sx={{ width: "100%", maxWidth: 360, p: "0px" }}>
                    <ListItem
                      key={"Directory"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/assets/directory"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Units Directory")
                          ) : (
                            <Tooltip
                              title={t("Units Directory")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <LocationCityIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>

                    <ListItem
                      key={"Common Assets Directory"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/assets/common-assets-directory"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Common Assets Directory")
                          ) : (
                            <Tooltip
                              title={t("Common Assets Directory")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <HomeWorkIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "governance"}
                onChange={handleChange("governance")}
                disableGutters={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ color: theme.palette.text.primary }}
                    />
                  }
                  aria-controls="governancebh-content"
                  id="governancebh-header"
                >
                  <ListItem key={"Governance"} disablePadding>
                    <ListItemButton>
                      <ListItemIcon
                        sx={{ justifyContent: open ? "unset" : "right" }}
                      >
                        {<AccountBalanceIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={t("Governance")}
                        sx={{ display: open ? "block" : "none" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>

                <AccordionDetails className="sub-list">
                  <List sx={{ width: "100%", maxWidth: 360, p: "0px" }}>
                    {isAuthorizedRole && (
                      <ListItem
                        key={"Votes"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <NavLink
                          to="/governance/votes"
                          className={({ isActive, isPending }) =>
                            isPending
                              ? "pending"
                              : isActive && open
                              ? "p-active-menu"
                              : open
                              ? "p-in-active-menu"
                              : "side-bar-menu-link"
                          }
                          children={({ isActive }) => {
                            const comp = open ? (
                              t("Votes")
                            ) : (
                              <Tooltip
                                title={t("Votes")}
                                placement="right-start"
                              >
                                <ListItemIcon
                                  sx={{
                                    background: isActive
                                      ? `${theme.palette.activeColor}`
                                      : "",
                                    minWidth: "unset",
                                  }}
                                >
                                  {
                                    <HowToVoteIcon
                                      sx={{
                                        color: isActive
                                          ? `${theme.palette.textWhite}`
                                          : "",
                                      }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            );
                            return <>{comp}</>;
                          }}
                        />
                      </ListItem>
                    )}
                    <ListItem
                      key={"CCRs"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/governance/ccrs"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("CCRs")
                          ) : (
                            <Tooltip title={t("CCRs")} placement="right-start">
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <AssignmentIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                    <ListItem
                      key={"ByLaws"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/governance/bylaws"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("ByLaws")
                          ) : (
                            <Tooltip
                              title={t("ByLaws")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <BalanceIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                    <ListItem
                      key={"Rules and Regulations"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/governance/rulesandregulations"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Rules and Regulations")
                          ) : (
                            <Tooltip
                              title={t("Rules and Regulations")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <RuleIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                    <ListItem
                      key={"Policies"}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <NavLink
                        to="/governance/policies"
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive && open
                            ? "p-active-menu"
                            : open
                            ? "p-in-active-menu"
                            : "side-bar-menu-link"
                        }
                        children={({ isActive }) => {
                          const comp = open ? (
                            t("Policies")
                          ) : (
                            <Tooltip
                              title={t("Policies")}
                              placement="right-start"
                            >
                              <ListItemIcon
                                sx={{
                                  background: isActive
                                    ? `${theme.palette.activeColor}`
                                    : "",
                                  minWidth: "unset",
                                }}
                              >
                                {
                                  <PolicyIcon
                                    sx={{
                                      color: isActive
                                        ? `${theme.palette.textWhite}`
                                        : "",
                                    }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          );
                          return <>{comp}</>;
                        }}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </Box>

        <Box></Box>
      </Drawer>
      <ConfirmLogout
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
      />
    </Box>
  );
}
