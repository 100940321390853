import { Box, Skeleton } from "@mui/material";

const MeetingSkeleton = (props: any) => {
    return (
        <Box className="myAccountBox_title" sx={{ mx: 4 }}>
            <Box>
                <Skeleton
                    variant={"text"}
                    width={200}
                    height={50}
                    sx={{
                        backgroundColor: "rgb(64 64 64 / 34%)",
                        marginX: "auto",
                    }}
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Skeleton
                        variant={"text"}
                        width={360}
                        height={50}
                        sx={{
                            backgroundColor: "rgb(64 64 64 / 34%)",
                            // marginLeft: "auto",
                            marginTop: "30px",
                        }}
                    />
                    <Skeleton
                        variant={"text"}
                        width={360}
                        height={50}
                        sx={{
                            backgroundColor: "rgb(64 64 64 / 34%)",
                            // marginRight: "auto",
                            marginTop: "30px",
                        }}
                    />
                </Box>

                <Skeleton
                    variant="rectangular"
                    height={200}
                    sx={{
                        backgroundColor: "rgb(64 64 64 / 34%)",
                        marginX: "auto",
                    }}
                />
            </Box>
        </Box>
    );
};

export default MeetingSkeleton;
