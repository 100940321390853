import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  personAssociations,
  personDetails,
} from "../../Redux/Feature/Auth-slice";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../Component/Common/SpinnerLoader/SpinnerLoader";
import Toast from "../../Component/Common/Toast/Toast";
import { ERROR_MESSAGES } from "../../Utils/utils";
import { useTranslation } from "react-i18next";

const AuthMiddleScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const audience =
          "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/";
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: audience,
            scope:
              "openid email read:roles read:current_user update:current_user_metadata",
          },
        });

        const parseJwt = (token: any) => {
          try {
            return JSON.parse(atob(token.split(".")[1]));
          } catch (error) {
            console.error("Error parsing JWT:", error);
            return null;
          }
        };

        const decodedToken = parseJwt(accessToken);
        if (decodedToken) {
          localStorage.setItem(
            "safe-token-expiration",
            decodedToken.exp.toString()
          );
        }
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims?.__raw;
        localStorage.setItem("user-id", idToken ?? "");
        localStorage.setItem("safe-token", accessToken);
        const domain = process.env.REACT_APP_AUTH0_DOMAIN;
        const userDetailsByIdUrl: any = `https://${domain}/userinfo/`;
        // process.env.REACT_APP_AUTH0_USER_DETAILS_BY_ID_URL;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const user_metadata = await metadataResponse.json();
        if (accessToken) {
          let details;
          let response;
          while (!details) {
            details = await dispatch(personDetails(user_metadata?.email));
          }
          while (!response) {
            response = await dispatch(
              personAssociations({ personId: "", email: user_metadata?.email })
            );
          }

          let user = JSON.parse(localStorage.getItem("user") || "{}");
          user.loginSessionFlag = true;
          localStorage.setItem("user", JSON.stringify(user));
          if (response?.payload?.statusCode === 200) {
            if (response?.payload?.data?.length > 0) {
              navigate("/account");
            } else {
              navigate("/setting");
            }
          } else if (response.error) {
            handleError(response.payload.data);
          }
        }
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  return (
    <div>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <SpinnerLoader loading={loading} />
    </div>
  );
};

export default AuthMiddleScreen;
