import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../Component/Common/Toast/Toast";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import { RootState } from "../../../Redux/Store";
import { getBoardVotes } from "../../../Redux/Feature/Roster-slice";
import VoteModal from "../../../Component/Modals/GovernanceModals/Vote/VoteModal";

const BoardVotes = (props: any) => {
  const { isBoard } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastType, setToastType] = useState("success");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [selectedVote, setSelectedVote] = useState<any>(null);
  const [voteModalOpen, setVoteModalOpen] = useState<boolean>(false);

  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const currentUser = useSelector((state: RootState) => state?.Auth?.user);

  const boardVotes = useSelector(
    (state: RootState) => state?.Manage?.boardVotes
  );

  const { t } = useTranslation();
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleVoteModalOpen = (payload: any) => {
    setSelectedVote(payload?.vote);
    setVoteModalOpen(true);
  };
  useEffect(() => {
    const fetchBoardVotes = async () => {
      await dispatch(getBoardVotes(currentAssociation?.associationId));
    };
    fetchBoardVotes();
  }, []);

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const handleVoteModalClose = () => {
    setSelectedVote(null);
    setVoteModalOpen(false);
  };

  return (
    <div className="myAccount">
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <VoteModal
        isOpen={voteModalOpen}
        onClose={handleVoteModalClose}
        vote={selectedVote}
        voterId={currentUser?.id}
        showToast={showToast}
      />
      {isBoard ? (
        <div>
          <Typography variant="h4" className="heading-class" align="center">
            {t("Board Votes")}
          </Typography>
          <Typography variant="h6" className="heading-class" align="center">
            {t("Open Votes")}
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            className="gridAssetTable"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {boardVotes?.openVotes?.map((vote: any) => (
                <Grid item xs={4} sx={{ mb: "10px" }}>
                  <Card className={"my-account-unit-card"}>
                    <CardHeader
                      title={vote?.title}
                      align="center"
                      sx={{
                        padding: "18px 20px 6px",
                        fontWeight: "bold",
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Type")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ marginBottom: "10px", fontWeight: "300" }}
                      >
                        {t(vote?.type)}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Description")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ marginBottom: "10px", fontWeight: "300" }}
                      >
                        {vote?.description}
                      </Typography>
                      <Button
                        variant="contained"
                        className="btn"
                        onClick={() =>
                          handleVoteModalOpen({
                            vote,
                            voteId: vote.voteId,
                          })
                        }
                        sx={{
                          width: "auto !important",
                          paddingX: "16px !important",
                        }}
                      >
                        {t("Vote")}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography variant="h6" className="heading-class" align="center">
            {t("Submitted Votes")}
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            className="gridAssetTable"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {boardVotes?.submittedVotes?.map((vote: any) => (
                <Grid item xs={4} sx={{ mb: "10px" }}>
                  <Card className={"my-account-unit-card"}>
                    <CardHeader
                      title={vote?.title}
                      align="center"
                      sx={{
                        padding: "18px 20px 6px",
                        fontWeight: "bold",
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Type")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ marginBottom: "10px", fontWeight: "300" }}
                      >
                        {vote?.type}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Description")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ marginBottom: "10px", fontWeight: "300" }}
                      >
                        {vote?.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
      ) : null}
    </div>
  );
};

export default BoardVotes;
