import http from "../api";
// Utility function to determine the environment

const getEnvironment = () => {
  return window.location.hostname === "localhost" ? "dev" : "live";
};

const GetFinancialAccounts = async (CurrentAssociationId: any) => {
  try {
    const response = await http.get(
      `/stripe-get-financial-connections/${CurrentAssociationId}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const StripeFinancialConnectionsSession = async (associationObject: any) => {
  try {
    const response = await http.post(
      `/stripe-financial-connections-session`,
      associationObject
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};
const StripeAssociationConnectSession = async (associationId: any) => {
  try {
    const response = await http.post(`/stripe-association-onboarding`, {
      appType: "web",
      associationId: associationId,
      environment: getEnvironment(),
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};
const StripeVendorConnectSession = async (obj: any) => {
  try {
    const response = await http.post(`/stripe-vendor-onboarding`, {
      country: obj.country,
      appType: "web",
      environment: getEnvironment(),
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const GetTransactions = async (CurrentAssociationId: any) => {
  try {
    const response = await http.get(
      `/get-transactions/${CurrentAssociationId}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const UpdateTransaction = async (editDataObj: any) => {
  try {
    const response = await http.put(
      `/update-transaction/${editDataObj.transactionId}`,
      {
        ...editDataObj,
      }
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const getAllBudgets = async (budgetObj: any) => {
  try {
    const response = await http.post(
      `/get-budgets/${budgetObj.associationId}`,
      {
        ...budgetObj,
      }
    );

    return response?.data;
  } catch (error) {
    return error;
  }
};

const getBudgetsByDate = async (budgetObj: any) => {
  try {
    const response = await http.post(
      `/get-budgets-by-date/${budgetObj.associationId}`,
      {
        ...budgetObj,
      }
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const AddBudget = async (newBudgetObj: any) => {
  try {
    const response = await http.post(`/create-budget`, { ...newBudgetObj });
    return response?.data;
  } catch (error) {
    return error;
  }
};

const DeleteBudget = async (budgetId: any) => {
  try {
    const response = await http.delete(`/delete-budget/${budgetId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const UpdateBudget = async (editDataObj: any) => {
  try {
    const response = await http.put(`update-budget/${editDataObj.budgetId}`, {
      allocatedAmount: editDataObj.allocatedAmount,
      name: editDataObj.name,
      assetId: editDataObj.assetId,
      monthYear: editDataObj.monthYear,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};

const associateTransactionWithBudget = async (linkTranObj: any) => {
  try {
    const response = await http.put(`/get-budget-transactions`, {
      ...linkTranObj,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};

const getBudget = async (budgetObj: any) => {
  try {
    const response = await http.get(
      `/get-budget/${budgetObj.budgetId}?year=${budgetObj.year}&month=${budgetObj.month}&quarter=${budgetObj.quarter}&duration=${budgetObj.duration}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const Finance = {
  GetFinancialAccounts,
  GetTransactions,
  UpdateTransaction,
  getBudgetsByDate,
  getAllBudgets,
  AddBudget,
  DeleteBudget,
  StripeVendorConnectSession,
  UpdateBudget,
  getBudget,
  associateTransactionWithBudget,
  StripeFinancialConnectionsSession,
  StripeAssociationConnectSession,
};
export default Finance;
