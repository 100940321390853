import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, useTheme } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateComponent } from "../../../Redux/Feature/Asset-slice";

const EditComponentTitle = (props: any) => {
  const dispatch = useDispatch();
  const {
    title,
    setEditTitle,
    assetId,
    showToast,
    setUpdateTitleProgress,
    updateTitleProgress,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const validationSchema = yup.object({
    title: yup.string().required(t("Title is required")),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setUpdateTitleProgress(true);

      const response: any = await dispatch(
        updateComponent({
          id: assetId,
          commonAssetsDirObj: {
            title: values.title,
          },
        })
      );
      setUpdateTitleProgress(false);
      setEditTitle(false);
      if (response?.payload?.statusCode === 200) {
        showToast(t("Component Updated"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    },
  });
  useEffect(() => {
    formik.setValues({ title });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="formGroup"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            fullWidth
            id="title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="standard"
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            sx={{
              // fontSize: 30,
              "& .MuiInput-root .MuiInput-input": {
                color: `${theme.palette.text.primary}`,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateTitleProgress}
          >
            {" "}
            {t("Save")}
            {updateTitleProgress ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <></>
            )}
          </Button>
          <Button
            onClick={() => setEditTitle(false)}
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateTitleProgress}
          >
            {" "}
            {t("Cancel")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditComponentTitle;
