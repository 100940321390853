import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import Toast from "../../../Component/Common/Toast/Toast";
import { loadStripe } from "@stripe/stripe-js";
import {
  GetFinancialAccounts,
  StripeFinancialConnectionsSession,
} from "../../../Redux/Feature/Finance-slice";
import "./balances.scss";
import AssociationSkeleton from "../../../Component/Skeletons/AssociationSkeleton";
import { Clear, Search } from "@mui/icons-material";
import { RootState } from "../../../Redux/Store";
import { MEMBER_TYPE } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";

const Balances = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const accountBalances: any = useSelector(
    (state: RootState) => state?.Finances?.accountBalances
  );
  let stripePublishableKey: any;
  const testMode = JSON.parse(localStorage.getItem("testMode") || "false");
  if (testMode) {
    stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;
  } else {
    stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  }
  const userData = useSelector((state: any) => state.Auth.user);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editFinancialConnections, setEditFinancialConnections] =
    useState(false);

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;
  useEffect(() => {
    fetchAccountBalance();
  }, [currentAssociation]);

  const fetchAccountBalance = async () => {
    setLoading(true);
    await dispatch(GetFinancialAccounts(currentAssociation?.associationId));
    setLoading(false);
  };

  const accountColumns = [
    {
      id: "institution",
      label: t("Institution"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "category",
      label: t("Category"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "subcategory",
      label: t("Subcategory"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "currentCash",
      label: t("Current Cash"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "availableCash",
      label: t("Available Cash"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "cashDifference",
      label: t("Cash Difference"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "status",
      label: t("Status"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
  ];

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleStripeFinancialConnectionsSession = async () => {
    const payload = {
      associationId: currentAssociation?.associationId,
    };
    await dispatch(StripeFinancialConnectionsSession(payload)).then(
      async (res: any) => {
        if (res?.payload?.data?.client_secret) {
          const stripe = await loadStripe(stripePublishableKey);
          if (stripe) {
            const financialConnectionsSessionResult = await stripe
              .collectFinancialConnectionsAccounts({
                clientSecret: res?.payload?.data?.client_secret,
              })
              .then(() => {
                if (res?.payload?.statusCode === 200) {
                  showToast(
                    t("Financial Connections Session Success"),
                    "success"
                  );
                } else {
                  showToast(t("Error"), "error");
                }
                setEditFinancialConnections(false);
              });
          }
        }
      }
    );
    fetchAccountBalance();
  };
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedAccountBalance = stableSort(
    accountBalances?.accounts,
    getComparator(order, orderBy)
  );
  const filteredAccountBalance = sortedAccountBalance?.filter((bal: any) => {
    if (!bal) {
      return false;
    }
    const {
      availableCash,
      institution_name,
      currentCash,
      cashDifference,
      status,
    } = bal;
    const lowerCaseSearch = search?.toLowerCase();
    return (
      institution_name?.toLowerCase().includes(lowerCaseSearch) ||
      availableCash === search ||
      currentCash === search ||
      cashDifference === search ||
      status?.toLowerCase().includes(lowerCaseSearch)
    );
  });

  let paginatedData: any = filteredAccountBalance?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  let totalCurrentCash;
  let totalAvailableCash;
  let totalCashDifference;

  if (accountBalances) {
    totalCurrentCash = accountBalances.totalCurrentCash;
    totalAvailableCash = accountBalances.totalAvailableCash;
    totalCashDifference = accountBalances.totalCashDifference;
  }

  const handleClearSearch = () => {
    setSearch("");
  };

  if (loading) {
    return <AssociationSkeleton />;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className="profile">
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Typography
        className="balances__title page_title"
        sx={{ color: `${theme.palette.activeColor}` }}
      >
        {t("Account Balances")}
      </Typography>

      {isBoardRole && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            className="btn"
            onClick={handleStripeFinancialConnectionsSession}
            sx={{
              width: "auto !important",
              paddingX: "16px !important",
              textTransform: "none",
              opacity:
                currentAssociation?.unitSubscriptionCount === 0 ? 0.6 : 1,
            }}
            disabled={currentAssociation?.unitSubscriptionCount === 0}
          >
            {t("Setup Financial Connections")}:
          </Button>
        </Box>
      )}
      <Box
        className="TableConnect board_wrapper board-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="board-wrap gridTable">
            <Box
              sx={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={6} lg={4}>
                <Box sx={{ flexGrow: 1, maxWidth: 360, paddingBottom: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{ color: `${theme.palette.activeColor}` }}
                      >
                        {t("Total Current Cash")}:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        color: totalCurrentCash >= 0 ? "green" : "red",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        {totalCurrentCash != null &&
                        totalCurrentCash !== undefined &&
                        !isNaN(totalCurrentCash)
                          ? formatter.format(totalCurrentCash)
                          : formatter.format(0)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{ color: `${theme.palette.activeColor}` }}
                      >
                        {t("Total Available Cash")}:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        color: totalAvailableCash >= 0 ? "green" : "red",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        {totalAvailableCash != null &&
                        totalAvailableCash !== undefined &&
                        !isNaN(totalAvailableCash)
                          ? formatter.format(totalAvailableCash)
                          : formatter.format(0)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{ color: `${theme.palette.activeColor}` }}
                      >
                        {t("Total Cash Difference")}:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        color: totalCashDifference >= 0 ? "green" : "red",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        {totalCashDifference != null &&
                        totalCashDifference !== undefined &&
                        !isNaN(totalCashDifference)
                          ? formatter.format(totalCashDifference)
                          : formatter.format(0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <OutlinedInput
                sx={{
                  margin: "0 !important",
                  border: "unset !important",
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.activeColor}`,
                  },
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                    },
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                      borderWidth: "1px",
                    },
                  },
                }}
                placeholder={t("Search")}
                value={search}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    {search ? (
                      <IconButton
                        onClick={handleClearSearch}
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Clear
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Search
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </Box>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {accountColumns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            {t("No Balances Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedData?.map((row: any) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&. MuiTableCell-root": {
                                height: "70px",
                              },
                            }}
                          >
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.institution_name}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.category}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.subcategory}
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: row.currentCash >= 0 ? "green" : "red",
                                }}
                              >
                                {row.currentCash && !isNaN(row.currentCash)
                                  ? formatter.format(row.currentCash)
                                  : "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    row.availableCash >= 0 ? "green" : "red",
                                }}
                              >
                                {row.availableCash && !isNaN(row.availableCash)
                                  ? formatter.format(row.availableCash)
                                  : "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    row.cashDifference >= 0 ? "green" : "red",
                                }}
                              >
                                {row.cashDifference &&
                                !isNaN(row.cashDifference)
                                  ? formatter.format(row.cashDifference)
                                  : "NA"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.status}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredAccountBalance?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default Balances;
