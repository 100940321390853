import http from "../api";

const personAssociations = async (personId: any, email: string) => {
  try {
    let response = await http.get(
      `/get-person-associations/${personId}/${email}`
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

const personDetails = async (email: any) => {
  try {
    let response = await http.get(`/get-user-details/${email}`);
    return response.data;
  } catch (e) {
    return e;
  }
};

const changeUserDefaultAssociation = async (associationIdObj: any) => {
  try {
    let response = await http.post(
      `/update-default-association`,
      associationIdObj
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

const getAssociation = async (associationId: any) => {
  try {
    const response = await http.get(`/get-association/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};
const createAssociation = async (associationObject: any) => {
  try {
    const response = await http.post(`/create-association/`, associationObject);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const removeProfilePicture = async (payload: any) => {
  try {
    const response = await http.delete(`/remove-profile-picture/${payload.id}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const uploadVerificationPicture = async (key: any) => {
  try {
    const response = await http.post(`/upload-verification-picture`, { key });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const removeVerificationPicture = async (payload: any) => {
  try {
    const response = await http.delete(
      `/remove-verification-document/${payload.id}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};
const UpdatePerson = async (obj: any) => {
  try {
    let response = await http.put(`/update-user-info`, obj);
    return response.data;
  } catch (e) {
    return e;
  }
};

const authDeleteAccount = async (personId: any) => {
  try {
    let response = await http.delete(`/delete-account/${personId}`);
    return response.data;
  } catch (e) {
    return e;
  }
};

const getAllRules = async (associationId: any) => {
  try {
    const response = await http.get(`/get-all-rules/${associationId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

const sendFeedback = async (message: any) => {
  try {
    const response = await http.post(`/feedback`, message);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAssetsByPerson = async (associationId: any) => {
  try {
    const response = await http.get(
      `/get-all-asset-by-person?associationId=${associationId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const UpdateAssociation = async (associationObject: any) => {
  try {
    const response = await http.put(
      `/update-association/${associationObject?.associationId}`,
      { ...associationObject }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const stripeResidentBillingSession = async (subscriptionObject: any) => {
  try {
    const response = await http.post(
      `/stripe-resident-billing-session`,
      subscriptionObject
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const rolesService = {
  personAssociations,
  personDetails,
  changeUserDefaultAssociation,
  removeProfilePicture,
  authDeleteAccount,
  UpdatePerson,
  getAllRules,
  getAssetsByPerson,
  getAssociation,
  createAssociation,
  UpdateAssociation,
  stripeResidentBillingSession,
  uploadVerificationPicture,
  removeVerificationPicture,
  sendFeedback,
};
export default rolesService;
