import {
  Box,
  Typography,
  Drawer,
  useTheme,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  Popper,
  Paper,
  TablePagination,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  FormControl,
  Switch,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../Pages/Settings/setting.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DrawerDescription from "../Common/DetailFields/DrawerDescription";
import { updateVote } from "../../Redux/Feature/Governance-slice";
import DrawerTitle from "../Common/DetailFields/DrawerTitle";
import DrawerAmount from "../Common/DetailFields/DrawerAmount";
import DatePickerComp from "../Common/DatePicker/DatePicker";
import dayjs from "dayjs";
import { imageFileExtensions } from "../../Utils/utils";
import CloseIcon from "@mui/icons-material/Close";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { styled } from "@mui/material/styles";
import { NotifyVote, deleteVote } from "../../Redux/Feature/Governance-slice";
import AddAttachmentModal from "../Modals/VotesModals/AddAttachmentModal";
import { MEMBER_TYPE } from "../../Utils/utils";
import ConfirmationModal from "../Modals/GeneralModals/ConfirmationModal";
import FileExtensionView from "../FileExtensionView/FileExtensionView";
import AttachmentPreviewModal from "../Modals/TicketsModals/AttachmentPreviewModal";
import { getVote } from "../../Redux/Feature/MyAccount-slice";
ChartJS.register(ArcElement, Tooltip, Legend);

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%", // Adjust the width as needed
    // width: "unset",
    marginLeft: "-18px",
    marginTop: "0.9vw",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));
const VoteDrawer = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateDescriptionProgress, setUpdateDescriptionProgress] =
    useState(false);
  const [updateTitleProgress, setUpdateTitleProgress] = useState(false);
  const [vendorsLoading, setVendorsLoading] = useState<boolean>(false);
  const [updateAmountProgress, setUpdateAmountProgress] = useState(false);
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [isStartDateMode, setIsStartDateMode] = useState(false);
  const [isEndDateMode, setIsEndDateMode] = useState(false);
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [editAmount, setEditAmount] = useState<boolean>(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState("");
  const [removeImageIndex, setRemoveImageIndex] = useState<number | null>(null);

  const [isAttachmentPreviewOpen, setIsAttachmentPreviewOpen] =
    useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any>({
    imageUrl: "",
    fileName: "",
    fileExtension: "",
  });
  const handleImageClick = (imageUrl: string, fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    setSelectedImageUrl({
      imageUrl,
      fileName,
      fileExtension,
    });
    setIsAttachmentPreviewOpen(true);
  };
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {
    selectedVoteId,
    rightDrawer,
    toggleDrawer,
    showToast,
    owners,
    handleClick,
    isAddAttachmentModalOpen,
    closeAddVoteAttachmentModal,
    voteId,
  } = props;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const groupOptions = [
    { value: MEMBER_TYPE.BOARD, label: "Board" },
    { value: MEMBER_TYPE.GENERAL, label: "General" },
  ];
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const selectedVote = useSelector(
    (state: RootState) => state?.Governance?.selectedVote
  );
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";
  const allRules = useSelector((state: RootState) => state?.Auth?.allRules);
  useEffect(() => {
    if (selectedVoteId) {
      console.log("selectedRow", selectedVoteId);
      fetchVote();
    }
  }, [selectedVoteId]);

  const fetchVote = async () => {
    console.log("selectedRow1", selectedVoteId);

    await dispatch(getVote(selectedVoteId));
  };
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const editDescriptionHandler = (value: boolean) => {
    setEditDescription(value);
  };
  const handleRuleChange = async (event: any) => {
    const ruleId = event.target.value;

    const response = await dispatch(
      updateVote({
        voteId: selectedVote?.voteId,
        payload: {
          ruleId,
        },
      })
    );
    if (response?.payload?.statusCode === 200) {
      // setSelectedRow({
      //   ...selectedVote,
      //   ruleId: ruleId,
      // });
      showToast(t("Vote Updated"), "success");
    } else if (response.error) {
      showToast(t("Vote Not Updated"), "error");
    }
  };

  const handleConfirmation = async () => {
    // setLoading(true);
    try {
      if (selectedVote) {
        const payload = {
          voteId: selectedVote.voteId,
          associationId: currentAssociation?.associationId,
        };

        if (
          confirmation ===
          "Are you sure you want to notify all owners of this vote?"
        ) {
          const response = await dispatch(NotifyVote(payload));
          if (response?.payload?.statusCode === 200) {
            showToast(
              t(`${selectedVote?.votingGroup} members notified of vote`),
              "success"
            );
          } else if (response.error) {
            showToast(t("Vote Notification Not Sent"), "error");
          }
        } else {
          const response = await dispatch(deleteVote(payload));
          if (response?.payload?.statusCode === 200) {
            showToast(t("Vote Removed"), "success");
          } else if (response.error) {
            showToast(t("Vote Not Removed"), "error");
          }
          toggleDrawer(false);
          // setSelectedRow(null);
        }
      }
    } catch (err) {
    } finally {
      closeConfirmationModal();
      // setLoading(false);
    }
  };
  const editAmountHandler = (value: boolean) => {
    setEditAmount(value);
  };

  const editTitleHandler = (value: boolean) => {
    setEditTitle(value);
  };

  const autocompleteChipStyle = {
    marginTop: "0px",
    "& .MuiOutlinedInput-root": {
      paddingRight: "19px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "-10px !important",
    },
    "& input": {
      padding: "0 !important",
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    borderRadius: "6px",
    border: "1px solid var(--mui-palette-divider)",
    background:
      theme.palette.mode === "dark"
        ? "var(--mui-palette-sidebarAutoCompleteBg)"
        : "var(--mui-palette-textWhite)",
    padding: "5px 10px",
    "& .MuiAutocomplete-tag": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.background.default
          : "#ccc",
      "& svg": {
        color: "#676767 !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      background: "#fff !important",
    },
  };
  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };

  const autoCompleteTextInputStyle = {
    paddingRight: "0px",
    "& .MuiAutocomplete-inputRoot": {
      background: "transparent",
    },
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
      paddingRight: "46px !important",
    },
  };
  const handleGroupChange = async (event: any) => {
    const votingGroup = event.target.value;
    const response = await dispatch(
      updateVote({
        voteId: selectedVote?.voteId,
        payload: {
          votingGroup,
        },
      })
    );
    if (response?.payload?.statusCode === 200) {
      // setSelectedRow({
      //   ...selectedVote,
      //   votingGroup: votingGroup,
      // });
      showToast(t("Vote Updated"), "success");
    } else if (response.error) {
      showToast(t("Vote Not Updated"), "error");
    }
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const updateAmountHandler = async (updatePayload: any) => {
    try {
      setUpdateAmountProgress(true);
      const response = await dispatch(
        updateVote({ voteId: selectedVote?.voteId, ...updatePayload })
      );
      if (response?.payload?.statusCode === 200) {
        // setSelectedRow({
        //   ...selectedVote,
        //   amount: updatePayload?.payload?.amount,
        // });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateAmountProgress(false);
      setEditAmount(false);
    } catch (error) {
    } finally {
      setUpdateAmountProgress(false);
    }
  };

  const updateTitleHandler = async (updatePayload: any) => {
    try {
      // setEditTitleHandler(true);
      setUpdateTitleProgress(true);
      const response = await dispatch(
        updateVote({ voteId: selectedVote?.voteId, ...updatePayload })
      );
      if (response?.payload?.statusCode === 200) {
        // setSelectedRow({
        //   ...selectedVote,
        //   title: updatePayload?.payload?.title,
        // });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateTitleProgress(false);
      setEditTitle(false);
    } catch (error) {
    } finally {
      setUpdateTitleProgress(false);
    }
  };
  const updateDescriptionHandler = async (updatePayload: any) => {
    try {
      setUpdateDescriptionProgress(true);
      const response = await dispatch(
        updateVote({ voteId: selectedVote?.voteId, ...updatePayload })
      );
      if (response?.payload?.statusCode === 200) {
        // setSelectedRow({
        //   ...selectedVote,
        //   description: updatePayload?.payload?.description,
        // });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateDescriptionProgress(false);
      editDescriptionHandler(false);
    } catch (error) {
    } finally {
      setUpdateDescriptionProgress(false);
    }
  };
  const openNotifyModal = (meeting: any) => {
    setConfirmationModalOpen(true);
    setConfirmation("Are you sure you want to notify all owners of this vote?");
  };
  const openRemoveModal = (meeting: any) => {
    setConfirmationModalOpen(true);
    setConfirmation("Are you sure you want to remove this vote?");
  };

  const handlePersonChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;

    setVendorsLoading(true);
    try {
      setUpdateDescriptionProgress(true);
      const response = await dispatch(
        updateVote({
          voteId: selectedVote?.voteId,
          payload: { persons: newValue },
        })
      );
      if (response?.payload?.statusCode === 200) {
        // setSelectedRow({
        //   ...selectedVote,
        //   persons: newValue,
        // });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateDescriptionProgress(false);
      editDescriptionHandler(false);

      setVendorsLoading(false);
    } catch (error) {
    } finally {
      setVendorsLoading(false);
    }
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmation={confirmation}
      />
      <Drawer
        anchor={"right"}
        open={rightDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{
          zIndex: "9",
          "& .MuiBackdrop-root": {
            opacity: "0 !important",
          },
        }}
      >
        <div
          className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
          style={{ width: "280px" }}
        >
          <Box className="sidebarVote__details">
            <div className="detailsSidebar__head">
              <Box
                className="sticky-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="cross" onClick={() => toggleDrawer(false)}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </div>
              </Box>

              <div className="title-div">
                {editTitle ? (
                  <DrawerTitle
                    title={selectedVote?.title}
                    editHandler={editTitleHandler}
                    idField="voteId"
                    idValue={selectedVote?.voteId}
                    updateHandler={updateTitleHandler}
                    updateProgress={updateTitleProgress}
                  />
                ) : (
                  <>
                    <Typography variant="h5" component="h5">
                      {t("Title")}: {selectedVote?.title || ""}
                    </Typography>
                    <Button onClick={() => editTitleHandler(true)}>
                      <ModeEditIcon
                        fontSize="medium"
                        sx={{ color: `${theme.palette.activeColor}` }}
                      />
                    </Button>
                  </>
                )}
              </div>
              <Grid item>
                <Typography variant="h6" sx={{ marginTop: "20px" }}>
                  {t("Type")} {selectedVote?.type}
                </Typography>
              </Grid>

              <Grid container direction={"row"}>
                <Grid item xs={12} lg={6} md={6}>
                  <Grid
                    container
                    direction={"column"}
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item>
                      <Typography variant="h6">{t("Start Date")}</Typography>
                    </Grid>
                    <Grid item>
                      <DatePickerComp
                        disablePast={false}
                        disableFuture={false}
                        isEditMode={isStartDateMode}
                        className="custom-datepicker"
                        value={
                          selectedVote?.startDate
                            ? dayjs(selectedVote?.startDate)
                            : null
                        }
                        onChange={async (newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          const response = await dispatch(
                            updateVote({
                              voteId: selectedVote?.voteId,
                              payload: {
                                startDate: formattedDate,
                              },
                            })
                          );
                          if (response?.payload?.statusCode === 200) {
                            showToast(t("Vote Updated"), "success");
                          } else if (response.error) {
                            showToast(t("Vote Not Updated"), "error");
                          }
                        }}
                        // style={{ width: "100%", height: "50px" }} // Make the date picker full width
                        disabled={!isBoardRole}
                      />
                      {/* {formik.errors.establishedDate &&
                    formik.touched.establishedDate && (
                      <Typography color={errorColor}>
                        {formik.errors.establishedDate}
                      </Typography>
                    )} */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction={"column"}
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item>
                      <Typography variant="h6">{t("End Date")}</Typography>
                    </Grid>
                    <Grid item>
                      <DatePickerComp
                        disablePast={true}
                        disableFuture={false}
                        isEditMode={isEndDateMode}
                        className="custom-datepicker"
                        value={
                          selectedVote?.endDate
                            ? dayjs(selectedVote?.endDate)
                            : null
                        }
                        onChange={async (newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          const response = await dispatch(
                            updateVote({
                              voteId: selectedVote?.voteId,
                              payload: {
                                endDate: formattedDate,
                              },
                            })
                          );
                          if (response?.payload?.statusCode === 200) {
                            showToast(t("Vote Updated"), "success");
                          } else if (response.error) {
                            showToast(t("Vote Not Updated"), "error");
                          }
                        }}
                        disabled={!isBoardRole}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="sideGroup">
                <div className="formDropdownField">
                  <InputLabel id="votingGroup">
                    <Typography
                      variant="h5"
                      component="h5"
                      marginTop={"20px"}
                      sx={{ color: `${theme.palette.activeColor}` }}
                    >
                      {t("Voting Group")}
                    </Typography>
                  </InputLabel>
                  <Select
                    labelId="votingGroup"
                    id="votingGroup"
                    value={selectedVote?.votingGroup}
                    placeholder="Select Voting Group"
                    name="votingGroup"
                    displayEmpty
                    onChange={handleGroupChange}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      sx: {
                        "& .MuiMenu-paper": {
                          backgroundColor: `${theme.palette.textWhite}`,
                          color: `${theme.palette.text.primary}`,
                        },
                        "& .MuiMenuItem-root.Mui-disabled": {
                          opacity: 0.7,
                        },
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: `${theme.palette.selectedItem}`,
                        },
                        "& .MuiSvgIcon-root-MuiSelect-icon": {
                          color: `${theme.palette.textWhite}`,
                        },
                      },
                    }}
                  >
                    {groupOptions?.map((group: any) => (
                      <MenuItem
                        className="SelectBox"
                        key={group.value}
                        value={group.value}
                      >
                        {t(group.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div>
                {editDescription ? (
                  <DrawerDescription
                    description={selectedVote?.description?.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )}
                    editHandler={editDescriptionHandler}
                    idField="voteId"
                    idValue={selectedVote?.voteId}
                    updateHandler={updateDescriptionHandler}
                    updateProgress={updateDescriptionProgress}
                  />
                ) : (
                  <>
                    <div className="SectionBox_title">
                      <label>{t("Description")}:</label>
                      <Button onClick={() => editDescriptionHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </div>
                    <textarea
                      value={
                        selectedVote?.description !== undefined &&
                        selectedVote?.description !== ""
                          ? selectedVote?.description?.replace(
                              /(<([^>]+)>)/gi,
                              ""
                            )
                          : t("No data")
                      }
                      onChange={(e) => {
                        const updatedRowSelect = [...selectedVote];
                        updatedRowSelect[0].description = e.target.value;
                        // setSelectedRow(updatedRowSelect);
                      }}
                      disabled={!editDescription}
                    ></textarea>
                  </>
                )}
              </div>

              {selectedVote?.type === "Position Change" && (
                <div className="sideVendors">
                  <FormControl variant="standard" fullWidth>
                    <div className="SectionBox_title">
                      <label>{t("Persons")}:</label>
                    </div>
                    {vendorsLoading || isUpdateInProgress ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <Box style={{ overflow: "visible" }}>
                        <Autocomplete
                          PopperComponent={MuiDropDownPopper}
                          sx={autocompleteChipStyle}
                          multiple
                          id="personsId"
                          options={owners?.filter(
                            (owner: any) =>
                              !selectedVote?.persons?.some(
                                (person: any) =>
                                  person.personId === owner.personId
                              )
                          )}
                          getOptionLabel={(option) => option?.name || ""}
                          defaultValue={selectedVote?.persons}
                          getOptionKey={(option: any) => option?.personId}
                          onChange={handlePersonChange}
                          value={selectedVote?.persons}
                          disableClearable
                          filterSelectedOptions
                          PaperComponent={(props) => (
                            <Paper sx={autoCompletePaperStyle} {...props} />
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={autoCompleteTextInputStyle}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </FormControl>
                </div>
              )}
              {selectedVote?.type === "Assessment" && (
                <div className="title-div">
                  {editAmount ? (
                    <DrawerAmount
                      amount={selectedVote?.amount}
                      editHandler={setEditAmount}
                      idField="voteId"
                      updateHandler={updateAmountHandler}
                      idValue={selectedVote?.voteId}
                      updateProgress={updateAmountProgress}
                    />
                  ) : (
                    <>
                      <Typography variant="h5" component="h5">
                        {t("Amount")}:{" "}
                        {selectedVote?.amount !== null &&
                        selectedVote?.amount !== undefined &&
                        !isNaN(selectedVote?.amount)
                          ? formatter.format(selectedVote?.amount)
                          : formatter.format(0)}
                      </Typography>
                      <Button onClick={() => editAmountHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </>
                  )}
                </div>
              )}
              {selectedVote?.type === "Rule Change" && (
                <div className="sideRules">
                  <div className="formDropdownField">
                    <InputLabel id="ruleId">
                      <Typography
                        variant="h5"
                        component="h5"
                        marginTop={"20px"}
                        sx={{ color: `${theme.palette.activeColor}` }}
                      >
                        {t("Rule")}
                      </Typography>
                    </InputLabel>
                    <Select
                      labelId="ruleId"
                      id="ruleId"
                      value={selectedVote?.ruleId}
                      placeholder="Select Rule"
                      name="ruleId"
                      displayEmpty
                      onChange={handleRuleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: `${theme.palette.textBlack}`,
                          },
                          "& .MuiMenuItem-root.Mui-disabled": {
                            opacity: 0.7,
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: `${theme.palette.selectedItem}`,
                          },
                          "& .MuiSvgIcon-root-MuiSelect-icon": {
                            color: `${theme.palette.textWhite}`,
                          },
                        },
                      }}
                    >
                      {allRules?.map((rule: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={rule.ruleId}
                          value={rule.ruleId}
                        >
                          {rule.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              {isBoardRole && (
                <>
                  <Button
                    variant="outlined"
                    className="btn btn-theme"
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: 160,
                      maxWidth: "120px",
                      width: "auto",
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      openNotifyModal(selectedVote?.id);
                    }}
                  >
                    {t("Notify")}
                  </Button>
                  <Button
                    variant="outlined"
                    className="btn btn-theme btn-red remove-btn"
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: 20,
                      maxWidth: "120px",
                      width: "auto",
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      openRemoveModal(selectedVote?.id);
                    }}
                  >
                    {t("Delete")}
                  </Button>
                </>
              )}
              <div className="attachment-list">
                {selectedVote?.attachments &&
                selectedVote?.attachments?.length > 0 ? (
                  selectedVote?.attachments?.map(
                    (attachment: any, index: number) => (
                      <div key={index} className="selected-image">
                        <Box
                          sx={{ display: "contents", marginTop: "20px" }}
                          onClick={() =>
                            handleImageClick(
                              attachment?.signedUrl,
                              attachment?.fileName
                            )
                          }
                        >
                          {imageFileExtensions.includes(
                            attachment?.fileName
                              ?.split(".")
                              .pop()
                              ?.toLowerCase()
                          ) ? (
                            <img
                              src={attachment?.signedUrl}
                              alt={attachment?.fileName}
                              className="selected-image-preview"
                            />
                          ) : (
                            <FileExtensionView
                              fileType={attachment?.fileName
                                .split(".")
                                .pop()
                                ?.toLowerCase()}
                            />
                          )}
                        </Box>
                        <CloseIcon
                          onClick={() => {
                            setRemoveImageIndex(index);
                            setConfirmationModalOpen(true);
                          }}
                        />
                      </div>
                    )
                  )
                ) : (
                  <p>{t("No attachments available")}</p>
                )}
                <AttachmentPreviewModal
                  imageObj={selectedImageUrl}
                  isOpen={isAttachmentPreviewOpen}
                  onClose={() => setIsAttachmentPreviewOpen(false)}
                  theme={theme}
                />
              </div>
              <Box>
                <div className="SectionBox">
                  <div className="attachmentBtn_section">
                    <button
                      type="button"
                      className="attachmentBtn"
                      onClick={handleClick}
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M10.2702 1.75775C12.6117 -0.585841 16.4107 -0.585842 18.7538 1.7573C21.0369 4.04037 21.0954 7.70558 18.9294 10.0595L18.7413 10.2569L9.94127 19.0551L9.9047 19.0853C8.44346 20.4021 6.18946 20.3574 4.78208 18.95C3.46307 17.631 3.34095 15.5683 4.41573 14.1116C4.43908 14.0662 4.46733 14.0225 4.50054 13.9813L4.55409 13.9217L4.641 13.8339L4.78208 13.6861L4.785 13.6889L12.2207 6.23463C12.4867 5.96801 12.9033 5.94325 13.1972 6.16072L13.2814 6.23322C13.548 6.49913 13.5728 6.91576 13.3553 7.20967L13.2828 7.29388L5.68819 14.907C4.97056 15.7826 5.02044 17.0768 5.83783 17.8942C6.6669 18.7233 7.98655 18.7628 8.86233 18.0127L17.695 9.18221C19.4505 7.42457 19.4505 4.57532 17.6932 2.81796C15.9907 1.11552 13.2636 1.06232 11.4971 2.65836L11.3292 2.81796L11.3167 2.83228L1.78033 12.3686C1.48744 12.6615 1.01256 12.6615 0.71967 12.3686C0.453403 12.1023 0.429197 11.6857 0.647052 11.3921L0.71967 11.3079L10.2685 1.7573L10.2702 1.75775Z"
                          fill={`${theme.palette.text.primary}`}
                        />
                      </svg>
                      {t("Add Attachment")}
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          </Box>
        </div>
      </Drawer>
      <AddAttachmentModal
        isOpen={isAddAttachmentModalOpen}
        onClose={closeAddVoteAttachmentModal}
        voteId={voteId ?? ""}
        attachments={selectedVote?.attachments}
      />
    </div>
  );
};

export default VoteDrawer;
