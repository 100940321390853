import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
  TableRow,
  TableSortLabel,
  useTheme,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/Store";
import { Clear, Search } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  deleteCommonAssetComponent,
  updateComponent,
  updateCommonAssetAPI,
  getUnitsAndBuildings,
} from "../../../Redux/Feature/Asset-slice";
import { getAllAssetByAssociationId } from "../../../Redux/Feature/Ticket-slice";

import { useTranslation } from "react-i18next";
import { ERROR_MESSAGES } from "../../../Utils/utils";
import { getAssetComponent } from "../../../Redux/Feature/Asset-slice";
import CreateComponentModal from "../../../Component/Modals/AssetModals/CommonAssetsModals/CreateComponentModal";
import ConfirmationModal from "../../../Component/Modals/GeneralModals/ConfirmationModal";
import Toast from "../../../Component/Common/Toast/Toast";
import CommonAssetProfile from "./CommonAssetProfile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditComponentTitle from "./EditComponentTitle";
import CommonAssetDirectorySkeleton from "../../../Component/Skeletons/CommonAssetDirectorySkeleton";

interface AssetComponentListProps {
  commonAsset: any;
  handleDirectoryClick: () => void;
  setRowSelected: any;
}
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const AssetComponentList: React.FC<AssetComponentListProps> = ({
  commonAsset,
  handleDirectoryClick,
  setRowSelected,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const [confirmation, setConfirmation] = useState("");
  const isFetching = useSelector((state: RootState) => state.Asset.isFetching);
  const components = useSelector(
    (state: RootState) => state.Asset.componentList
  );
  const [tab, setTab] = useState(0);

  const [isCreateComponentModalOpen, setIsCreateComponentModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<any>("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [updateTitleProgress, setUpdateTitleProgress] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState("");

  const unitsAndBuildings = useSelector(
    (state: RootState) => state?.Asset?.unitsAndBuildings
  );

  const [componentToDelete, setComponentToDelete] = useState<any>(null);
  const [selectedComponent, setSelectedComponent] = useState<any>(null);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";

  useEffect(() => {
    fetchUnitsAndBuildings();
  }, []);

  const fetchUnitsAndBuildings = async () => {
    await dispatch(
      getUnitsAndBuildings({ id: currentAssociation.associationId })
    );
  };

  const toggleDrawer = (value: boolean) => {
    setRightDrawer(value);
  };

  const handleCreateComponentModalClose = () => {
    setIsCreateComponentModalOpen(false);
  };
  const openCreateComponent = () => {
    setIsCreateComponentModalOpen(true);
  };

  const handleOpenRemoveComponentModal = (asset: any): void => {
    setComponentToDelete(asset);
    setConfirmationModalOpen(true);
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  const assetsColumn = [
    {
      id: "title",
      label: t("Title"),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "Associated Asset",
      label: t("Associated Asset"),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "Action",
      label: t("Action"),
      width: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
  ];

  const filteredAssetColumns = isBoardRole
    ? assetsColumn
    : assetsColumn?.filter((column) => column.id !== "Action");

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAssociatedAssetChange = async (
    event: any,
    newValue: any,
    row: any
  ) => {
    try {
      if (!newValue || !newValue.assetId) {
        return;
      }
      const response: any = await dispatch(
        updateComponent({
          id: row.assetId,
          commonAssetsDirObj: { associatedAssetId: newValue.assetId },
        })
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Asset allocation updated successfully"), "success");
      } else {
        showToast(t("Failed to update asset allocation"), "error");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      showToast(t("Error occurred while updating asset allocation"), "error");
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };
  const updateCommonAssetHandler = async (updateAssetPayload: any) => {
    try {
      const response: any = await dispatch(
        updateCommonAssetAPI({
          id: updateAssetPayload.assetId,
          commonAssetsDirObj: updateAssetPayload.payload,
        })
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Asset Updated"), "success");
        setRowSelected([{ ...commonAsset, ...updateAssetPayload.payload }]);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const sortedAssets = stableSort(components, getComparator(order, orderBy));

  const filteredAssets = sortedAssets?.filter((asset: any) => {
    if (!asset) {
      return false;
    }
    const { title, establishedDate, priority, assetCategory } = asset;
    const lowerCaseSearch = search.toLowerCase();
    return (
      title.toLowerCase().includes(lowerCaseSearch) ||
      assetCategory.toString().toLowerCase().includes(lowerCaseSearch) ||
      priority.toString().toLowerCase().includes(lowerCaseSearch) ||
      establishedDate.includes(lowerCaseSearch)
    );
  });

  const paginatedData = filteredAssets.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClearSearch = () => {
    setSearch("");
  };

  const fetchAssetStatistics = async (assetId: string) => {
    try {
      if (currentAssociation) {
        const response: any = await dispatch(getAssetComponent(assetId));
        if (response?.payload?.statusCode === 200) {
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching all common assets:", error);
    } finally {
    }
  };

  const handleRowClick = (params: any) => {
    const isButtonClicked = (window.event?.target as Element)?.closest(
      ".remove-common-asset"
    );
    if (!isButtonClicked) {
      const selectedRecords: any = [params];

      fetchAssetStatistics(selectedRecords[0]?.assetId);
    }
  };
  // if (isFetching) {
  //   return <CommonAssetDirectorySkeleton />;
  // }

  const handleConfirmation = async () => {
    try {
      setLoading(true);
      if (componentToDelete?.assetId) {
        const response = await dispatch(
          deleteCommonAssetComponent(componentToDelete?.assetId)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Component Removed"), "success");
        } else {
          showToast(t("Component Not Removed"), "error");
        }
        toggleDrawer(false);
        setComponentToDelete(null);
      }
    } catch (err) {
    } finally {
      setLoading(false);
      closeConfirmationModal();
    }
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const updateComponentHandler = async (updateAssetPayload: any) => {
    try {
      const response: any = await dispatch(
        updateComponent({
          id: updateAssetPayload.assetId,
          commonAssetsDirObj: updateAssetPayload.payload,
        })
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Asset Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  if (isFetching) {
    return <CommonAssetDirectorySkeleton />;
  }
  return (
    <>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmation={confirmation}
        loading={loading}
      />
      <CreateComponentModal
        isOpen={isCreateComponentModalOpen}
        onClose={handleCreateComponentModalClose}
        associationId={currentAssociation?.associationId}
        commonAssetId={commonAsset.assetId}
        setOpenToast={setOpenToast}
        setToastMessage={setToastMessage}
        setToastType={setToastType}
      />
      {openToast && (
        <Toast
          open={openToast}
          message={toastMessage}
          handleClose={handleCloseToast}
          type={toastType}
        />
      )}
      <Box>
        <AppBar
          position="static"
          sx={{
            backgroundColor: theme.palette.background.default,
            boxShadow: "none",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "left",
            }}
          >
            <Button
              onClick={handleDirectoryClick}
              startIcon={<ArrowBackIcon />}
              sx={{
                color: `${theme.palette.text.primary}`,
              }}
            />
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="settings tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: `${theme.palette.activeColor}`,
                },
              }}
            >
              <Tab
                label={
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color:
                        tab === 0
                          ? `${theme.palette.activeColor}`
                          : theme.palette.text.primary,
                    }}
                  >
                    {t("Reserve Details")}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color:
                        tab === 1
                          ? `${theme.palette.activeColor}`
                          : theme.palette.text.primary,
                    }}
                  >
                    {t("Component List")}
                  </Typography>
                }
              />
            </Tabs>
          </Box>
        </AppBar>
        <Box
          className="ccr document document-wrapper"
          sx={{ paddingTop: "0 !important" }}
        >
          <Box className="rule__title">
            <Typography className="rule__title__heading page_title">
              {commonAsset?.title} {t("Profile")}
            </Typography>
          </Box>

          {tab === 0 && (
            <CommonAssetProfile
              rowSelect={commonAsset}
              updateCommonAssetHandler={updateCommonAssetHandler}
              loading={loading}
              showToast={showToast}
            />
          )}
          {tab === 1 && (
            <>
              <Box
                className="TableConnect document-wrapper  new-support-table"
                sx={{
                  border: "unset !important",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <OutlinedInput
                    sx={{
                      margin: "0 !important",
                      border: "unset !important",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      "& .MuiInputBase-input": {
                        padding: "6px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#20c1f5",
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#20c1f5",
                        },
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#20c1f5",
                          borderWidth: "1px",
                        },
                      },
                    }}
                    placeholder={t("Search")}
                    value={search}
                    onChange={handleSearchChange}
                    endAdornment={
                      <InputAdornment position="end">
                        {search ? (
                          <IconButton onClick={handleClearSearch} edge="end">
                            <Clear style={{ color: "#20c1f5" }} />
                          </IconButton>
                        ) : (
                          <IconButton edge="end">
                            <Search style={{ color: "#20c1f5" }} />
                          </IconButton>
                        )}
                      </InputAdornment>
                    }
                  />
                  {isBoardRole && (
                    <Box sx={{ textAlign: "end", marginBottom: "12px" }}>
                      <Button
                        className="btn add-rule btn-white"
                        variant="outlined"
                        onClick={openCreateComponent}
                      >
                        {t("Add Component")}
                      </Button>
                    </Box>
                  )}
                </Box>
                <Grid item xs={12} lg={12}>
                  <Box className="TableConnectInner">
                    <Box className="document-wrap gridTable">
                      <Paper
                        sx={{
                          width: "100%",
                          mb: 2,
                          border: "1px solid #20c1f5",
                        }}
                      >
                        <Box>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead sx={{ background: "black" }}>
                                <TableRow>
                                  {filteredAssetColumns?.map(
                                    (column, index) => (
                                      <TableCell
                                        sx={{ padding: "16px 8px" }}
                                        key={column.id}
                                        style={{
                                          width: column.minWidth,
                                          maxWidth: column.maxWidth,
                                          minWidth: column.minWidth,
                                        }}
                                        sortDirection={
                                          orderBy === column.id ? order : false
                                        }
                                      >
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : "asc"
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {paginatedData?.length === 0 ? (
                                  <TableRow>
                                    <TableCell colSpan={3} align="center">
                                      {t("No Components Found")}
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  paginatedData.map((row: any) => (
                                    <TableRow
                                      // onClick={() => handleRowClick(row)}
                                      key={row.id}
                                      sx={{
                                        "&. MuiTableCell-root": {
                                          height: "70px",
                                        },
                                      }}
                                      onMouseEnter={() =>
                                        setHoveredRowId(row?.assetId)
                                      }
                                      onMouseLeave={() => setHoveredRowId("")}
                                    >
                                      {/* <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row.title}
                                      </TableCell> */}

                                      <TableCell
                                        sx={{
                                          height: "64px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {editTitle &&
                                        selectedComponent?.assetId ===
                                          row?.assetId ? (
                                          <EditComponentTitle
                                            setUpdateTitleProgress={
                                              setUpdateTitleProgress
                                            }
                                            updateTitleProgress={
                                              updateTitleProgress
                                            }
                                            title={row?.title}
                                            assetId={row?.assetId}
                                            showToast={showToast}
                                            setEditTitle={setEditTitle}
                                          />
                                        ) : (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              minHeight: "28px",
                                            }}
                                          >
                                            <span className="edit-document-text">
                                              {row?.title}
                                            </span>
                                            {!editTitle &&
                                              isBoardRole &&
                                              hoveredRowId === row?.assetId && (
                                                <Button
                                                  sx={{ justifyContent: "end" }}
                                                  className="support-edit-icon"
                                                  onClick={(e) => {
                                                    setEditTitle(true);
                                                    setSelectedComponent(row);
                                                  }}
                                                >
                                                  <ModeEditIcon
                                                    fontSize="small"
                                                    sx={{
                                                      color:
                                                        hoveredRowId ===
                                                        row?.assetId
                                                          ? `${theme.palette.activeColor}`
                                                          : "transparent",
                                                    }}
                                                  />
                                                </Button>
                                              )}
                                          </Box>
                                        )}
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                          maxWidth: "100px",
                                        }}
                                      >
                                        <Autocomplete
                                          options={unitsAndBuildings}
                                          getOptionLabel={(option: any) => {
                                            const asset =
                                              unitsAndBuildings.find(
                                                (asset: any) =>
                                                  asset.assetId === option
                                              );
                                            return asset ? asset.title : "";
                                          }}
                                          disabled={!isBoardRole}
                                          value={row?.associatedAssetId}
                                          onChange={(
                                            event: any,
                                            newValue: any
                                          ) =>
                                            handleAssociatedAssetChange(
                                              event,
                                              newValue,
                                              row
                                            )
                                          }
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.assetId === value.assetId}
                                          filterSelectedOptions
                                          filterOptions={(options, state) => {
                                            const response = options.filter(
                                              (option) =>
                                                option?.title
                                                  ?.toLowerCase()
                                                  .startsWith(
                                                    state.inputValue.toLowerCase()
                                                  )
                                            );
                                            return response;
                                          }}
                                          renderInput={(params: any) => (
                                            <TextField
                                              {...params}
                                              placeholder={t("Select Asset")}
                                            />
                                          )}
                                          renderOption={(props, option) => (
                                            <li {...props} key={option.assetId}>
                                              {option.title}
                                            </li>
                                          )}
                                        />
                                      </TableCell>

                                      {/* <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.estimatedLife} {t("Years")}
                                      </TableCell> */}
                                      {/* <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.remainingLife} {t("Years")}
                                      </TableCell> */}
                                      {/* <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.totalCost !== null &&
                                        row?.totalCost !== undefined &&
                                        !isNaN(row?.totalCost)
                                          ? formatter.format(row?.totalCost)
                                          : formatter.format(0)}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.annualFunding !== null &&
                                        row?.annualFunding !== undefined &&
                                        !isNaN(row?.annualFunding)
                                          ? formatter.format(row?.annualFunding)
                                          : formatter.format(0)}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.presentValue !== null &&
                                        row?.presentValue !== undefined &&
                                        !isNaN(row?.presentValue)
                                          ? formatter.format(row?.presentValue)
                                          : formatter.format(0)}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.onHandValue !== null &&
                                        row?.onHandValue !== undefined &&
                                        !isNaN(row?.onHandValue)
                                          ? formatter.format(row?.onHandValue)
                                          : formatter.format(0)}
                                      </TableCell> */}
                                      {isBoardRole && (
                                        <TableCell
                                          sx={{
                                            height: "60px",
                                            padding: "4px 8px",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              color: `${theme.palette.text.primary}`,
                                            }}
                                            onClick={() =>
                                              handleOpenRemoveComponentModal(
                                                row
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            sx={{ background: "black" }}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={sortedAssets.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("Rows per page")}
                          />
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default AssetComponentList;
