import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  IconButton,
  useTheme,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import {
  updateTicketPrivatePublicToggle,
  updateTicketStatus,
  getAllTickets,
  getTicket,
  createComment,
  updateTicketAttachments,
  updateTicketType,
  getAllAssetByAssociationId,
  updateTicketTextFields,
  updateTicket,
  resetTicketDrawerState,
} from "../../Redux/Feature/Ticket-slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import "./tickets.scss";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { generateFullDate } from "../../Utils/dateTime";
import EditSupportTicketTableTitle from "./components/EditSupportTicketTableTitle";
import Toast from "../../Component/Common/Toast/Toast";
import EditSupportTicketStatus from "./components/EditSupportTicketStatus";
import { Clear, Search } from "@mui/icons-material";
import SupportTicketDrawer from "../../Component/Drawers/TicketDrawer";
import SupportTicketSkelton from "../../Component/Skeletons/SupportTicketSkelton";
import { getVendors } from "../../Redux/Feature/Roster-slice";
import { GetAllRules, getAssetsByPerson } from "../../Redux/Feature/Auth-slice";
import { useTranslation } from "react-i18next";
import { ERROR_MESSAGES } from "../../Utils/utils";

function SupportTickets() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(false);
  const [vendorsLoading, setVendorsLoading] = useState<boolean>(false);
  const [rulesLoading, setRulesLoading] = useState<boolean>(false);
  const [updateFieldProgress, setUpdateFieldProgress] =
    useState<boolean>(false);
  const [updateTitleProgress, setUpdateTitleProgress] = useState(false);
  const [updateDescriptionProgress, setUpdateDescriptionProgress] =
    useState(false);
  const [editSupportTicketId, setEditSupportTicketId] = useState<string | null>(
    null
  );
  const [selectedStatusOption, setSelectedStatusOption] =
    useState<string>("open");
  const [editStatusSupportTicketId, setEditStatusSupportTicketId] = useState<
    string | null
  >(null);

  const ticketStatusOptions = [
    { value: "open", text: t("Open") },
    { value: "closed", text: t("Closed") },
    { value: "all", text: t("All") },
  ];

  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");

  const supportTicketTable = useSelector(
    (state: any) => state?.SupportTicket?.TicketTable
  );
  const lastTicketsKey = useSelector(
    (state: any) => state?.SupportTicket?.lastTicketsKey
  );

  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";
  const selectedTicket: any = useSelector(
    (state: any) => state?.SupportTicket?.commentData
  );

  const isFetchingRequest: any = useSelector(
    (state: RootState) => state?.SupportTicket?.isFetchingRequest
  );
  const isFetching = useSelector(
    (state: RootState) => state?.SupportTicket?.isFetching
  );

  const [assetId, setAssetId] = useState("");

  const [rowSelect, setRowSelected] = useState<any>([]);
  const [commentData, setCommentData] = useState<any>([
    {
      comment: "",
      associationId: currentAssociation.associationId,
      assetId: "",
    },
  ]);
  const [allCommentData, setAllCommentData] = useState<any>([]);
  const [TicketTable, setTicketData] = useState<any>([]);
  const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] =
    useState(false);
  const [selectedSupportTicketId, setSelectedSupportTicketId] = useState<
    string | null
  >(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any>({
    imageUrl: "",
    fileName: "",
    fileExtension: "",
  });
  const [isAttachmentPreviewOpen, setIsAttachmentPreviewOpen] =
    useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  const [toastType, setToastType] = useState("success");

  const [selectedAsset, setSelectedAsset] = useState<string[]>([]);
  const [selectedRule, setSelectedRule] = useState<string[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<string[]>([]);
  const [hoveredRowId, setHoveredRowId] = useState("");

  const handleImageClick = (imageUrl: string, fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    setSelectedImageUrl({
      imageUrl,
      fileName,
      fileExtension,
    });
    setIsAttachmentPreviewOpen(true);
  };

  const openAddAttachmentModal = (ticketId: any) => {
    setIsAddAttachmentModalOpen(true);
    setSelectedSupportTicketId(ticketId);
  };
  const closeAddAttachmentModal = async (uploadedAttachments: string[]) => {
    try {
      setIsAddAttachmentModalOpen(false);
      if (uploadedAttachments?.length > 0) {
        const updatedAttachments = [...selectedTicket.attachments];
        const transformedAttachments = updatedAttachments.map((attachment) => ({
          s3Key: attachment.key,
        }));
        const attachments = uploadedAttachments.map((key) => ({ s3Key: key }));
        const mergedAttachments = transformedAttachments.concat(attachments);

        const updatedRow = {
          ...rowSelect[0],
          attachments: mergedAttachments,
        };

        setRowSelected([updatedRow]);
        const response = await dispatch(updateTicketAttachments(updatedRow));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
        await dispatch(getTicket({ id: rowSelect[0]?.ticketId }));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleRemoveAttachment = async (attachmentIndex: number) => {
    try {
      const updatedAttachments = [...selectedTicket.attachments];
      updatedAttachments.splice(attachmentIndex, 1);
      const transformedAttachments = updatedAttachments.map((attachment) => ({
        s3Key: attachment.key,
      }));
      const updatedRow = {
        ...rowSelect[0],
        attachments: transformedAttachments,
      };
      setRowSelected([updatedRow]);
      const response = await dispatch(updateTicketAttachments(updatedRow));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Ticket Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      await dispatch(getTicket({ id: rowSelect[0]?.ticketId }));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleClick = () => {
    if (rowSelect.length > 0) {
      const selectedTicketId = rowSelect[0].ticketId;
      openAddAttachmentModal(selectedTicketId);
    }
  };

  const [commentError, setCommentError] = useState<Boolean>(false);

  useEffect(() => {
    setRightDrawer(false);

    fetchAllAccountData();
  }, [currentAssociation?.associationId, selectedStatusOption]);

  const fetchAllAccountData = async () => {
    if (currentAssociation?.associationId) {
      setCommentData((prevData: any) => [
        {
          comment: "",
          associationId: currentAssociation?.associationId,
        },
      ]);
      setAssetsLoading(true);
      setVendorsLoading(true);
      setRulesLoading(true);
      try {
        const requestBody = {
          associationId: currentAssociation?.associationId,
          status: selectedStatusOption,
        };

        const supportTicketResponse: any = await dispatch(
          getAllTickets(requestBody)
        );
        if (supportTicketResponse.error) {
          handleError(supportTicketResponse.payload.data);
        }
        const assetResponse: any = await dispatch(
          getAllAssetByAssociationId({ id: currentAssociation.associationId })
        );
        if (assetResponse.error) {
          handleError(assetResponse.payload.data);
        }
        // setAssetsLoading(false);
        const vendorResponse: any = await dispatch(
          getVendors(currentAssociation.associationId)
        );
        if (vendorResponse.error) {
          handleError(vendorResponse.payload.data);
        }
        setVendorsLoading(false);
        const ruleResponse: any = await dispatch(
          GetAllRules(currentAssociation.associationId)
        );
        if (ruleResponse.error) {
          handleError(ruleResponse.payload.data);
        }
        setRulesLoading(false);
        const allPersonAssets: any = await dispatch(
          getAssetsByPerson(currentAssociation?.associationId)
        );
        if (allPersonAssets.error) {
          handleError(allPersonAssets.payload.data);
        }
        setAssetsLoading(false);
      } catch (error) {
      } finally {
        setAssetsLoading(false);
        setVendorsLoading(false);
        setRulesLoading(false);
      }
    }
  };

  useEffect(() => {
    let rules = [];
    let vendors = [];
    let assets = [];

    if (selectedTicket?.allComments !== undefined) {
      const sortedComments = [...selectedTicket?.allComments].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      if (selectedTicket?.allComments?.length > 0)
        setAllCommentData(sortedComments);
    }
    if (selectedTicket?.rules && selectedTicket?.rules?.length > 0) {
      rules = selectedTicket?.rules;
    }
    if (selectedTicket?.vendors && selectedTicket?.vendors?.length > 0) {
      vendors = selectedTicket?.vendors;
    }
    if (selectedTicket?.assets && selectedTicket?.assets?.length > 0) {
      assets = selectedTicket?.assets;
    }
    setSelectedRule(rules);
    setSelectedVendor(vendors);
    setSelectedAsset(assets);
  }, [selectedTicket]);

  useEffect(() => {
    if (TicketTable !== undefined) {
      const sortedSupportTicketData = [...TicketTable].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      if (sortedSupportTicketData?.length > 0)
        setTicketData(sortedSupportTicketData);
    }
  }, [TicketTable]);

  const handleValueChange = async (e: any) => {
    try {
      const selectedStatus = e.target;
      if (rowSelect?.length > 0) {
        const updatedSupportTicket = {
          ...rowSelect[0],
          status: selectedStatus.value,
        };
        setRowSelected([updatedSupportTicket]);
        const response = await dispatch(
          updateTicketStatus(updatedSupportTicket)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleTypeChange = async (e: any) => {
    try {
      const selectedType = e.target;
      if (rowSelect?.length > 0) {
        const updatedSupportTicket = {
          ...rowSelect[0],
          type: selectedType.value,
        };
        setRowSelected([updatedSupportTicket]);
        const response = await dispatch(updateTicketType(updatedSupportTicket));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleVisibilityToggle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (rowSelect?.length > 0) {
        const updatedIsPrivate = event.target.checked;
        const updatedRow = { ...rowSelect[0], isPrivate: updatedIsPrivate };
        setRowSelected([updatedRow]);
        const response = await dispatch(
          updateTicketPrivatePublicToggle(updatedRow)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const editTitleHandler = (value: boolean) => {
    setEditTitle(value);
  };

  const [editDescription, setEditDescription] = useState<boolean>(false);
  const editDescriptionHandler = (value: boolean) => {
    setEditDescription(value);
  };

  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const toggleDrawer = async (value: boolean) => {
    setRightDrawer(value);
    await dispatch(resetTicketDrawerState());
  };

  const mySupportTicketColumn = [
    {
      id: "title",
      label: t("Title"),
      width: "500px",
      minWidth: "500px",
      maxWidth: "500px",
      cellClassName: "support-title-cell",
    },
    {
      id: "type",
      label: t("Type"),
      width: "110px",
      minWidth: "110px",
      maxWidth: "110px",
      headerClassName: "support-ticket-header",
    },
    {
      id: "status",
      label: t("Status"),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "date",
      label: t("Date"),
      width: "9px",
      minWidth: "90px",
      maxWidth: "90px",
    },
  ];

  const handleRowClick = async (params: any) => {
    const isInputClicked =
      (window.event?.target as Element)?.tagName.toLowerCase() === "input";
    if (!isInputClicked) {
      const isIconClicked = (window.event?.target as Element)?.closest(".icon");
      if (!isIconClicked) {
        const isEditIconClicked = (window.event?.target as Element)?.closest(
          ".support-edit-icon"
        );
        if (!isEditIconClicked) {
          setUpdateInProgress(true);
          const selectedrecords: any = [params];
          dispatch(await getTicket({ id: selectedrecords[0]?.ticketId }))
            .then((res: any) => {
              if (res.payload.statusCode === 200) {
                setAssetId(res.payload.data.assetId);
              }
              setUpdateInProgress(false);
            })
            .catch(() => {
              setUpdateInProgress(false);
            });
          setCommentError(false);
          setRowSelected(selectedrecords);
          setCommentData({
            ticketId: selectedrecords[0]?.ticketId,
            personName: userData.name,
            personId: userData.id,
            date: new Date(),
            comment: "",
          });
          setAllCommentData([]);
          toggleDrawer(true);
        }
      }
    }
  };

  const editSupportTicketTitleHandler = (value: boolean) => {
    setEditSupportTicketId(null);
    return;
  };

  const editStatusHandler = (value: boolean) => {
    setEditStatusSupportTicketId(null);
    return;
  };

  const updateTitleHandler = async (updatePayload: any) => {
    try {
      setUpdateTitleProgress(true);
      const response = await dispatch(updateTicketTextFields(updatePayload));
      if (response?.payload?.statusCode === 200) {
        setRowSelected([response?.payload?.data]);
        showToast(t("Ticket Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateTitleProgress(false);
      setLoading(false);
      editTitleHandler(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateTitleProgress(false);
      setLoading(false);
    }
  };

  const updateDescriptionHandler = async (updatePayload: any) => {
    try {
      setUpdateDescriptionProgress(true);
      const response = await dispatch(updateTicketTextFields(updatePayload));
      if (response?.payload?.statusCode === 200) {
        setRowSelected([response?.payload?.data]);
        showToast(t("Ticket Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateDescriptionProgress(false);
      setLoading(false);
      editDescriptionHandler(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateDescriptionProgress(false);
      setLoading(false);
    }
  };

  const updateSupportTicketTitleHandler = async (updatePayload: any) => {
    try {
      setUpdateFieldProgress(true);
      const response = await dispatch(updateTicketTextFields(updatePayload));
      if (response?.payload?.statusCode === 200) {
        setEditSupportTicketId(null);
        setRowSelected(response?.payload?.data);
        showToast(t("Ticket Updated"), "success");
        setUpdateFieldProgress(false);
      } else if (response.error) {
        handleError(response.payload.data);
        setEditSupportTicketId(null);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateFieldProgress(false);
    }
  };

  const handleStatusOptionChange = async (e: any) => {
    const selectedStatusObject = e.target.value;
    setSelectedStatusOption(selectedStatusObject);
  };

  const updateSupportTicketStatusHandler = async (updatePayload: any) => {
    try {
      setUpdateFieldProgress(true);
      const response = await dispatch(updateTicketStatus(updatePayload));
      if (response?.payload?.statusCode === 200) {
        setRowSelected(response?.payload?.data);
        setEditStatusSupportTicketId(null);
        showToast(t("Ticket Updated"), "success");
        setUpdateFieldProgress(false);
      } else if (response.error) {
        handleError(response.payload.data);
        setEditStatusSupportTicketId(null);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateFieldProgress(false);
    }
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  const handlePostComment = async (commentData: any) => {
    if (commentData.comment) {
      try {
        setLoading(true);
        const response = await dispatch(createComment(commentData));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Comment posted successfully"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
          setLoading(false);
        }
        await dispatch(getTicket({ id: rowSelect[0]?.ticketId }));
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Comment data is empty.");
    }
  };
  const getStatusColor = (status: any) => {
    switch (status) {
      case "In Progress":
        return `${theme.palette.inProgress}`;
      case "To Do":
        return `${theme.palette.todo}`;
      default:
        return `${theme.palette.done}`;
    }
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getTicketById = async (ticketId: string) => {
    try {
      const response: any = await dispatch(getTicket({ id: ticketId }));

      if (response.payload.statusCode === 200) {
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateInProgress(false);
    } catch (error) {
      setUpdateInProgress(false);
      console.error("Error fetching ticket data:", error);
    }
  };

  const handleAssetChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setAssetsLoading(true);
    try {
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          assets: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedAsset(response?.payload?.data?.assets);
        showToast(t("Ticket updated"), "success");
        await getTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setAssetsLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setAssetsLoading(false);
    }
  };

  const handleVendorChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setVendorsLoading(true);
    try {
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          vendors: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedVendor(response?.payload?.data?.vendors);
        showToast(t("Vendor updated"), "success");
        await getTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setVendorsLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setVendorsLoading(false);
    }
  };
  const handleRuleChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setRulesLoading(true);
    try {
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          rules: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedRule(response?.payload?.data?.rules);
        showToast(t("Rule updated"), "success");
        await getTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setRulesLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setRulesLoading(false);
    }
  };

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedData = stableSort(
    supportTicketTable,
    getComparator(order, orderBy)
  );

  const filteredData = sortedData.filter((ticket: any) => {
    if (!ticket) {
      return false;
    }
    const { title, status, type } = ticket;
    const formattedDate = moment(ticket.date).format("ll");
    const lowerCaseSearch = search.toLowerCase();
    return (
      title.toLowerCase().includes(lowerCaseSearch) ||
      status.toLowerCase().includes(lowerCaseSearch) ||
      type.toLowerCase().includes(lowerCaseSearch) ||
      formattedDate.includes(lowerCaseSearch)
    );
  });

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClearSearch = () => {
    setSearch("");
  };

  if (isFetching) {
    return <SupportTicketSkelton />;
  }

  return (
    <>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <div className="control-section">
        <div
          style={{
            maxWidth: "350px",
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            className="centerContentBlock__title page_title"
            sx={{ mb: "15px" }}
            align="center"
          >
            {t("Tickets")}
          </Typography>
        </div>
        <div id="wrapper">
          {rowSelect?.length > 0 && (
            <SupportTicketDrawer
              isUpdateInProgress={isUpdateInProgress}
              updateFieldProgress={updateFieldProgress}
              updateTitleProgress={updateTitleProgress}
              updateDescriptionProgress={updateDescriptionProgress}
              rightDrawer={rightDrawer}
              toggleDrawer={toggleDrawer}
              rowSelect={rowSelect}
              setRowSelected={setRowSelected}
              editTitle={editTitle}
              editTitleHandler={editTitleHandler}
              updateTitleHandler={updateTitleHandler}
              handleTypeChange={handleTypeChange}
              handleVisibilityToggle={handleVisibilityToggle}
              handleValueChange={handleValueChange}
              editDescription={editDescription}
              editDescriptionHandler={editDescriptionHandler}
              updateDescriptionHandler={updateDescriptionHandler}
              commentDataById={selectedTicket}
              handleImageClick={handleImageClick}
              handleRemoveAttachment={handleRemoveAttachment}
              selectedImageUrl={selectedImageUrl}
              isAttachmentPreviewOpen={isAttachmentPreviewOpen}
              setIsAttachmentPreviewOpen={setIsAttachmentPreviewOpen}
              handleClick={handleClick}
              isAddAttachmentModalOpen={isAddAttachmentModalOpen}
              closeAddAttachmentModal={closeAddAttachmentModal}
              selectedSupportTicketId={selectedSupportTicketId}
              setCommentData={setCommentData}
              commentData={commentData}
              handleAssetChange={handleAssetChange}
              handleVendorChange={handleVendorChange}
              handleRuleChange={handleRuleChange}
              assets={selectedAsset}
              vendors={selectedVendor}
              rules={selectedRule}
              handlePostComment={handlePostComment}
              loading={loading}
              rulesLoading={rulesLoading}
              assetsLoading={assetsLoading}
              vendorsLoading={vendorsLoading}
              allCommentData={allCommentData}
              isFetchingRequest={isFetchingRequest}
              showToast={showToast}
            />
          )}
          <div>
            <Box>
              <Box
                className="TableDark TableConnect support-table new-support-table"
                sx={{
                  overflow: "unset !important",
                  marginTop: "50px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{
                        color: theme.palette.text.primary,
                        marginRight: 16,
                      }}
                    >
                      {t("Status")}:
                    </Typography>
                    <Box className="DropdownFieldScss" sx={{ width: 200 }}>
                      <FormControl variant="standard" fullWidth>
                        <Select
                          labelId="duration-select-label"
                          id="duration-select"
                          value={selectedStatusOption}
                          onChange={handleStatusOptionChange}
                          displayEmpty
                        >
                          {ticketStatusOptions.map((duration) => (
                            <MenuItem
                              key={duration.value}
                              value={duration.value}
                            >
                              {duration.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box>
                    <OutlinedInput
                      sx={{
                        margin: "0 !important",
                        border: "unset !important",
                        "& .MuiInputBase-input": {
                          padding: "6px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: `${theme.palette.activeColor}`,
                        },
                        "&:hover": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${theme.palette.activeColor}`,
                          },
                        },
                        "&.Mui-focused": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${theme.palette.activeColor}`,
                            borderWidth: "1px",
                          },
                        },
                      }}
                      placeholder={t("Search")}
                      value={search}
                      onChange={handleSearchChange}
                      endAdornment={
                        <InputAdornment position="end">
                          {search ? (
                            <IconButton
                              onClick={handleClearSearch}
                              edge="end"
                              className="search-icon-button-hover"
                            >
                              <Clear
                                style={{
                                  color: `${theme.palette.activeColor}`,
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              className="search-icon-button-hover"
                            >
                              <Search
                                style={{
                                  color: `${theme.palette.activeColor}`,
                                }}
                              />
                            </IconButton>
                          )}
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Box>
                <Paper
                  sx={{
                    width: "100%",
                    mb: 2,
                    border: `1px solid ${theme.palette.activeColor}`,
                  }}
                >
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead sx={{}}>
                          <TableRow>
                            {mySupportTicketColumn.map((column, index) => (
                              <TableCell
                                sx={{ padding: "16px 8px" }}
                                key={column.id}
                                style={{
                                  width: column.minWidth,
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                }}
                                sortDirection={
                                  orderBy === column.id ? order : false
                                }
                              >
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={
                                    orderBy === column.id ? order : "asc"
                                  }
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginatedData?.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                {t("No Ticket Found")}
                              </TableCell>
                            </TableRow>
                          ) : (
                            paginatedData.map((row: any) => (
                              <TableRow
                                key={row.id}
                                onClick={() => handleRowClick(row)}
                                sx={{
                                  "&. MuiTableCell-root": {
                                    height: "70px",
                                  },
                                }}
                                onMouseEnter={() =>
                                  setHoveredRowId(row?.ticketId)
                                }
                                onMouseLeave={() => setHoveredRowId("")}
                              >
                                <TableCell
                                  sx={{ height: "64px", padding: "4px 8px" }}
                                >
                                  {editSupportTicketId === row?.ticketId ? (
                                    <EditSupportTicketTableTitle
                                      updateFieldProgress={updateFieldProgress}
                                      title={row?.title || ""}
                                      editTitleHandler={
                                        editSupportTicketTitleHandler
                                      }
                                      ticketId={row?.ticketId}
                                      updateSupportTicketHandler={
                                        updateSupportTicketTitleHandler
                                      }
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: "28px",
                                      }}
                                    >
                                      <span className="edit-document-text">
                                        {row?.title}
                                      </span>
                                      {editSupportTicketId == null && (
                                        <Button
                                          sx={{ justifyContent: "end" }}
                                          className="support-edit-icon"
                                          onClick={(e) => {
                                            setEditSupportTicketId(
                                              row?.ticketId
                                            );
                                            setEditStatusSupportTicketId(null);
                                          }}
                                          disabled={!isBoardRole}
                                        >
                                          {isBoardRole && (
                                            <ModeEditIcon
                                              fontSize="small"
                                              sx={{
                                                color:
                                                  hoveredRowId === row?.ticketId
                                                    ? `${theme.palette.activeColor}`
                                                    : "transparent",
                                              }}
                                            />
                                          )}
                                        </Button>
                                      )}
                                    </Box>
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={{ height: "64px", padding: "4px 8px" }}
                                >
                                  {t(row.type)}
                                </TableCell>
                                <TableCell
                                  sx={{ height: "64px", padding: "4px 8px" }}
                                >
                                  {editStatusSupportTicketId ===
                                  row?.ticketId ? (
                                    <EditSupportTicketStatus
                                      updateFieldProgress={updateFieldProgress}
                                      status={row.status || ""}
                                      editStatusHandler={editStatusHandler}
                                      updateStatusHandler={
                                        updateSupportTicketStatusHandler
                                      }
                                      ticketId={row?.ticketId}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: getStatusColor(row.status),
                                          opacity: 1,
                                        }}
                                      >
                                        {t(row.status)}
                                      </span>
                                      {editStatusSupportTicketId == null && (
                                        <Button
                                          sx={{ justifyContent: "end" }}
                                          className="support-edit-icon"
                                          onClick={() => {
                                            setEditStatusSupportTicketId(
                                              row?.ticketId
                                            );
                                            setEditSupportTicketId(null);
                                          }}
                                          disabled={!isBoardRole}
                                        >
                                          {isBoardRole && (
                                            <ModeEditIcon
                                              fontSize="small"
                                              sx={{
                                                color:
                                                  hoveredRowId === row?.ticketId
                                                    ? `${theme.palette.activeColor}`
                                                    : "transparent",
                                              }}
                                            />
                                          )}
                                        </Button>
                                      )}
                                    </Box>
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={{ height: "64px", padding: "4px 8px" }}
                                >
                                  {generateFullDate(row.date)}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                          {paginatedData?.length === 1 && (
                            <TableRow style={{ height: 40 }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      sx={{}}
                      rowsPerPageOptions={[25, 50, 100]}
                      component="div"
                      count={filteredData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("Rows per page")}
                    />
                  </Box>
                </Paper>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportTickets;
