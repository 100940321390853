import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
  Paper,
  TextField,
  Autocomplete,
  Popper,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../Redux/Store";
import { createAssociation } from "../../../Redux/Feature/Auth-slice";
import { updateUserAssociationList } from "../../../Redux/Feature/Auth-slice";
import SpinnerLoader from "../../Common/SpinnerLoader/SpinnerLoader";
import { COUNTRY_CODES } from "../../../Utils/utils";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { SendFeedback } from "../../../Redux/Feature/Auth-slice";

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  showToast: (message: any, toastType: string) => void;
}

const roleOptions = [
  { title: "Board", value: "board" },
  { title: "Manager", value: "manager" },
];
const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  showToast,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const [validationErrors, setValidationErrors] = useState<any>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFeedback((prevState) => value);
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      feedback: "",
    }));
  };
  const reset = () => {
    setFeedback("");
  };
  const validateForm = (data: any) => {
    const errors: any = {};

    if (feedback === "") {
      errors.title = "Feedback is required";
    }
    return errors;
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const validationData: any = validateForm(feedback);
    if (Object.keys(validationData).length > 0) {
      setValidationErrors(validationData);
      setLoading(false);
    } else {
      try {
        const response = await dispatch(SendFeedback({ feedback }));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Feedback Sent"), "success");
        } else {
          showToast(t("Failed to send feedback"), "error");
        }
        reset();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };
  return (
    <>
      <SpinnerLoader loading={loading} />
      <Modal open={isOpen} onClose={onClose} sx={{ zIndex: 100 }}>
        <Box
          className="add-document add-association-model"
          sx={{ width: "500px !important", paddingRight: "40px" }}
        >
          <Box
            sx={{ m: "20px 30px 40px" }}
            justifyContent={"center"}
            display="flex"
            onClick={() => onClose()}
          >
            <Typography
              className="modal-modal-title"
              variant="h4"
              component="h4"
            >
              {t("Send App Feedback")}
            </Typography>
            <CancelIcon
              className="support-ticket-cross-icon"
              sx={{
                color: theme.palette.mode === "light" ? "#b7b7b7" : "#fff",
              }}
            />
          </Box>
          <form onSubmit={handleSubmit}>
            <div className="formField">
              <textarea
                className="textAreaInput"
                name="description"
                value={feedback || ""}
                onChange={handleChange}
                placeholder={t("Write your Feedback here...")}
              />
              {validationErrors.feedback && (
                <Typography color="error">
                  {validationErrors.feedback}
                </Typography>
              )}
            </div>
            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#fff" }} />
                ) : (
                  t("Submit")
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default FeedbackModal;
