import { useRef, useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Popper,
} from "@mui/material";
import "./setting.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleTheme } from "../../Redux/Feature/Theme-slice";
import removePhoto from "../../Assets/Images/SVG/removePhoto.svg";
import uploadPhoto from "../../Assets/Images/SVG/uploadPhoto.svg";
import {
  changeUserDefaultAssociation,
  personAssociations,
  updateUser,
  UpdatePersonEmail,
  UpdatePersonName,
  removeProfilePicture,
  logoutUser,
  updatePersonSubscription,
  UpdatePerson,
  authDeleteAccount,
  uploadVerificationPicture,
  removeVerificationPicture,
} from "../../Redux/Feature/Auth-slice";
import { GetOrganization } from "../../Redux/Feature/Setting-slice";
import {
  PreSignedUrlForUploadPicture,
  uploadProfilePicture,
} from "../../Redux/Feature/Setting-slice";
import DeleteAccountModal from "../../Component/Modals/SettingsModals/DeleteAccountModal";
import SpinnerLoader from "../../Component/Common/SpinnerLoader/SpinnerLoader";
import Toast from "../../Component/Common/Toast/Toast";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingField from "../../Component/Common/DetailFields/SettingField";
import { useAuth0 } from "@auth0/auth0-react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AddressDetailField from "../../Component/Common/DetailFields/AddressDetailField";
import DateTimeDetailField from "../../Component/Common/DetailFields/DateTimeDetailField";
import OrganizationSettings from "./OrganizationSettings";
import { translations, letters, verificationTypes } from "../../Utils/utils";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { date } from "yup";
import { RootState } from "../../Redux/Store";
import { profile } from "console";

const Setting = () => {
  const theme = useTheme();
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const mapRef: any = useRef(null);
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const defaultAssociation = useSelector(
    (store: any) => store.Auth.defaultAssociation
  );
  const uploadedPic = useSelector(
    (store: any) => store?.Setting?.uploadedProfilePicURL
  );
  const userAssociations = useSelector(
    (state: any) => state.Auth.userAssociations
  );

  const user = useSelector((state: any) => state.Auth.user);
  // const [value, setValue] = useState(0);
  // const [openDeleteAccountModal, setOpenDeleteAccountModal] =
  //   useState<boolean>(false);

  const dispatch = useDispatch();

  const [uploadedImage, setUploadedImage] = useState<string | null>(
    uploadedPic || null
  );
  const [selectedServices, setSelectedServices] = useState<any>([]);

  const currentOrganization: any = useSelector(
    (state: RootState) => state?.Setting?.organization
  );

  const [organizationPageData, setOrganizationPageData] = useState<any>({
    title: currentOrganization?.title,

    unitSubscriptionCount: currentOrganization?.unitSubscriptionCount,
    subscriptionId: currentOrganization?.subscriptionId,
    accounts: currentOrganization?.accounts?.data,
    address: currentOrganization?.address,
    stripeConnectId: currentOrganization?.stripeConnectId,
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const userLang = navigator.language.slice(0, 2);

  const originalLang = userData.language
    ? translations.find(
        (translation: any) => translation.key === userData.language
      )
    : translations.find((translation: any) => translation.key === userLang) || {
        key: "en",
        value: "English",
      };
  const [selectedLanguage, setSelectedLanguage] = useState<any>(originalLang);
  const [profileData, setProfileData] = useState({
    EmailID: userData?.email,
    Name: userData?.name,
    middleInitial: userData?.middleInitial,
    subscribed: userData?.subscribed,
    profileImageUrl: userData?.profileImageUrl,
    verificationDocument: userData?.verificationDocument,
    verificationDocumentType: userData?.verificationDocumentType,
    phone: userData?.phone,
    emergencyContactName: userData?.emergencyContactName,
    emergencyContactPhone: userData?.emergencyContactPhone,
    emergencyContactEmail: userData?.emergencyContactEmail,
    dateOfBirth: userData?.dateOfBirth,
    address: userData?.address,
    language: originalLang,
  });

  const setUpdatedUserData = () => {
    const profileData = {
      EmailID: userData?.email,
      Name: userData?.name,
      middleInitial: userData?.middleInitial,
      subscribed: userData?.subscribed,
      profileImageUrl: userData?.profileImageUrl,
      verificationDocument: userData?.verificationDocument,
      verificationDocumentType: userData?.verificationDocumentType,
      phone: userData?.phone,
      emergencyContactName: userData?.emergencyContactName,
      emergencyContactPhone: userData?.emergencyContactPhone,
      emergencyContactEmail: userData?.emergencyContactEmail,
      dateOfBirth: userData?.dateOfBirth,
      address: userData?.address,
      language: originalLang,
    };
    setProfileData(profileData);
  };
  useEffect(() => {
    if (userData) {
      setUpdatedUserData();
    }
  }, [userData]);

  const [tab, setTab] = useState(0);
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [editEmergencyContactName, setEditEmergencyContactName] =
    useState(false);
  const [editEmergencyContactEmail, setEditEmergencyContactEmail] =
    useState(false);
  const [editEmergencyContactPhone, setEditEmergencyContactPhone] =
    useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editDateOfBirth, setEditDateOfBirth] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [changeDefaultAssociation, setChangeDefaultAssociation] =
    useState(false);
  const [changeLanguage, setChangeLanguage] = useState(false);
  const [changeInitial, setChangeInitial] = useState(false);
  const [changeVerificationType, setChangeVerificationType] = useState(false);

  const autocomplete: any = useRef(null);
  const [selectedAssociation, setSelectedAssociation] =
    useState<any>(defaultAssociation);

  useEffect(() => {
    setSelectedAssociation(defaultAssociation);
  }, [defaultAssociation]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [, setPreSignedPicResURL] = useState({});

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const MAX_FILE_SIZE_MB = 3;
  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const formattedValue =
      name === "phone" || name === "emergencyContactPhone"
        ? formatPhoneNumberIntl(value)
        : value;
    setProfileData({
      ...profileData,
      [name]: formattedValue,
    });
  };

  useEffect(() => {
    setUploadedImage(userData.profileImageUrl);
  }, [userData.profileImageUrl]);
  const changeDefaultAssociationFunction = () => {
    if (!selectedAssociation || selectedAssociation === undefined) return;
    if (
      defaultAssociation?.associationId === selectedAssociation?.associationId
    )
      return;
    setLoading(true);
    dispatch(
      changeUserDefaultAssociation({
        associationId: selectedAssociation?.associationId,
      })
    )
      .then((res: any) => {
        if (res.payload.statusCode === 200) {
          showToast(t("Default association changed successfully"), "success");
          dispatch(personAssociations({ personId: "", email: userData.email }));
        } else {
          showToast(t("Error"), "error");
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getVerificationTypeValue = (verificationDocumentType: string) => {
    const type = verificationTypes.find(
      (item: any) => item.key === verificationDocumentType
    );
    return type ? type.value : "Select Type";
  };

  const getInitialValue = (middleInitial: string) => {
    const letter = letters.find((item: any) => item.key === middleInitial);
    return letter ? letter.value : "Select Initial";
  };
  const handleVerificationFileChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const fileObj = event.target.files?.[0];
    if (fileObj) {
      if (!ALLOWED_FILE_TYPES.includes(fileObj.type)) {
        showToast(
          t("Invalid file type. Please upload a .jpeg, .jpg, or .png file."),
          "error"
        );
        return;
      }

      if (fileObj.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setImageError(true);

        showToast(
          t(
            "File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file."
          ),
          "error"
        );
        return;
      }
      setLoading(true);
      dispatch(
        PreSignedUrlForUploadPicture({
          fileName: fileObj.name,
        })
      )
        // const res = handleFile(fileObj);
        .then((res: any) => {
          if (res.payload && res.payload.statusCode === 200) {
            setPreSignedPicResURL(res.payload.data);
            const signedUrl = res.payload.data.signedUrl;
            const key = res.payload.data.key;
            setLoading(true);
            fetch(signedUrl, {
              method: "PUT",
              body: fileObj,
            })
              .then((response) => {
                if (response.status === 200) {
                  setLoading(true);
                  dispatch(uploadVerificationPicture(key))
                    .then((res: any) => {
                      let userData = JSON.parse(
                        localStorage.getItem("user") || ""
                      );
                      userData.verificationDocument = res?.payload?.data;
                      localStorage.setItem("user", JSON.stringify(userData));
                      dispatch(updateUser());
                      setUploadedImage(res?.payload?.data?.url);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files?.[0];
    if (fileObj) {
      if (!ALLOWED_FILE_TYPES.includes(fileObj.type)) {
        showToast(
          t("Invalid file type. Please upload a .jpeg, .jpg, or .png file."),
          "error"
        );
        return;
      }

      if (fileObj.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setImageError(true);

        showToast(
          t(
            "File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file."
          ),
          "error"
        );
        return;
      }
      setLoading(true);
      dispatch(
        PreSignedUrlForUploadPicture({
          fileName: fileObj.name,
        })
      )
        // const res = handleFile(fileObj);
        .then((res: any) => {
          if (res.payload && res.payload.statusCode === 200) {
            setPreSignedPicResURL(res.payload.data);
            const signedUrl = res.payload.data.signedUrl;
            const key = res.payload.data.key;
            setLoading(true);
            fetch(signedUrl, {
              method: "PUT",
              body: fileObj,
            })
              .then((response) => {
                if (response.status === 200) {
                  setLoading(true);
                  dispatch(uploadProfilePicture(key))
                    .then((res: any) => {
                      let userData = JSON.parse(
                        localStorage.getItem("user") || ""
                      );
                      userData.profileImageUrl = res?.payload?.data?.url;
                      localStorage.setItem("user", JSON.stringify(userData));
                      dispatch(updateUser());
                      setUploadedImage(res?.payload?.data?.url);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleVerificationRemoveImage = async () => {
    setLoading(true);
    try {
      const resp = await dispatch(removeVerificationPicture(userData.id));
      if (resp?.payload?.statusCode === 200) {
        showToast(t("Updated Person Details"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = async () => {
    setLoading(true);
    try {
      const resp = await dispatch(removeProfilePicture(userData.id));
      if (resp?.payload?.statusCode === 200) {
        setUploadedImage("");
        showToast(t("Updated Person Details"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteModal = (value: boolean): void => {
    setIsDeleteModalOpen(value);
  };
  const handleDeleteAccount = async () => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    try {
      const response = await dispatch(authDeleteAccount(userData.id));
      if (response?.payload?.statusCode === 200) {
        dispatch(logoutUser());
        logout({ logoutParams: { returnTo: window.location.origin } });
      } else if (response && response?.response?.status === 400) {
        const errorResponse = response?.response?.data?.error;
        if (
          errorResponse.includes(
            "Assets must be removed from account to delete"
          )
        ) {
          showToast(
            t(
              "Assets still exist for your profile, contact zayuk or association to delete your account"
            ),
            "error"
          );
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const fetchOrganization = async () => {
    if (user?.organizationId === null) return;
    const response = await dispatch(GetOrganization(user.organizationId));
    if (response?.payload?.statusCode === 200) {
      setOrganizationPageData({
        ...organizationPageData,
        title: response?.payload?.data?.title,
        address: response?.payload?.data?.address,
        services: response?.payload?.data?.services,
        stripeConnectId: response?.payload?.data?.stripeConnectId,
      });
      setSelectedServices(response?.payload?.data?.services);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, [user?.organizationId]);

  const changeSubscriptionHandler = async (event: any) => {
    setLoading(true);
    try {
      const resp = await dispatch(
        updatePersonSubscription({ subscribed: !profileData.subscribed })
      );
      profileData.subscribed = !profileData.subscribed;

      if (resp?.payload?.statusCode === 200) {
        showToast(t("Updated Person Details"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEmail = async () => {
    setLoading(true);
    try {
      if (userData) {
        const payload = {
          email: profileData?.EmailID,
        };
        const response = await dispatch(UpdatePersonEmail(payload));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Updated Person Details"), "success");
        } else if (response?.payload?.status === 400) {
          showToast(t("Error"), "error");
        }
      }
      setEditEmail(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSaveName = async () => {
    setLoading(true);
    try {
      if (userData) {
        const payload = {
          name: profileData?.Name,
        };
        const response = await dispatch(UpdatePersonName(payload));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Updated Person Details"), "success");
        } else {
          showToast(t("Error"), "error");
        }
      }
      setEditName(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const handlePlaceChanged = async () => {
    if (autocomplete.current) {
      const place = autocomplete.current.getPlace();
      if (!place || !place?.geometry || !place?.geometry?.location) {
        showToast(
          t("Please select an address from the dropdown list"),
          "error"
        );
        return;
      }
      const addressData: any = {};
      addressData.address = place?.formatted_address;
      var bounds = place.geometry.viewport.toJSON();

      addressData.latitude = (bounds.north + bounds.south) / 2;
      addressData.longitude = (bounds.east + bounds.west) / 2;
      addressData.latitudeDelta = bounds.north - bounds.south;
      addressData.longitudeDelta = bounds.east - bounds.west;

      if (mapRef?.current) {
        mapRef?.current?.panTo(place?.geometry?.location);
      }
      place.address_components.forEach((component: any) => {
        const types = component.types;

        types.forEach((type: any) => {
          switch (type) {
            case "street_number": {
              addressData.streetNumber = `${component.long_name}`;
              break;
            }
            case "route": {
              addressData.streetName = component.short_name;
              break;
            }
            case "locality":
              addressData.city = component.long_name;
              break;
            case "administrative_area_level_1":
              addressData.state = component.long_name;
              break;
            case "country":
              addressData.country = component.long_name;
              break;
            case "postal_code":
              addressData.postalCode = component.long_name;
              break;
            default:
              break;
          }
        });
      });
      const payload = {
        address: addressData.address,
        city: addressData.city,
        state: addressData.state,
        postalCode: addressData.postalCode,
        country: addressData.country,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };

      setEditAddress(!editAddress);
      const response = await dispatch(UpdatePerson(payload));

      if (response?.payload?.statusCode === 200) {
        showToast(t("Updated Person Details"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    }
  };
  const handleLanguageChange = async () => {
    setLoading(true);
    try {
      if (userData) {
        const payload = {
          language: selectedLanguage?.key,
        };
        const response = await dispatch(UpdatePerson(payload));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Updated Person Details"), "success");
        } else {
          showToast(t("Error"), "error");
        }
      }
    } catch (err) {
    } finally {
      setChangeLanguage(!changeLanguage);
      setLoading(false);
    }
  };
  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };

  const handleDateChanged = async (date: any) => {
    const payload = {
      dateOfBirth: date,
    };
    const response = await dispatch(UpdatePerson(payload));
    setEditDateOfBirth(false);
    if (response?.payload?.statusCode === 200) {
      showToast(t("Updated Person Details"), "success");
    } else {
      showToast(t("Error"), "error");
    }
  };

  const handleSavePersonDetails = async (payload: any) => {
    setLoading(true);
    try {
      if (userData) {
        const response = await dispatch(UpdatePerson(payload));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Updated Person Details"), "success");
        } else {
          showToast(t("Error"), "error");
        }
      }
      setEditPhoneNumber(false);
      setEditEmergencyContactName(false);
      setEditEmergencyContactEmail(false);
      setEditEmergencyContactPhone(false);
      setEditAddress(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.background.default,
          boxShadow: "none",
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="settings tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: `${theme.palette.activeColor}`,
            },
          }}
        >
          <Tab
            label={
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color:
                    tab === 0
                      ? `${theme.palette.activeColor}`
                      : theme.palette.text.primary,
                }}
              >
                {t("General")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color:
                    tab === 1
                      ? `${theme.palette.activeColor}`
                      : theme.palette.text.primary,
                }}
              >
                {t("Contact")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color:
                    tab === 2
                      ? `${theme.palette.activeColor}`
                      : theme.palette.text.primary,
                }}
              >
                {t("Organization")}
              </Typography>
            }
          />
        </Tabs>
      </AppBar>
      <div className="profile">
        <SpinnerLoader loading={loading} />
        <Toast
          open={openToast}
          message={`${toastMessage}.`}
          handleClose={handleCloseToast}
          type={toastType as "success" | "error"}
        />

        <Box>
          {tab === 0 && (
            <div>
              <Box className="profile__container">
                <Box className="profile__btn">
                  <Box className="profile__btnBox">
                    <Box
                      className="profile__btnBox-btnCircle"
                      style={{
                        backgroundImage: `url(${uploadedPic})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        overflow: "hidden",
                      }}
                    >
                      {uploadedImage ? (
                        <>
                          <img
                            src={uploadedImage && uploadedImage}
                            alt="Uploaded Profile"
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </>
                      ) : (
                        <AccountCircleIcon style={{ fontSize: "200px" }} />
                      )}
                    </Box>

                    <Box>
                      {uploadedImage ? (
                        <Button
                          className="btn btn-theme btn-red"
                          onClick={handleRemoveImage}
                          sx={{
                            width: "auto !important",
                            paddingX: "16px !important",
                            color: "text.primary",
                            marginBottom: "12px",
                          }}
                        >
                          <img
                            src={removePhoto}
                            alt="remove Profile"
                            style={{ height: "16px", width: "16px" }}
                          />
                          <Typography
                            sx={{ fontSize: "14px", marginLeft: "10px", mb: 0 }}
                          >
                            {t("Remove Photo")}
                          </Typography>
                        </Button>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                          <Button
                            className="btn btn-theme"
                            sx={{
                              width: "auto !important",
                              paddingX: "16px !important",
                              color: "text.primary",
                              marginBottom: "12px",
                            }}
                            onClick={() => {
                              setImageError(false);
                              fileInputRef.current?.click();
                            }}
                          >
                            <img
                              src={uploadPhoto}
                              alt="upload Profile"
                              style={{ height: "20px", width: "20px" }}
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                marginLeft: "10px",
                                mb: 0,
                              }}
                            >
                              {t("Upload Photo")}
                            </Typography>
                          </Button>
                        </>
                      )}
                    </Box>
                    {imageError ? (
                      <Typography className="profile__btnBox-textWarning">
                        {t("File Size Exceed")}
                      </Typography>
                    ) : (
                      <Typography className="profile__btnBox-text">
                        {t("Allowed *.jpeg, *.jpg, *.png size of 3 Mb")}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Button
                      className="btnDelete"
                      sx={{
                        width: "auto !important",
                        paddingX: "16px !important",
                        color: `${theme.palette.deleteText}`,
                      }}
                      onClick={() => handleOpenDeleteModal(true)}
                    >
                      {t("Delete Account")}
                    </Button>
                  </Box>
                </Box>

                <Box className="profile__form">
                  {/* Code for Name */}
                  <SettingField
                    isEditField={editName}
                    fieldLabel={"First & Last Name"}
                    fieldValue={profileData?.Name}
                    fieldName={"Name"}
                    placeholder={"Edit Name"}
                    handleChange={handleChange}
                    setisEditField={setEditName}
                    handleSaveField={handleSaveName}
                    resetUserDataOnCancel={setUpdatedUserData}
                  />

                  {/* Code for Change Middle Initial */}
                  <Box className="formGroup">
                    <Grid container spacing={2}>
                      <Grid item md={10} lg={10}>
                        <Box sx={{ display: "flex", width: "100%" }}>
                          <Grid item md={4} lg={4}>
                            <Typography
                              className="formlabel"
                              sx={{ color: "text.activeColor", mr: "10px" }}
                            >
                              {t("Middle Initial")}:
                            </Typography>
                          </Grid>
                          <Grid item md={8} lg={8}>
                            {!changeInitial ? (
                              <Typography
                                className="formlabel"
                                sx={{ color: "text.primary" }}
                              >
                                {getInitialValue(profileData?.middleInitial)}
                              </Typography>
                            ) : (
                              <Box className="formGroup_inner DrpCustom">
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, width: 300 }}
                                >
                                  <InputLabel
                                    sx={{
                                      color: "white",
                                    }}
                                    variant="outlined"
                                    className="association-dropdown-label"
                                  >
                                    <Typography className="association-dropdown-label-typography">
                                      {getInitialValue(
                                        profileData?.middleInitial
                                      )}
                                    </Typography>
                                  </InputLabel>
                                  <Select
                                    labelId="association-dropdown-select-label"
                                    id="association-dropdown-select"
                                    label={profileData?.middleInitial}
                                    onChange={(e: any) => {
                                      const selectedInitial = e.target.value;
                                      setProfileData({
                                        ...profileData,
                                        middleInitial: selectedInitial.key,
                                      });
                                    }}
                                    value={profileData?.middleInitial}
                                  >
                                    {letters.map((letter: any) => (
                                      <MenuItem key={letter.key} value={letter}>
                                        {t(letter.value)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={2}
                        lg={2}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {!changeInitial ? (
                          <Button
                            variant="contained"
                            className="btn"
                            sx={{
                              width: "auto !important",
                              paddingX: "16px !important",
                            }}
                            onClick={() => setChangeInitial(!changeInitial)}
                          >
                            {t("Edit")}
                          </Button>
                        ) : (
                          <Box sx={{ display: "flex" }}>
                            <Button
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                                mr: "10px",
                              }}
                              variant="contained"
                              className="btn"
                              onClick={() => {
                                setChangeInitial(!changeInitial);
                                handleSavePersonDetails({
                                  middleInitial: profileData?.middleInitial,
                                });
                              }}
                            >
                              {t("Save")}
                            </Button>
                            <Button
                              variant="contained"
                              className="btn"
                              onClick={() => {
                                setChangeInitial(false);
                              }}
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                              }}
                            >
                              {t("Cancel")}
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Code for Default Association */}
                  <Box className="formGroup">
                    <Grid container spacing={2}>
                      <Grid item md={10} lg={10}>
                        <Box sx={{ display: "flex", width: "100%" }}>
                          <Grid item md={4} lg={4}>
                            <Typography
                              className="formlabel"
                              sx={{ color: "text.activeColor", mr: "10px" }}
                            >
                              {t("Default Association")}:
                            </Typography>
                          </Grid>
                          <Grid item md={8} lg={8}>
                            {!changeDefaultAssociation ? (
                              <Typography
                                className="formlabel"
                                sx={{ color: "text.primary" }}
                              >
                                {selectedAssociation.title
                                  ? selectedAssociation.title
                                  : "Set Default Association"}
                              </Typography>
                            ) : (
                              <Box className="formGroup_inner DrpCustom">
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, width: 300 }}
                                >
                                  <InputLabel
                                    sx={{
                                      color: "white",
                                    }}
                                    variant="outlined"
                                    className="association-dropdown-label"
                                  >
                                    <Typography className="association-dropdown-label-typography">
                                      {defaultAssociation.title}
                                    </Typography>
                                  </InputLabel>
                                  <Select
                                    labelId="association-dropdown-select-label"
                                    id="association-dropdown-select"
                                    label={selectedAssociation.title}
                                    onChange={(event) =>
                                      setSelectedAssociation(event.target.value)
                                    }
                                    value={selectedAssociation}
                                  >
                                    {userAssociations.map(
                                      (userAssociation: any) => (
                                        <MenuItem value={userAssociation}>
                                          {userAssociation.title}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={2}
                        lg={2}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {!changeDefaultAssociation ? (
                          <Button
                            variant="contained"
                            sx={{
                              width: "auto !important",
                              paddingX: "16px !important",
                            }}
                            className="btn"
                            onClick={() =>
                              setChangeDefaultAssociation(
                                !changeDefaultAssociation
                              )
                            }
                          >
                            {t("Edit")}
                          </Button>
                        ) : (
                          <Box sx={{ display: "flex" }}>
                            <Button
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                                mr: "10px",
                              }}
                              variant="contained"
                              className="btn"
                              onClick={() => {
                                changeDefaultAssociationFunction();
                                setChangeDefaultAssociation(
                                  !changeDefaultAssociation
                                );
                              }}
                            >
                              {t("Save")}
                            </Button>
                            <Button
                              variant="contained"
                              className="btn"
                              onClick={() => {
                                setSelectedAssociation(defaultAssociation);
                                setChangeDefaultAssociation(false);
                              }}
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                              }}
                            >
                              {t("Cancel")}
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Code for Change Language */}
                  <Box className="formGroup">
                    <Grid container spacing={2}>
                      <Grid item md={10} lg={10}>
                        <Box sx={{ display: "flex", width: "100%" }}>
                          <Grid item md={4} lg={4}>
                            <Typography
                              className="formlabel"
                              sx={{ color: "text.activeColor", mr: "10px" }}
                            >
                              {t("Language")}:
                            </Typography>
                          </Grid>
                          <Grid item md={8} lg={8}>
                            {!changeLanguage ? (
                              <Typography
                                className="formlabel"
                                sx={{ color: "text.primary" }}
                              >
                                {t(originalLang.value)}
                              </Typography>
                            ) : (
                              <Box className="formGroup_inner DrpCustom">
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, width: 300 }}
                                >
                                  <InputLabel
                                    sx={{
                                      color: "white",
                                    }}
                                    variant="outlined"
                                    className="association-dropdown-label"
                                  >
                                    <Typography className="association-dropdown-label-typography">
                                      {t(originalLang.value)}
                                    </Typography>
                                  </InputLabel>
                                  <Select
                                    labelId="association-dropdown-select-label"
                                    id="association-dropdown-select"
                                    label={selectedLanguage.value}
                                    onChange={(e: any) => {
                                      const selectedLanguage = e.target.value;

                                      setSelectedLanguage(selectedLanguage);
                                    }}
                                    value={selectedLanguage}
                                  >
                                    {translations.map((translation: any) => (
                                      <MenuItem
                                        key={translation.key}
                                        value={translation}
                                      >
                                        {t(translation.value)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={2}
                        lg={2}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {!changeLanguage ? (
                          <Button
                            variant="contained"
                            className="btn"
                            sx={{
                              width: "auto !important",
                              paddingX: "16px !important",
                            }}
                            onClick={() => setChangeLanguage(!changeLanguage)}
                          >
                            {t("Edit")}
                          </Button>
                        ) : (
                          <Box sx={{ display: "flex" }}>
                            <Button
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                                mr: "10px",
                              }}
                              variant="contained"
                              className="btn"
                              onClick={() => {
                                setChangeLanguage(!changeLanguage);
                                handleLanguageChange();
                              }}
                            >
                              {t("Save")}
                            </Button>
                            <Button
                              variant="contained"
                              className="btn"
                              onClick={() => {
                                setChangeLanguage(false);
                              }}
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                              }}
                            >
                              {t("Cancel")}
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Code for Email */}
                  <SettingField
                    isEditField={editEmail}
                    fieldLabel={"Email"}
                    fieldValue={profileData?.EmailID}
                    fieldName={"EmailID"}
                    placeholder={"Edit Email"}
                    handleChange={handleChange}
                    setisEditField={setEditEmail}
                    handleSaveField={handleSaveEmail}
                    resetUserDataOnCancel={setUpdatedUserData}
                  />

                  <Box className="formGroup">
                    <Typography
                      className="formlabel"
                      sx={{ color: "text.primary" }}
                    >
                      {t("Subscribe")}
                    </Typography>
                    <Box
                      className="toggleBtn"
                      display={"flex"}
                      alignItems={"center"}
                      style={{ gap: "25px" }}
                    >
                      <Typography sx={{ color: "text.primary" }}>
                        {userData?.subscribed === true ? t("On") : t("Off")}
                      </Typography>
                      <Switch
                        onChange={(e: any) => changeSubscriptionHandler(e)}
                        checked={userData?.subscribed}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          )}
          {tab === 1 && (
            <div>
              <Box className="profile__container">
                {/* <Box className-="profile__btn" sx={{ marginTop: "16px" }}>
                  <Box className="profile__btnBox">
                    <Box className="formGroup">
                      <Grid container spacing={2}>
                        {/* <Grid item md={4} lg={4}> */}
                {/* <Typography
                          className="formlabel"
                          sx={{ color: "text.activeColor", mr: "10px" }}
                        >
                          {t("Verification Document Type")}:
                        </Typography>
                        {/* </Grid> 
                        <Grid item md={10} lg={10}>
                          <Box sx={{ display: "flex", width: "100%" }}>
                            <Grid item md={8} lg={8}>
                              {!changeVerificationType ? (
                                <Typography
                                  className="formlabel"
                                  sx={{ color: "text.primary" }}
                                >
                                  {t(
                                    getVerificationTypeValue(
                                      profileData?.verificationDocumentType
                                    )
                                  )}
                                </Typography>
                              ) : (
                                <Box className="formGroup_inner DrpCustom">
                                  <FormControl
                                    variant="standard"
                                    sx={{ m: 1, width: 100 }}
                                  >
                                    <InputLabel
                                      sx={{
                                        color: "white",
                                      }}
                                      variant="outlined"
                                      className="association-dropdown-label"
                                    >
                                      <Typography className="association-dropdown-label-typography">
                                        {t(
                                          getVerificationTypeValue(
                                            profileData?.verificationDocumentType
                                          )
                                        )}
                                      </Typography>
                                    </InputLabel>
                                    <Select
                                      labelId="association-dropdown-select-label"
                                      id="association-dropdown-select"
                                      label={
                                        profileData?.verificationDocumentType
                                      }
                                      onChange={(e: any) => {
                                        const selectedVerificationType =
                                          e.target.value;
                                        setProfileData({
                                          ...profileData,
                                          verificationDocumentType:
                                            selectedVerificationType.key,
                                        });
                                      }}
                                      value={
                                        profileData?.verificationDocumentType
                                      }
                                    >
                                      {verificationTypes.map((type: any) => (
                                        <MenuItem key={type.key} value={type}>
                                          {t(type.value)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              )}
                            </Grid>
                          </Box>
                        </Grid> 
                        <Grid
                          item
                          md={2}
                          lg={2}
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "flex-end",
                          }}
                        >
                          {!changeVerificationType ? (
                            <Box sx={{ display: "flex" }}>
                              <Button
                                variant="contained"
                                className="btn"
                                sx={{
                                  width: "auto !important",
                                  paddingX: "16px !important",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setChangeVerificationType(
                                    !changeVerificationType
                                  )
                                }
                              >
                                {t("Edit")}
                              </Button>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex" }}>
                              <Button
                                sx={{
                                  width: "auto !important",
                                  paddingX: "16px !important",
                                  mr: "10px",
                                }}
                                variant="contained"
                                className="btn"
                                onClick={() => {
                                  setChangeVerificationType(
                                    !changeVerificationType
                                  );
                                  handleSavePersonDetails({
                                    verificationDocumentType:
                                      profileData?.verificationDocumentType,
                                  });
                                }}
                              >
                                {t("Save")}
                              </Button>
                              <Button
                                variant="contained"
                                className="btn"
                                onClick={() => {
                                  setChangeVerificationType(
                                    !changeVerificationType
                                  );
                                }}
                                sx={{
                                  width: "auto !important",
                                  paddingX: "16px !important",
                                }}
                              >
                                {t("Cancel")}
                              </Button>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    {profileData?.verificationDocument ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginLeft: "10px",
                            mb: 0,
                            color: theme.palette.text.primary,
                          }}
                        >
                          {t("Your verification photo is uploaded")}
                        </Typography>
                        <Button
                          className="btn btn-theme btn-red"
                          onClick={handleVerificationRemoveImage}
                          sx={{
                            width: "auto !important",
                            paddingX: "16px !important",
                            color: "text.primary",
                            marginBottom: "12px",
                          }}
                        >
                          <img
                            src={removePhoto}
                            alt="remove Profile"
                            style={{ height: "16px", width: "16px" }}
                          />
                          <Typography
                            sx={{ fontSize: "14px", marginLeft: "10px", mb: 0 }}
                          >
                            {t("Remove Verification Photo")}
                          </Typography>
                        </Button>
                      </>
                    ) : (
                      <>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleVerificationFileChange}
                        />
                        <Button
                          className="btn btn-theme"
                          sx={{
                            width: "auto !important",
                            paddingX: "16px !important",
                            color: "text.primary",
                            marginBottom: "12px",
                          }}
                          onClick={() => {
                            setImageError(false);
                            fileInputRef.current?.click();
                          }}
                        >
                          <img
                            src={uploadPhoto}
                            alt="upload Verification"
                            style={{ height: "20px", width: "20px" }}
                          />
                          <Typography
                            sx={{ fontSize: "14px", marginLeft: "10px", mb: 0 }}
                          >
                            {t("Upload Verification Photo")}
                          </Typography>
                        </Button>
                      </>
                    )}
                  </Box>
                  {imageError ? (
                    <Typography className="profile__btnBox-textWarning">
                      {t("File Size Exceed")}
                    </Typography>
                  ) : (
                    <Typography className="profile__btnBox-text">
                      {t("Allowed *.jpeg, *.jpg, *.png size of 3 Mb")}
                    </Typography>
                  )}
                </Box> */}
                <Box className="profile__form">
                  {/* Code for Phone Number */}
                  <SettingField
                    isEditField={editPhoneNumber}
                    fieldLabel={"Phone Number"}
                    fieldValue={profileData?.phone}
                    fieldName={"phone"}
                    placeholder={"Edit Phone"}
                    handleChange={handleChange}
                    setisEditField={setEditPhoneNumber}
                    handleSaveField={() =>
                      handleSavePersonDetails({
                        phone: profileData?.phone,
                      })
                    }
                    resetUserDataOnCancel={setUpdatedUserData}
                  />

                  <DateTimeDetailField
                    isEditField={editDateOfBirth}
                    fieldLabel={"Date of Birth"}
                    fieldValue={profileData?.dateOfBirth}
                    setIsEditField={setEditDateOfBirth}
                    handleSaveField={handleDateChanged}
                    isValueEdit={true}
                  />

                  <AddressDetailField
                    isEditField={editAddress}
                    fieldLabel={"Address"}
                    fieldValue={profileData?.address}
                    setisEditField={setEditAddress}
                    handleSaveField={handlePlaceChanged}
                    isNameEdit={true}
                    autocomplete={autocomplete}
                  />

                  {/* Code for Emergency Contact Name */}
                  <SettingField
                    isEditField={editEmergencyContactName}
                    fieldLabel={"Emergency Contact Name"}
                    fieldValue={profileData?.emergencyContactName}
                    fieldName={"emergencyContactName"}
                    placeholder={"Edit Emergency Contact"}
                    handleChange={handleChange}
                    setisEditField={setEditEmergencyContactName}
                    handleSaveField={() =>
                      handleSavePersonDetails({
                        emergencyContactName: profileData?.emergencyContactName,
                      })
                    }
                    resetUserDataOnCancel={setUpdatedUserData}
                  />
                  {/* Code for Emergency Contact Email */}
                  <SettingField
                    isEditField={editEmergencyContactEmail}
                    fieldLabel={"Emergency Contact Email"}
                    fieldValue={profileData?.emergencyContactEmail}
                    fieldName={"emergencyContactEmail"}
                    placeholder={"Edit Emergency Contact"}
                    handleChange={handleChange}
                    setisEditField={setEditEmergencyContactEmail}
                    handleSaveField={() =>
                      handleSavePersonDetails({
                        emergencyContactEmail:
                          profileData?.emergencyContactEmail,
                      })
                    }
                    resetUserDataOnCancel={setUpdatedUserData}
                  />
                  {/* Code for Emergency Contact Phone */}
                  <SettingField
                    isEditField={editEmergencyContactPhone}
                    fieldLabel={"Emergency Contact Phone"}
                    fieldValue={profileData?.emergencyContactPhone}
                    fieldName={"emergencyContactPhone"}
                    placeholder={"Edit Emergency Contact"}
                    handleChange={handleChange}
                    setisEditField={setEditEmergencyContactPhone}
                    handleSaveField={() =>
                      handleSavePersonDetails({
                        emergencyContactPhone:
                          profileData?.emergencyContactPhone,
                      })
                    }
                    resetUserDataOnCancel={setUpdatedUserData}
                  />
                </Box>
              </Box>
            </div>
          )}
          {tab === 2 && (
            <div>
              <Box p={3}>
                <OrganizationSettings
                  organizationPageData={organizationPageData}
                  setOrganizationPageData={setOrganizationPageData}
                  currentOrganization={currentOrganization}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  showToast={showToast}
                />
              </Box>
            </div>
          )}
        </Box>
        <DeleteAccountModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteAccount}
        />
      </div>
    </div>
  );
};

export default Setting;
