import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TicketService from "../Service/Ticket-service";

interface Ticket {
  title: string;
  type: string;
  date: Date;
  description: string;
  ticketId: string;
  name: string;
  personId: string;
  status: string;
  attachments: [];
  allComments: comment[];
}

interface comment {
  comment: string;
  commentId: string;
  date: Date;
  personId: string;
  personName: string;
  ticketId: string;
}

interface TicketSliceState {
  isFetching: boolean;
  isFetchingComments: boolean;
  isFetchingRequestTableData: boolean;
  isFetchingRequest: boolean;
  error: string;
  successMessage: string;
  isError: boolean;
  isSuccess: boolean;
  TicketTable: Ticket[];
  lastTicketsKey: string;
  commentData: {
    allComments: comment[];
    rules: any[];
    vendors: any[];
    assets: any[];
    services: any[];
  };
  allAssets: any[];
  commentDataByCommentId: comment[];
  AddAttachmentTicketResponse: any;
}

export const getAllTickets: any = createAsyncThunk(
  "Ticket/get",
  async (getTicketObj, thunkAPI) => {
    const data = await TicketService.getAllTickets(getTicketObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicketStatus: any = createAsyncThunk(
  "HandleTicketStatus/put",
  async (obj, thunkAPI) => {
    const data = await TicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicketTextFields: any = createAsyncThunk(
  "updateTicketTextFields/put",
  async (obj, thunkAPI) => {
    const data = await TicketService.updateTicketTextFields(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicketPrivatePublicToggle: any = createAsyncThunk(
  "HandleTicketPrivatePublicToggle/put",
  async (obj, thunkAPI) => {
    const data = await TicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicketAttachments: any = createAsyncThunk(
  "HandleTicketAttachments/put",
  async (obj, thunkAPI) => {
    const data = await TicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const createTicket: any = createAsyncThunk(
  "Ticket/post",
  async (reqPayload, thunkAPI) => {
    const data = await TicketService.createTicket(reqPayload);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createComment: any = createAsyncThunk(
  "Ticket/comment/post",
  async (reqPayload, thunkAPI) => {
    const data = await TicketService.createComment(reqPayload);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getCommentById: any = createAsyncThunk(
  "getTicket/withComments",
  async ({ id }: { id: string }, thunkAPI) => {
    const data = await TicketService.getCommentById(id);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getTicket: any = createAsyncThunk(
  "Ticket/withComments",
  async ({ id }: { id: string }, thunkAPI) => {
    const data = await await TicketService.getTicket(id);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAllAssetByAssociationId: any = createAsyncThunk(
  "Ticket/withAsset",
  async ({ id }: { id: string }, thunkAPI) => {
    const data = await await TicketService.getAllAssetByAssociationId(id);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const addAttachmentTicket: any = createAsyncThunk(
  "AddAttachmentTicket/post",
  async (initialObj, thunkAPI) => {
    const data = await TicketService.addAttachmentTicket(initialObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicketType: any = createAsyncThunk(
  "HandleTicketType/put",
  async (obj, thunkAPI) => {
    const data = await TicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicket: any = createAsyncThunk(
  "UpdateTicket/put",
  async (obj, thunkAPI) => {
    const data = await TicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

const supportTicketSlice = createSlice({
  name: "Ticket",
  initialState: {
    isFetching: false,
    isFetchingComments: false,
    isFetchingRequestTableData: false,
    isFetchingRequest: false,
    error: "",
    successMessage: "",
    isError: false,
    isSuccess: false,
    TicketTable: [],
    lastTicketsKey: "",
    allAssets: Array(),
    commentData: {
      allComments: [],
      rules: [],
      vendors: [],
      assets: [],
      services: [],
    },
    commentDataByCommentId: [],
    AddAttachmentTicketResponse: {},
  } as TicketSliceState,
  reducers: {
    resetTicketDrawerState: (state) => {
      state.commentData = {
        allComments: [],
        rules: [],
        vendors: [],
        assets: [],
        services: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTickets.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllTickets.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        if (action?.payload?.data?.tickets) {
          state.TicketTable = action?.payload?.data?.tickets;
          state.lastTicketsKey = action?.payload?.data?.lastEvaluatedKey;
        } else {
          state.TicketTable = [];
          state.lastTicketsKey = "";
        }
      })
      .addCase(getAllTickets.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateTicketStatus.pending, (state) => {})
      .addCase(updateTicketStatus.fulfilled, (state, action) => {
        const updatedTicket = action?.payload?.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedSupportTicket = state.TicketTable.findIndex(
          (ticket) => ticket.ticketId === updatedTicket.ticketId
        );
        if (updatedSupportTicket !== -1) {
          state.TicketTable = state.TicketTable.map((ticket, i) =>
            i === updatedSupportTicket ? updatedTicket : ticket
          );
        } else {
          state.TicketTable.push(updatedTicket);
        }
      })

      .addCase(updateTicketStatus.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateTicketType.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(updateTicketType.fulfilled, (state, action) => {
        const updatedTicket = action?.payload?.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedSupportTicket = state.TicketTable.findIndex(
          (ticket) => ticket.ticketId === updatedTicket.ticketId
        );
        if (updatedSupportTicket !== -1) {
          state.TicketTable = state.TicketTable.map((ticket, i) =>
            i === updatedSupportTicket ? updatedTicket : ticket
          );
        } else {
          state.TicketTable.push(updatedTicket);
        }
      })

      .addCase(updateTicketType.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(updateTicketPrivatePublicToggle.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(updateTicketPrivatePublicToggle.fulfilled, (state, action) => {
        const updatedTicket = action?.payload?.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedSupportTicket = state.TicketTable.findIndex(
          (ticket) => ticket.ticketId === updatedTicket.ticketId
        );
        if (updatedSupportTicket !== -1) {
          state.TicketTable = state.TicketTable.map((ticket, i) =>
            i === updatedSupportTicket ? updatedTicket : ticket
          );
        } else {
          state.TicketTable.push(updatedTicket);
        }
      })

      .addCase(updateTicketPrivatePublicToggle.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(updateTicketAttachments.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(updateTicketAttachments.fulfilled, (state, action) => {
        const updatedTicket = action?.payload?.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedSupportTicket = state.TicketTable.findIndex(
          (ticket) => ticket.ticketId === updatedTicket.ticketId
        );
        if (updatedSupportTicket !== -1) {
          state.TicketTable = state.TicketTable.map((ticket, i) =>
            i === updatedSupportTicket ? updatedTicket : ticket
          );
        } else {
          state.TicketTable.push(updatedTicket);
        }
      })

      .addCase(updateTicketAttachments.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(createTicket.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.TicketTable = [...state.TicketTable, action?.payload.data];
        state.error = "";
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(createComment.pending, (state) => {
        // state.isFetchingRequest = true;
      })
      .addCase(createComment.fulfilled, (state, action) => {
        state.isFetchingRequest = false;
        state.successMessage = action?.payload.message;
        if (!state.commentData) {
          state.commentData = {
            allComments: [],
            rules: [],
            assets: [],
            vendors: [],
            services: [],
          };
        } else if (!state.commentData.allComments) {
          state.commentData.allComments = [];
        }

        state.commentData.allComments.unshift(action?.payload.data);
        state.error = "";
      })
      .addCase(createComment.rejected, (state, action) => {
        state.isFetchingRequest = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getCommentById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCommentById.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.commentDataByCommentId = action?.payload?.data;
      })
      .addCase(getCommentById.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getTicket.pending, (state) => {
        // state.isFetchingRequest = true;
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.isFetchingRequest = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        console.log("action?.payload?.data", action?.payload?.data);
        state.commentData = action?.payload?.data;
      })
      .addCase(getTicket.rejected, (state, action) => {
        state.isFetchingRequest = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getAllAssetByAssociationId.pending, (state) => {
        // state.isFetchingRequest = true;
      })
      .addCase(getAllAssetByAssociationId.fulfilled, (state, action) => {
        state.isFetchingRequest = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.allAssets = action?.payload?.data;
      })
      .addCase(getAllAssetByAssociationId.rejected, (state, action) => {
        state.isFetchingRequest = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(addAttachmentTicket.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(addAttachmentTicket.fulfilled, (state, action) => {
        state.AddAttachmentTicketResponse = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(addAttachmentTicket.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateTicketTextFields.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(updateTicketTextFields.fulfilled, (state, action) => {
        const updatedTicket = action?.payload?.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedSupportTicket = state.TicketTable.findIndex(
          (ticket) => ticket.ticketId === updatedTicket.ticketId
        );
        if (updatedSupportTicket !== -1) {
          state.TicketTable = state.TicketTable.map((ticket, i) =>
            i === updatedSupportTicket ? updatedTicket : ticket
          );
        } else {
          state.TicketTable.push(updatedTicket);
        }
      })

      .addCase(updateTicketTextFields.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateTicket.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        if (action?.payload?.data?.assets) {
          state.commentData.assets = action?.payload?.data?.assets;
        }
        if (action?.payload?.data.vendors) {
          state.commentData.vendors = action?.payload?.data?.vendors;
        }
        if (action?.payload?.data.rules) {
          state.commentData.rules = action?.payload?.data?.rules;
        }
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export const { resetTicketDrawerState } = supportTicketSlice.actions;

export default supportTicketSlice;
