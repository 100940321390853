import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  useTheme,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "../AssociationModals/Vendors/vendorModal.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  isOpen: boolean;
  loading?:boolean;
  onClose: () => void;
  onConfirmation: () => void;
  confirmation: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  loading,
  onClose,
  onConfirmation,
  confirmation,
  
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isFetching = useSelector((state: any) => state?.Manage?.isFetching);
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="removeVendorWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon
            className="close-icon"
            sx={{ color: theme.palette.mode === "light" ? "#b7b7b7" : "#fff" }}
          />
        </Box>
        <Typography
          id="remove-member-modal-description"
          sx={{
            mt: 2,
            marginBottom: "30px",
          }}
        >
          {t(confirmation)}
        </Typography>
        <div className="SubmitBtn ticketModel__footer">
          <Button
            className="removeVendorButtonYes"
            onClick={onConfirmation}
            type="submit"
            variant="outlined"
          >
            {loading || isFetching ? (
              <CircularProgress
                size={33}
                sx={{ color: `${theme.palette.activeColor}` }}
              />
            ) : (
              t("Yes")
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            className="removeVendorButtonCancel"
          >
            {t("Cancel")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
