import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
  Autocomplete,
  Paper,
  TextField,
  Popper,
  styled,
  FormLabel,
  Link,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../Redux/Store";
import { createOrganization } from "../../../Redux/Feature/Setting-slice";
import { vendorTypes } from "../../../Utils/assets";

import SpinnerLoader from "../../Common/SpinnerLoader/SpinnerLoader";
import { COUNTRY_CODES } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%",
    marginLeft: "-18px",
    marginTop: "1.3vw",
  },
}));
interface CreateOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  showToast: (message: any, toastType: string) => void;
}

const CreateOrganizationModal: React.FC<CreateOrganizationModalProps> = ({
  isOpen,
  onClose,
  showToast,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [serviceTypes, setServiceTypes] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Organization Title is required")),
  });
  const isFetching = useSelector(
    (state: RootState) => state?.Setting?.isFetching
  );

  const autocompleteChipStyle = {
    marginTop: "10px",
    "& .MuiAutocomplete-endAdornment": {
      right: "-10px !important",
    },
    "& input": {
      padding: "0 !important",
      color: "var(--mui-palette-text-primary)",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    borderRadius: "10px",
    border: "var(--mui-palette-inputBorderColor)",
    background: "var(--mui-palette-inputBackground)",
    padding: "12px 20px",
    "& .MuiAutocomplete-tag": {
      background: theme.palette.background.default,
      border: " var(--mui-palette-inputBorderColor)",
      "& svg": {
        color: "#676767 !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      background: "#fff !important",
    },
  };

  const autoCompletePaperStyle = {
    width: "100%",
    background: "var(--mui-palette-autoCompleteBackground)",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: "var(--mui-palette-autocompletetextcolor)",
    },
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      file: null as File | null,
      serviceType: [],
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { serviceType, title, country } = values;
      const organizationPayload = {
        title: title,
        services: serviceTypes,
        country: country,
      };
      dispatch(createOrganization(organizationPayload))
        .then((response: any) => {
          resetForm();
          onClose();
          if (response?.payload?.statusCode === 200) {
            showToast(t("Successfully created organization"), "success");
          } else {
            showToast(t("Error"), "error");
          }
        })
        .catch((error: any) => {
          showToast(t("Error"), "error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <SpinnerLoader loading={loading} />
      <Modal open={isOpen} onClose={handleCloseModal} sx={{ zIndex: 100 }}>
        <Box
          className="add-document add-association-model"
          sx={{ width: "500px !important", paddingRight: "40px" }}
        >
          <Box
            sx={{ m: "20px 30px 40px" }}
            justifyContent={"center"}
            display="flex"
          >
            <p
              style={{
                color: `${theme.palette.text.primary}`,
                fontSize: "30px",
                fontWeight: "600",
              }}
            >
              {t("Create Vendor Organization")}
            </p>
            <Link sx={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
              <CancelIcon className="support-ticket-cross-icon" />
            </Link>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <div
              className="e-input-group"
              style={{ marginBottom: "20px", width: "100%" }}
            >
              <label
                style={{
                  color: `${theme.palette.text.primary}`,
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                {t("Organization Name")}
              </label>
              <input
                id="title"
                name="title"
                placeholder={t("Organization Name")}
                style={{ width: "100%" }}
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.title && !!formik.errors.title}
                className="request-support-field"
              />
              {formik.errors.title && formik.touched.title && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.title}
                </Typography>
              )}
            </div>

            <div className="formDropdownField margin-bottom">
              <FormLabel
                sx={{ fontSize: "20px", color: `${theme.palette.textWhite}` }}
              >
                {t("Service Types")}
              </FormLabel>

              <Autocomplete
                PopperComponent={MuiDropDownPopper}
                sx={autocompleteChipStyle}
                multiple
                id="vendorsId1"
                options={vendorTypes}
                getOptionLabel={(option: any) => t(option.value) || ""}
                onChange={(event, newValue) => {
                  const selectedTypes: any = newValue.map(
                    (value: any) => value.key
                  );
                  setServiceTypes(selectedTypes);
                }}
                value={vendorTypes?.filter((option: any) =>
                  serviceTypes.includes(option?.key)
                )}
                filterSelectedOptions
                PaperComponent={(props) => (
                  <Paper sx={autoCompletePaperStyle} {...props} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Vendors"
                    // fullWidth
                  />
                )}
              />
            </div>

            <Typography
              color={`${theme.palette.textWhite}`}
              sx={{ marginBottom: "10px", marginTop: "16px" }}
            >
              {t("Select Country")}
            </Typography>

            <Box sx={{ marginBottom: "16px" }}>
              <Autocomplete
                options={COUNTRY_CODES}
                sx={{
                  "& .MuiInputBase-root": {
                    border: "var(--mui-palette-inputBorderColor)",
                  },
                }}
                getOptionLabel={(option: any) => option?.value}
                defaultValue={selectedCountry}
                getOptionKey={(option: any) => option?.key}
                filterSelectedOptions
                PopperComponent={MuiDropDownPopper}
                id="selectCountry"
                className="country-select"
                onChange={(event, newValue: any) => {
                  setSelectedCountry(newValue);
                  formik.setFieldValue(
                    "country",
                    newValue ? newValue?.key : ""
                  );
                }}
                value={selectedCountry || null}
                onBlur={formik.handleBlur}
                PaperComponent={(props) => (
                  <Paper sx={autoCompletePaperStyle} {...props} />
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t("Select Country")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: `${theme.palette.modelInputBorderColor}`, // default border color
                        },
                        "&:hover fieldset": {
                          borderColor: `${theme.palette.modelInputBorderColor}`, // border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: `${theme.palette.modelInputBorderColor} !important`, // border color when focused
                        },
                      },
                    }}
                  />
                )}
              />
              {formik.errors.country && formik.touched.country && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.country}
                </Typography>
              )}
            </Box>

            <Box sx={{ marginTop: "30px" }}>
              <div className="SubmitBtn ticketModel__footer">
                <Button
                  className="sBtn e-control e-btn"
                  id="targetButton1"
                  type="submit"
                  disabled={isFetching === true}
                >
                  {loading ? (
                    <CircularProgress
                      size={23}
                      sx={{ color: `${theme.palette.textWhite}` }}
                    />
                  ) : (
                    t("Submit")
                  )}
                </Button>
              </div>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateOrganizationModal;
