import "./headerModal.scss";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

const AssetsAutoComplete = (props: any) => {
  const {
    mergedAssets,
    setSelectedAsset,
    setTicketData,
    selectedAsset,
    autoCompletePaperStyle,
    MuiDropDownPopper,
    autocompleteChipStyle,
  } = props;
  const { t } = useTranslation();

  const getAssetLabel = (option: any) => {
    switch (option?.type) {
      case "common":
        return `${option?.title} (Asset Category)`;
      case "component":
        return `${option?.title} (Common Asset)`;
      case "building":
        return `${option?.title} (Building)`;
      case "unit":
        return `${option?.title} (Unit)`;
      default:
        return `${option?.title} (${option?.type})`;
    }
  };

  return (
    <Autocomplete
      disablePortal
      PopperComponent={MuiDropDownPopper}
      sx={autocompleteChipStyle}
      multiple
      id="assetsId1"
      options={mergedAssets}
      getOptionKey={(option) => option.assetId}
      getOptionLabel={getAssetLabel}
      onChange={(event, newValue) => {
        const selectedIds = newValue.map((asset: any) => asset.assetId);
        setSelectedAsset(selectedIds);
        setTicketData((prevState: any) => ({
          ...prevState,
          assets: selectedIds,
        }));
      }}
      value={mergedAssets?.filter((asset: any) =>
        selectedAsset.includes(asset.assetId)
      )}
      filterSelectedOptions
      filterOptions={(options, state) => {
        // Perform exact match or prefix match
        return options.filter((option) =>
          option?.title
            ?.toLowerCase()
            .startsWith(state.inputValue.toLowerCase())
        );
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={t("Select Assets")} />
      )}
      PaperComponent={(props) => (
        <Paper sx={autoCompletePaperStyle} {...props} />
      )}
    />
  );
};

export default AssetsAutoComplete;
