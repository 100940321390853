import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  AppBar,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createCommonAssetAPI,
  deleteCommonAsset,
  getAllCommonAssets,
  getAssetComponents,
  updateCommonAssetAPI,
} from "../../../Redux/Feature/Asset-slice";
import Toast from "../../../Component/Common/Toast/Toast";
import { AppDispatch, RootState } from "../../../Redux/Store";
import { Clear, Search } from "@mui/icons-material";
import DeleteCommonAssetModal from "../../../Component/Modals/AssetModals/CommonAssetsModals/DeleteCommonAssetModal";
import DeleteIcon from "@mui/icons-material/Delete";

import CommonAssetModal from "../../../Component/Modals/AssetModals/CommonAssetsModals/CommonAssetModal";
import CommonAssetDirectorySkeleton from "../../../Component/Skeletons/CommonAssetDirectorySkeleton";
import { assetCategories } from "../../../Utils/assets";
import {
  getAssociation,
  updateCommonCount,
} from "../../../Redux/Feature/Auth-slice";
import { useTranslation } from "react-i18next";
import { ERROR_MESSAGES } from "../../../Utils/utils";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AssetComponentList from "./AssetComponentList";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const assetCategoryMap = assetCategories.reduce((map: any, category: any) => {
  map[category.key] = category.value;
  return map;
}, {});

const CommonAssetsDirectory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const {
    commonAssetDirectory,
    isFetching,
    totalAnnualFunding,
    totalOnHandValue,
    totalPercentFunded,
    totalPresentValue,
    totalTotalCost,
  } = useSelector((state: RootState) => state.Asset);

  const [tab, setTab] = useState(1);

  const [isCreateCommonAssetModalOpen, setIsCreateCommonAssetModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [showDeleteCommonAssetModal, setShowDeleteCommonAssetModal] =
    useState<boolean>(false);
  const [selectedCommonAsset, setSelectedCommonAsset] = useState<any | null>(
    null
  );

  const [rowSelect, setRowSelected] = useState<any>([]);
  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";
  const toggleDrawer = (value: boolean) => {
    setRightDrawer(value);
  };

  const openCreateCommonAssetComponent = () => {
    setIsCreateCommonAssetModalOpen(true);
  };

  const closeCreateCommonAssetComponent = async () => {
    setIsCreateCommonAssetModalOpen(false);
  };

  useEffect(() => {
    fetchCommonAssets();
  }, [currentAssociation?.associationId]);

  const fetchAssetComponents = async (assetId: string) => {
    // setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getAssetComponents({
            commonAssetId: assetId,
            associationId: currentAssociation?.associationId,
          })
        );
        if (response?.payload?.statusCode === 200) {
          // setCommonAssets(commonAssetDirectory);
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching all common assets:", error);
    } finally {
      // setLoading(false);
    }
  };

  const fetchCommonAssets = async () => {
    setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getAllCommonAssets({
            associationId: currentAssociation?.associationId,
          })
        );
        if (response?.payload?.statusCode === 200) {
          // setCommonAssets(commonAssetDirectory);
        } else if (response.error) {
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching all common assets:", error);
    } finally {
      setLoading(false);
    }
  };

  const createCommonAssetHandler = async (createAssetPayload: any) => {
    try {
      // setLoading(true);
      const response: any = await dispatch(
        createCommonAssetAPI(createAssetPayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Asset Created"), "success");
        await dispatch(getAssociation(currentAssociation.associationId));
        const newCommonCount = currentAssociation.commonCount + 1;
        dispatch(updateCommonCount(newCommonCount));
      } else if (response.error) {
        showToast(t("Error"), "error");
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      // setLoading(false);
    }
  };
  const handleOpenRemoveCommonAssetModal = (asset: any): void => {
    setSelectedCommonAsset(asset);
    setShowDeleteCommonAssetModal(true);
  };

  const handleRemoveCommonAsset = async () => {
    try {
      if (selectedCommonAsset) {
        // setLoading(true);
        const response: any = await dispatch(
          deleteCommonAsset(selectedCommonAsset?.assetId)
        );
        setShowDeleteCommonAssetModal(false);
        if (response?.payload?.statusCode === 200) {
          showToast(t("Asset Deleted"), "success");
          await dispatch(getAssociation(currentAssociation.associationId));
        } else {
          showToast(t("Error"), "error");
        }
      }
    } catch (err) {
    } finally {
      // setLoading(false);
    }
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  const assetsColumn = [
    {
      id: "title",
      label: t("Title"),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "assetCategory",
      label: t("Asset Category"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      id: "quantity",
      label: t("Quantity"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      id: "remainingLife",
      label: t("Remaining Life"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      id: "totalCost",
      label: t("Total Cost"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      id: "annualFunding",
      label: t("Annual Funding Required"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      id: "percentFunded",
      label: t("Percent Funded"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },

    {
      id: "Action",
      label: t("Action"),
      width: "20px",
      minWidth: "20px",
      maxWidth: "20px",
    },
  ];

  const filteredAssetColumns = isBoardRole
    ? assetsColumn
    : assetsColumn?.filter(
        (column) =>
          column.id !== "Action" &&
          column.id !== "remainingLife" &&
          column.id !== "totalCost" &&
          column.id !== "percentFunded" &&
          column.id !== "annualFunding"
      );

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const getColorForPercentFunded = (percent: any) => {
    if (percent <= 33) return theme.palette.error.main; // Red for poor health
    if (percent <= 66) return theme.palette.warning.main; // Yellow for fair health
    if (percent <= 100) return theme.palette.success.main; // Green for good health
    return theme.palette.info.main; // Blue for excellent health
  };
  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedAssets = stableSort(
    commonAssetDirectory,
    getComparator(order, orderBy)
  );

  const filteredAssets = sortedAssets?.filter((asset: any) => {
    if (!asset) {
      return false;
    }
    const { title, establishedDate, priority, assetCategory } = asset;
    const lowerCaseSearch = search.toLowerCase();
    return (
      title?.toLowerCase().includes(lowerCaseSearch) ||
      assetCategory?.toString().toLowerCase().includes(lowerCaseSearch) ||
      priority?.toString().toLowerCase().includes(lowerCaseSearch) ||
      // usefulLife.toString().toLowerCase().includes(lowerCaseSearch)||
      establishedDate?.includes(lowerCaseSearch)
    );
  });

  const paginatedData = filteredAssets?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClearSearch = () => {
    setSearch("");
  };

  const handleRowClick = (params: any) => {
    const isButtonClicked = (window.event?.target as Element)?.closest(
      ".remove-common-asset"
    );
    if (!isButtonClicked) {
      const selectedRecords: any = [params];
      setRowSelected(selectedRecords);
      toggleDrawer(true);
      fetchAssetComponents(selectedRecords[0]?.assetId);
    }
  };

  // if (isFetching) {
  //   return <CommonAssetDirectorySkeleton />;
  // }
  const handleDirectoryClick = () => {
    setRowSelected([]);
  };

  return (
    <>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <DeleteCommonAssetModal
        isOpen={showDeleteCommonAssetModal}
        onClose={() => setShowDeleteCommonAssetModal(false)}
        onDelete={handleRemoveCommonAsset}
        loading={loading}
      />
      <Box>
        {rowSelect?.length > 0 ? (
          <>
            <AssetComponentList
              commonAsset={rowSelect[0]}
              setRowSelected={setRowSelected}
              handleDirectoryClick={handleDirectoryClick}
            ></AssetComponentList>
          </>
        ) : isFetching ? (
          <CommonAssetDirectorySkeleton />
        ) : (
          <Box
            className="ccr document document-wrapper"
            sx={{ paddingTop: "0 !important" }}
          >
            <Box className="rule__title">
              <Typography className="rule__title__heading page_title">
                {t("Common Assets Directory")}
              </Typography>
            </Box>
            <Box
              className="TableConnect document-wrapper  new-support-table"
              sx={{
                border: "unset !important",
              }}
            >
              <Box
                sx={{
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <OutlinedInput
                  sx={{
                    margin: "0 !important",
                    border: "unset !important",
                    borderRadius: "10px",
                    padding: "5px 10px",
                    "& .MuiInputBase-input": {
                      padding: "6px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#20c1f5",
                    },
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#20c1f5",
                      },
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#20c1f5",
                        borderWidth: "1px",
                      },
                    },
                  }}
                  placeholder={t("Search")}
                  value={search}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      {search ? (
                        <IconButton onClick={handleClearSearch} edge="end">
                          <Clear style={{ color: "#20c1f5" }} />
                        </IconButton>
                      ) : (
                        <IconButton edge="end">
                          <Search style={{ color: "#20c1f5" }} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
                {isBoardRole && (
                  <Box sx={{ textAlign: "end", marginBottom: "12px" }}>
                    <Button
                      className="btn add-rule btn-white"
                      variant="outlined"
                      onClick={openCreateCommonAssetComponent}
                    >
                      {t("Add Common Asset")}
                    </Button>
                  </Box>
                )}
              </Box>
              <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={9}>
                  <Box className="articlePart">
                    <CommonAssetModal
                      loading={loading}
                      isOpen={isCreateCommonAssetModalOpen}
                      onClose={closeCreateCommonAssetComponent}
                      createAssetHandler={createCommonAssetHandler}
                    />
                  </Box>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        color: `${theme.palette.text.primary}`,
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <span>{t("Total On Hand Value")}:</span>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          <span>{formatter.format(totalOnHandValue)}</span>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography
                      sx={{
                        color: `${theme.palette.text.primary}`,
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <span>{t("Total Present Value")}:</span>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          <span>{formatter.format(totalPresentValue)}</span>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography
                      sx={{
                        color: `${theme.palette.text.primary}`,
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <span>{t("Total Percent Funded")}:</span>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          <span
                            style={{
                              color:
                                getColorForPercentFunded(totalPercentFunded),
                            }}
                          >
                            {totalPercentFunded}%
                          </span>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        color: `${theme.palette.text.primary}`,
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <span>{t("Total Annual Funding")}:</span>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          <span>{formatter.format(totalAnnualFunding)}</span>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography
                      sx={{
                        color: `${theme.palette.text.primary}`,
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <span>{t("Total Total Cost")}:</span>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          <span>{formatter.format(totalTotalCost)}</span>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Box className="TableConnectInner">
                    <Box className="document-wrap gridTable">
                      <Paper
                        sx={{
                          width: "100%",
                          mb: 2,
                          border: "1px solid #20c1f5",
                        }}
                      >
                        <Box>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead sx={{ background: "black" }}>
                                <TableRow>
                                  {filteredAssetColumns?.map(
                                    (column, index) => (
                                      <TableCell
                                        sx={{ padding: "16px 8px" }}
                                        key={column.id}
                                        style={{
                                          width: column.minWidth,
                                          maxWidth: column.maxWidth,
                                          minWidth: column.minWidth,
                                        }}
                                        sortDirection={
                                          orderBy === column.id ? order : false
                                        }
                                      >
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : "asc"
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {paginatedData?.length === 0 ? (
                                  <TableRow>
                                    {isBoardRole ? (
                                      <TableCell colSpan={8} align="center">
                                        {t("No Components Found")}
                                      </TableCell>
                                    ) : (
                                      <TableCell colSpan={3} align="center">
                                        {t("No Components Found")}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ) : (
                                  paginatedData.map((row: any) => (
                                    <TableRow
                                      onClick={() => handleRowClick(row)}
                                      key={row.id}
                                      sx={{
                                        "&. MuiTableCell-root": {
                                          height: "70px",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row.title}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {t(assetCategoryMap[row.assetCategory])}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.quantity} {row?.measurement}
                                      </TableCell>
                                      {isBoardRole && (
                                        <>
                                          <TableCell
                                            sx={{
                                              height: "60px",
                                              padding: "4px 8px",
                                            }}
                                          >
                                            {row?.remainingLife} {t("Years")}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              height: "60px",
                                              padding: "4px 8px",
                                            }}
                                          >
                                            {row?.totalCost !== null &&
                                            row?.totalCost !== undefined &&
                                            !isNaN(row?.totalCost)
                                              ? formatter.format(row?.totalCost)
                                              : formatter.format(0)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              height: "60px",
                                              padding: "4px 8px",
                                            }}
                                          >
                                            {row?.annualFunding !== null &&
                                            row?.annualFunding !== undefined &&
                                            !isNaN(row?.annualFunding)
                                              ? formatter.format(
                                                  row?.annualFunding
                                                )
                                              : formatter.format(0)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              height: "60px",
                                              padding: "4px 8px",
                                            }}
                                          >
                                            {row?.percentFunded} %
                                          </TableCell>
                                          {/* <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.presentValue !== null &&
                                        row?.presentValue !== undefined &&
                                        !isNaN(row?.presentValue)
                                          ? formatter.format(row?.presentValue)
                                          : formatter.format(0)}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row?.onHandValue !== null &&
                                        row?.onHandValue !== undefined &&
                                        !isNaN(row?.onHandValue)
                                          ? formatter.format(row?.onHandValue)
                                          : formatter.format(0)}
                                      </TableCell> */}
                                          <TableCell
                                            sx={{
                                              height: "60px",
                                              padding: "4px 8px",
                                            }}
                                          >
                                            <Button
                                              sx={{
                                                color: `${theme.palette.text.primary}`,
                                              }}
                                              onClick={() =>
                                                handleOpenRemoveCommonAssetModal(
                                                  row
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            sx={{ background: "black" }}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={sortedAssets?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("Rows per page")}
                          />
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default CommonAssetsDirectory;
