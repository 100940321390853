import { useState, useEffect } from "react";

import {
  Box,
  Button,
  Typography,
  useTheme,
  Grid,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableBody,
  Table,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import "./votes.scss";
import { useDispatch, useSelector } from "react-redux";
import "../Settings/setting.scss";
import { useTranslation } from "react-i18next";
import { RootState } from "../../Redux/Store";
import Toast from "../../Component/Common/Toast/Toast";
import VendorSkelton from "../../Component/Skeletons/VendorSkeleton";
import { Clear, Rule, Search } from "@mui/icons-material";
import {
  getVotes,
  resetDrawer,
  updateVote,
} from "../../Redux/Feature/Governance-slice";
import AddVoteModal from "../../Component/Modals/GovernanceModals/Vote/AddVoteModal";
import VoteDrawer from "../../Component/Drawers/VoteDrawer";
import CompletedDrawer from "../../Component/Drawers/CompletedDrawer";
import { getOwners } from "../../Redux/Feature/Roster-slice";
import { ERROR_MESSAGES } from "../../Utils/utils";

const Votes = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState<string>("");
  const { t } = useTranslation();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const [completedDrawer, setCompletedDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] =
    useState(false);
  const [selectedVoteId, setSelectedVoteId] = useState<string | null>(null);

  const voteStatusOptions = [
    { value: "open", text: t("Open") },
    { value: "closed", text: t("Closed") },
    { value: "all", text: t("All") },
  ];
  const [isVoteModalOpen, setAddVoteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({
    attachments: [],
    voteId: "",

    title: "",
    rules: "",
    type: "",
    votingGroup: "",
    startDate: "",
    endDate: "",
  });

  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const [selectedStatusOption, setSelectedStatusOption] =
    useState<string>("open");
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";

  const votesColumns = [
    {
      id: "title",
      label: t("Title"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "type",
      label: t("Type"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "votingGroup",
      label: t("Voting Group"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "startDate",
      label: t("Start Date"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "endDate",
      label: t("End Date"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
  ];

  const AllVotes = useSelector(
    (state: RootState) => state?.Governance?.allVotes
  );

  const owners = useSelector((state: RootState) => state?.Manage?.owners);
  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  useEffect(() => {
    fetchAllVotes();
  }, [currentAssociation?.associationId]);

  const showToast = (message: any, type: "success" | "error") => {
    setToastMessage(message);
    setToastType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const openAddAttachmentModal = (voteId: any) => {
    setIsAddAttachmentModalOpen(true);
    setSelectedVoteId(voteId);
  };

  const handleClick = () => {
    openAddAttachmentModal(selectedRow?.voteId);
  };

  const toggleCompletedDrawer = async (value: boolean) => {
    setCompletedDrawer(value);
    dispatch(resetDrawer());
  };
  const toggleDrawer = async (value: boolean) => {
    setRightDrawer(value);

    dispatch(resetDrawer());
  };
  const handleAddVoteModalOpen = () => {
    setAddVoteModalOpen(true);
  };

  const handleAddVoteModalClose = () => {
    setAddVoteModalOpen(false);
  };

  const fetchAllVotes = async () => {
    setLoading(true);
    if (currentAssociation) {
      await dispatch(getVotes(currentAssociation?.associationId));

      await dispatch(getOwners(currentAssociation?.associationId));
      setIsInitialLoad(false);
    }
    setLoading(false);
  };

  const handleRowClick = async (params: any) => {
    const isInputClicked =
      (window.event?.target as Element)?.tagName.toLowerCase() === "input";
    const isIconClicked = (window.event?.target as Element)?.closest(".icon");
    const isEditIconClicked = (window.event?.target as Element)?.closest(
      ".support-edit-icon"
    );
    if (!isInputClicked && !isIconClicked && !isEditIconClicked) {
      const selectedRecords: any = [params];
      setSelectedRow(selectedRecords[0]);

      if (selectedRecords[0]) {
        if (
          selectedRecords[0].endDate === null ||
          selectedRecords[0].startDate === null
        ) {
          toggleDrawer(true);
          return;
        }
        const endDate = new Date(selectedRecords[0]?.endDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates

        if (endDate < today) {
          // End date has passed
          toggleCompletedDrawer(true);
        } else if (endDate.getTime() === today.getTime()) {
          // End date is today
          showToast(
            "Vote is ending today, check back tomorrow for results",
            "success"
          );
        } else {
          if (isBoardRole) {
            toggleDrawer(true);
          }
        }
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleClearSearch = () => {
    setSearch("");
  };
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const sortedVotes = stableSort(AllVotes, getComparator(order, orderBy));

  const filteredVotes = sortedVotes?.filter((vote: any) => {
    if (!vote) {
      return false;
    }
    const { title, status, type } = vote;
    const lowerCaseSearch = search?.toLowerCase();
    return lowerCaseSearch
      ? title?.toLowerCase().includes(lowerCaseSearch) ||
          type?.toLowerCase().includes(lowerCaseSearch) ||
          status?.toLowerCase().includes(lowerCaseSearch)
      : true;
  });

  const paginatedData = filteredVotes?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  const closeAddVoteAttachmentModal = async (
    uploadedAttachments: string[],
    selectedVote: any
  ) => {
    try {
      setIsAddAttachmentModalOpen(false);
      console.log("uploadedAttachments", uploadedAttachments);
      if (uploadedAttachments?.length > 0) {
        let updatedAttachments: any = [];
        if (selectedVote?.attachments && selectedVote?.attachments.length > 0) {
          updatedAttachments = [...selectedVote?.attachments];
        }
        const transformedAttachments = updatedAttachments?.map(
          (attachment: any) => ({
            s3Key: attachment.key,
          })
        );
        console.log("transformedAttachments", transformedAttachments);
        const attachments = uploadedAttachments?.map((key) => ({ s3Key: key }));
        const mergedAttachments = transformedAttachments.concat(attachments);
        console.log("mergedAttachments", mergedAttachments);
        const updatedRow = {
          ...selectedVote,
          attachments: mergedAttachments,
        };

        const obj = {
          voteId: selectedVoteId,
          payload: updatedRow,
        };
        setSelectedRow(updatedRow);
        setSelectedVoteId(null);
        const response = await dispatch(updateVote(obj));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Vote Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
        // await dispatch(getTicket({ id: rowSelect[0]?.ticketId }));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  if (loading) {
    return <VendorSkelton />;
  }

  const isAuthorizedRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager" ||
    currentAssociation?.role === "general";
  return (
    <div className="profile">
      {isAuthorizedRole ? (
        <div>
          <Box className="votesBox__title">
            <Typography className="votes__title page_title">
              {t("Votes")}
            </Typography>
          </Box>
          <Toast
            type={toastType as "success" | "error"}
            open={openToast}
            message={`${toastMessage}`}
            handleClose={handleCloseToast}
          />
          <AddVoteModal
            isOpen={isVoteModalOpen}
            onClose={handleAddVoteModalClose}
            associationId={currentAssociation?.associationId}
            setIsInitialLoad={setIsInitialLoad}
            owners={owners}
          />
          <Box
            className="TableConnect votes_wrapper votes-member-table new-support-table"
            sx={{
              border: "unset !important",
            }}
          >
            <Box className="TableConnectInner">
              <Box className="votes-wrap gridTable">
                <Box
                  sx={{
                    marginBottom: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <OutlinedInput
                        sx={{
                          margin: "0 !important",
                          border: "unset !important",
                          "& .MuiInputBase-input": {
                            padding: "6px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${theme.palette.activeColor}`,
                          },
                          "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: `${theme.palette.activeColor}`,
                            },
                          },
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: `${theme.palette.activeColor}`,
                              borderWidth: "1px",
                            },
                          },
                        }}
                        placeholder={t("Search")}
                        value={search}
                        onChange={handleSearchChange}
                        endAdornment={
                          <InputAdornment position="end">
                            {search ? (
                              <IconButton
                                onClick={handleClearSearch}
                                edge="end"
                                className="search-icon-button-hover"
                              >
                                <Clear
                                  style={{
                                    color: `${theme.palette.activeColor}`,
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                className="search-icon-button-hover"
                              >
                                <Search
                                  style={{
                                    color: `${theme.palette.activeColor}`,
                                  }}
                                />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      {isBoardRole && (
                        <Button
                          className="btn add-member btn-white"
                          variant="outlined"
                          onClick={handleAddVoteModalOpen}
                        >
                          {t("Create Vote")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Paper
                  sx={{
                    width: "100%",
                    mb: 2,
                    border: `1px solid ${theme.palette.activeColor}`,
                  }}
                >
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {votesColumns?.map((column, index) => (
                              <TableCell
                                sx={{ padding: "16px 8px" }}
                                key={column.id}
                                style={{
                                  width: column.minWidth,
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                }}
                                sortDirection={
                                  orderBy === column.id ? order : false
                                }
                              >
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={
                                    orderBy === column.id ? order : "asc"
                                  }
                                  onClick={() => handleSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginatedData?.length === 0 ? (
                            <TableRow key={"noVoteRowId"}>
                              <TableCell colSpan={5} align="center">
                                {t("No Votes Found")}
                              </TableCell>
                            </TableRow>
                          ) : (
                            paginatedData?.map((row: any) => (
                              <TableRow
                                key={row.id}
                                onClick={() => handleRowClick(row)}
                                sx={{
                                  "&. MuiTableCell-root": {
                                    height: "70px",
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{ height: "60px", padding: "4px 8px" }}
                                >
                                  {row?.title}
                                </TableCell>
                                <TableCell
                                  sx={{ height: "60px", padding: "4px 8px" }}
                                >
                                  {t(row?.type)}
                                </TableCell>
                                <TableCell
                                  sx={{ height: "60px", padding: "4px 8px" }}
                                >
                                  {row?.votingGroup === "board"
                                    ? t("Board")
                                    : t("General")}
                                </TableCell>

                                <TableCell
                                  sx={{ height: "60px", padding: "4px 8px" }}
                                >
                                  {row?.startDate
                                    ? new Date(
                                        row?.startDate
                                      ).toLocaleDateString()
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{ height: "60px", padding: "4px 8px" }}
                                >
                                  {row?.endDate
                                    ? new Date(
                                        row?.endDate
                                      ).toLocaleDateString()
                                    : "-"}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      component="div"
                      count={filteredVotes.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("Rows per page")}
                    />
                  </Box>
                </Paper>
              </Box>
              <CompletedDrawer
                selectedVoteId={selectedRow?.voteId}
                completedDrawer={completedDrawer}
                toggleCompletedDrawer={toggleCompletedDrawer}
                showToast={showToast}
                owners={owners}
                handleClick={handleClick}
                isAddAttachmentModalOpen={isAddAttachmentModalOpen}
                closeAddVoteAttachmentModal={closeAddVoteAttachmentModal}
              />
              <VoteDrawer
                selectedVoteId={selectedRow?.voteId}
                rightDrawer={rightDrawer}
                toggleDrawer={toggleDrawer}
                showToast={showToast}
                owners={owners}
                handleClick={handleClick}
                isAddAttachmentModalOpen={isAddAttachmentModalOpen}
                closeAddVoteAttachmentModal={closeAddVoteAttachmentModal}
              />
            </Box>
          </Box>
        </div>
      ) : (
        <p>You do not have permission to view this page.</p>
      )}
    </div>
  );
};
export default Votes;
