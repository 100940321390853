import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Finance from "../Service/Finance-service";

export const StripeAssociationConnectSession: any = createAsyncThunk(
  "StripeAssociationConnectSession/post",
  async (associationId, thunkAPI) => {
    const data = await Finance.StripeAssociationConnectSession(associationId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const StripeVendorConnectSession: any = createAsyncThunk(
  "StripeVendorConnectSession/post",
  async (obj: any, thunkAPI) => {
    const data = await Finance.StripeVendorConnectSession(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const StripeFinancialConnectionsSession: any = createAsyncThunk(
  "StripeFinancialConnectionsSession/post",
  async (associationId, thunkAPI) => {
    const data = await Finance.StripeFinancialConnectionsSession(associationId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const GetFinancialAccounts: any = createAsyncThunk(
  "GetFinancialAccounts/get",
  async (CurrentAssociationId, thunkAPI) => {
    const data = await Finance.GetFinancialAccounts(CurrentAssociationId);
    if (data.statusCode === 200) {
      return data.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const GetTransactions: any = createAsyncThunk(
  "GetTransactions/get",
  async (CurrentAssociationId, thunkAPI) => {
    const data = await Finance.GetTransactions(CurrentAssociationId);
    if (data.statusCode === 200) {
      return data.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdateTransaction: any = createAsyncThunk(
  "UpdateTransaction/put",
  async (editDataObj, thunkAPI) => {
    const data = await Finance.UpdateTransaction(editDataObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAllBudgets: any = createAsyncThunk(
  "getAllBudgets/post",
  async (budgetObj, thunkAPI) => {
    const data = await Finance.getAllBudgets(budgetObj);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getBudgetsByDate: any = createAsyncThunk(
  "getBudgetsByDate/post",
  async (budgetObj, thunkAPI) => {
    const data = await Finance.getBudgetsByDate(budgetObj);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const AddBudget: any = createAsyncThunk(
  "AddBudget/post",
  async (newBudgetObj, thunkAPI) => {
    try {
      const data = await Finance.AddBudget(newBudgetObj);
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      console.error("Error in AddBudget:", error);
      throw error;
    }
  }
);

export const DeleteBudget: any = createAsyncThunk(
  "DeleteBudget/delete",
  async (budgetId, thunkAPI) => {
    const data = await Finance.DeleteBudget(budgetId);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdateBudget: any = createAsyncThunk(
  "UpdateBudget/put",
  async (editDataObj, thunkAPI) => {
    const data = await Finance.UpdateBudget(editDataObj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const associateTransactionWithBudget: any = createAsyncThunk(
  "associateTransactionWithBudget/put",
  async (linkTranObj, thunkAPI) => {
    const data = await Finance.associateTransactionWithBudget(linkTranObj);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const getBudget: any = createAsyncThunk(
  "getBudget/get",
  async (selectedBudgetID, thunkAPI) => {
    const data = await Finance.getBudget(selectedBudgetID);
    if (data.statusCode === 200) {
      // Convert BudgetClass instance to plain object
      const budget = { ...data };
      return budget;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

interface FinanceSliceInterface {
  isFetching: boolean;
  expenses: {
    budgets: any[];
    totalAllocatedAmount: number;
    totalActualAmount: number;
    difference: number;
  };
  incomes: {
    budgets: any[];
    totalAllocatedAmount: number;
    totalActualAmount: number;
    difference: number;
  };
  selectedBudget: any;
  budgetCategories: any[];
  totalDifference: number;
  totalActualAmount: number;
  totalAllocatedAmount: number;
  selectedMonth: number;
  selectedYear: number;
  transactions: any[];
  accountBalances: number;
  error: string;
  successMessage: string;
  isError: boolean;
  isSuccess: boolean;
  updateIsFetching: boolean;
  toggleTransactionLinked: boolean;
  allPaymentMethods: any[];
  lastTransactionsKey: string;
  accountId: string;
  stripeOnboardingStatus: string;
}

const FinanceSlice = createSlice({
  name: "Auth",
  initialState: {
    accountId: "",
    stripeOnboardingStatus: "",
    isFetching: false,
    expenses: {
      budgets: [],
      totalAllocatedAmount: 0,
      totalActualAmount: 0,
      difference: 0,
    },
    incomes: {
      budgets: [],
      totalAllocatedAmount: 0,
      totalActualAmount: 0,
      difference: 0,
    },
    selectedBudget: {},
    totalDifference: 0,
    totalActualAmount: 0,
    totalAllocatedAmount: 0,
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    transactions: [],
    budgetCategories: [],
    accountBalances: 0,
    error: "",
    successMessage: "",
    isError: false,
    isSuccess: false,
    updateIsFetching: false,
    toggleTransactionLinked: false,
    allPaymentMethods: [],
    lastTransactionsKey: "",
  } as FinanceSliceInterface,
  reducers: {
    resetDrawer: (state) => {
      state.selectedBudget = {
        budgetId: "",
        name: "",
        allocatedAmount: 0,
        associationId: "",
        creatorId: "",
        month: 0,
        year: 0,
        asset: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(StripeAssociationConnectSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(StripeAssociationConnectSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(StripeAssociationConnectSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(StripeVendorConnectSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(StripeVendorConnectSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(StripeVendorConnectSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(GetFinancialAccounts.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetFinancialAccounts.fulfilled, (state, action) => {
        state.isFetching = false;
        state.accountBalances = action?.payload;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";
      })
      .addCase(GetFinancialAccounts.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getAllBudgets.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllBudgets.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";
        const data = action?.payload;
        state.budgetCategories = data?.budgets;
      })
      .addCase(getAllBudgets.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getBudgetsByDate.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getBudgetsByDate.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";

        const data = action?.payload;
        state.incomes = data?.income;
        state.expenses = data?.expense;
        state.totalDifference = data?.difference;
        state.totalActualAmount = data?.totalActualAmount;
        state.totalAllocatedAmount = data?.totalAllocatedAmount;
      })
      .addCase(getBudgetsByDate.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(UpdateTransaction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(UpdateTransaction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        const { budgetId } = action.payload.data;

        const matchingBudget = state.budgetCategories.find(
          (budget) => budget.budgetId === budgetId
        );

        if (matchingBudget) {
          const transaction = state.transactions.find(
            (transaction) =>
              transaction.transactionId === action.payload.data.transactionId
          );

          if (transaction) {
            transaction.budget = matchingBudget;
          }
        }
      })

      .addCase(UpdateTransaction.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(AddBudget.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(AddBudget.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        const { data = {} } = action.payload;
        const newBudget: any = {
          budgetId: data?.budgetId,
          name: data?.name,
          allocatedAmount: data?.allocatedAmount,
          actualAmount: 0,
          difference: data?.allocatedAmount,
          isIncome: data?.isIncome,
          associationId: data?.associationId,
          creatorId: data?.creatorId,
          month: parseInt(data?.monthYear?.slice(0, 2), 10),
          year: parseInt(data?.monthYear?.slice(2, 6), 10),
        };

        if (data?.isIncome) {
          const updatedBudgets = [newBudget, ...state.incomes.budgets];
          state.incomes.budgets = updatedBudgets;
          state.incomes.totalAllocatedAmount += data?.allocatedAmount;
          state.incomes.difference += data?.allocatedAmount;
          state.totalAllocatedAmount += data?.allocatedAmount;
          state.totalDifference += data?.allocatedAmount;
        } else {
          const updatedBudgets = [newBudget, ...state.expenses.budgets];
          state.expenses.budgets = updatedBudgets;
          state.expenses.totalAllocatedAmount -= data?.allocatedAmount;
          state.expenses.difference -= data?.allocatedAmount;
          state.totalAllocatedAmount -= data?.allocatedAmount;
          state.totalDifference -= data?.allocatedAmount;
        }
      })

      .addCase(AddBudget.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(DeleteBudget.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(DeleteBudget.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";
        const deletedBudgetId = action.payload.data.budgetId;
        let index;
        const oldBudget =
          state.expenses.budgets.find(
            (budget) => budget.budgetId === deletedBudgetId
          ) ||
          state.incomes.budgets.find(
            (budget) => budget.budgetId === deletedBudgetId
          );

        if (oldBudget) {
          // Calculate the difference in allocatedAmount
          const allocatedAmountDifference = 0 - oldBudget.allocatedAmount;

          // Augment the existing difference by the same amount as the change in allocatedAmount
          oldBudget.difference += allocatedAmountDifference;

          if (oldBudget.isIncome) {
            index = state.incomes.budgets.findIndex(
              (budget) => budget.budgetId === deletedBudgetId
            );
            state.incomes.budgets.splice(index, 1);
            state.incomes.totalAllocatedAmount += allocatedAmountDifference;
            state.incomes.difference += allocatedAmountDifference;
            state.totalAllocatedAmount += allocatedAmountDifference;
            state.totalDifference += allocatedAmountDifference;
          } else {
            index = state.expenses.budgets.findIndex(
              (budget) => budget.budgetId === deletedBudgetId
            );
            state.expenses.budgets.splice(index, 1);
            state.expenses.totalAllocatedAmount -= allocatedAmountDifference;
            state.expenses.difference -= allocatedAmountDifference;
            state.totalAllocatedAmount -= allocatedAmountDifference;
            state.totalDifference -= allocatedAmountDifference;
          }
        }
      })
      .addCase(DeleteBudget.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(UpdateBudget.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(UpdateBudget.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";

        const updatedBudget = action.payload.data;
        let index;

        // Find the old budget
        const oldBudget =
          state.expenses.budgets.find(
            (budget) => budget.budgetId === updatedBudget.budgetId
          ) ||
          state.incomes.budgets.find(
            (budget) => budget.budgetId === updatedBudget.budgetId
          );

        if (oldBudget) {
          // Calculate the difference in allocatedAmount
          const allocatedAmountDifference =
            updatedBudget.allocatedAmount - oldBudget.allocatedAmount;

          // Augment the existing difference by the same amount as the change in allocatedAmount
          oldBudget.difference += allocatedAmountDifference;
          oldBudget.allocatedAmount = updatedBudget.allocatedAmount;

          if (oldBudget.isIncome) {
            state.incomes.totalAllocatedAmount += allocatedAmountDifference;
            state.incomes.difference += allocatedAmountDifference;
            state.totalAllocatedAmount += allocatedAmountDifference;
            state.totalDifference += allocatedAmountDifference;
          } else {
            state.expenses.totalAllocatedAmount -= allocatedAmountDifference;
            state.expenses.difference -= allocatedAmountDifference;
            state.totalAllocatedAmount -= allocatedAmountDifference;
            state.totalDifference -= allocatedAmountDifference;
          }
        }
      })
      .addCase(UpdateBudget.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getBudget.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getBudget.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";
        state.selectedBudget = action?.payload?.data;
      })
      .addCase(getBudget.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(GetTransactions.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetTransactions.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";
        if (action?.payload?.data) {
          state.transactions = action?.payload?.data?.transactions;
          state.lastTransactionsKey = action?.payload?.data?.lastEvaluatedKey;
        } else {
          state.transactions = [];
          state.lastTransactionsKey = "";
        }
      })
      .addCase(GetTransactions.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(associateTransactionWithBudget.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(associateTransactionWithBudget.fulfilled, (state, action) => {
        state.isFetching = false;
        state.toggleTransactionLinked = !state.toggleTransactionLinked;
        state.successMessage = action?.payload?.message;
        state.updateIsFetching = true;
        state.error = "";
      })
      .addCase(associateTransactionWithBudget.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(StripeFinancialConnectionsSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(StripeFinancialConnectionsSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(StripeFinancialConnectionsSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export const { resetDrawer } = FinanceSlice.actions;

export default FinanceSlice;
