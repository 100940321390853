import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAttachmentTicket } from "../../../../Redux/Feature/Ticket-slice";
import "../../HeaderModals/headerModal.scss";
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
  Modal,
  useTheme,
  Autocomplete,
  TextField,
  FormLabel,
  Paper,
  Popper,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { RootState } from "../../../../Redux/Store";
import { useLocation } from "react-router-dom";
import { AddAttachmentMyAccountSupportTicket } from "../../../../Redux/Feature/MyAccount-slice";
import Toast from "../../../Common/Toast/Toast";
import { styled } from "@mui/material/styles";
import DatePickerComp from "../../../Common/DatePicker/DatePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../../../Utils/utils";
import {
  addVoteAttachment,
  createVote,
} from "../../../../Redux/Feature/Governance-slice";
import { GetAllRules } from "../../../../Redux/Feature/Auth-slice";

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%",
    marginLeft: "-18px",
    marginTop: "1.3vw",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));

interface AddVoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  associationId: string;
  setIsInitialLoad: (value: boolean) => void;
  owners: any[];
}

const groupOptions = [
  { value: MEMBER_TYPE.BOARD, label: "Board" },
  { value: MEMBER_TYPE.GENERAL, label: "General" },
];
const CreateVoteModal: React.FC<AddVoteModalProps> = ({
  isOpen,
  onClose,
  owners,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const uploaderRef = useRef<HTMLInputElement>(null);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [tempFile, setTempFile] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [selectedOwner, setSelectedOwner] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const AllRules: any = useSelector(
    (state: RootState) => state?.Auth?.allRules
  );

  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );
  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  const [voteData, setVoteData] = useState<{
    title: string;
    description: string;
    type: string;
    attachments: any[];
    amount: number;
    persons: any[];
    ruleId: string;
    votingGroup: string;
    startDate: Date | null;
    endDate: Date | null;
  }>({
    title: "",
    description: "",
    type: "Position Change",
    attachments: [],
    amount: 0,
    ruleId: "",
    persons: [],
    votingGroup: "board",
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetAllRules(currentAssociation?.associationId));
    };
    fetchData();
  }, [dispatch, currentAssociation?.associationId]);

  const reset = () => {
    setVoteData({
      title: "",
      description: "",
      type: "Position Change",
      attachments: [],
      amount: 0,
      ruleId: "",
      persons: [],
      votingGroup: "board",
      startDate: null,
      endDate: null,
    });
    setTempFile([]);
  };

  function dialogClose() {
    reset();
    setLoading(false);
    onClose();
  }

  function onInputFocus(args: any) {
    args.target.parentElement.classList.add("e-input-focus");
  }
  function onInputBlur(args: any) {
    args.target.parentElement.classList.remove("e-input-focus");
  }
  function onIconMouseDown(args: any) {
    args.persist();
    setTimeout(() => {
      args.target.classList.add("e-input-btn-ripple");
    }, 300);
  }
  function onIconMouseUp(args: any) {
    args.target.classList.remove("e-input-btn-ripple");
  }
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setVoteData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const typeOptions = [
    "Position Change",
    "Rule Change",
    "Assessment",
    "Budget",
    "Other",
  ];

  const handleDropdownChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (value) {
      setVoteData((prevState) => ({
        ...prevState,
        ruleId: value,
      }));
    }

    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      ruleId: "",
    }));
  };

  const handleGroupChange = (value: string) => {
    setVoteData((prevState) => ({
      ...prevState,
      votingGroup: value,
    }));
  };

  const handleTypeChange = (value: string) => {
    setVoteData((prevState) => ({
      ...prevState,
      type: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const validationData: any = validateForm(voteData);
    if (Object.keys(validationData).length > 0) {
      setValidationErrors(validationData);
      setLoading(false);
    } else {
      try {
        let attachmentKeys: string[] = [];
        if (tempFile.length > 0) {
          attachmentKeys = await Promise.all(
            tempFile.map((elem: any) =>
              dispatch(addVoteAttachment({ fileName: elem.name }) as any).then(
                (res: any) => {
                  console.log("res", res);
                  if (res.payload.statusCode === 200) {
                    const signedUrl = res.payload.data.signedUrl;
                    const key = res.payload.data.s3Key;
                    return fetch(signedUrl, {
                      method: "PUT",
                      body: elem,
                    }).then((response) => {
                      if (response.status === 200) {
                        return key;
                      }
                    });
                  }
                }
              )
            )
          );
        }

        const updatedVoteData = {
          ...voteData,
          attachments: attachmentKeys.map((key) => ({ s3Key: key })),
          associationId: currentAssociation.associationId,
        };
        setVoteData(updatedVoteData);
        console.log("updatedVoteData", updatedVoteData);
        const response = await dispatch(createVote(updatedVoteData));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Vote Created"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
        reset();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };
  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  const validateForm = (data: any) => {
    const errors: any = {};

    if (!data.title.trim()) {
      errors.title = "Title is required";
    }
    if (
      data.type === "Request for Proposal" &&
      data.serviceTypes.length === 0
    ) {
      errors.serviceTypes = "Service Type is required on request for proposal";
    }

    return errors;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    setTempFile([...tempFile, ...Array.from(selectedFiles)]);
  };

  const handleRemoveFile = (indexToRemove: number) => {
    setTempFile((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const handleDateChange = (field: any, value: any) => {
    setVoteData({ ...voteData, [field]: value });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setVoteData({ ...voteData, [name]: value });
  };

  const handleBrowseClick = () => {
    if (uploaderRef.current) {
      uploaderRef.current.click();
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "rgba(0, 105, 202, 1.75)",
    border: `0.713px solid ${theme.palette.activeColor}`,
    boxShadow: 24,
    borderRadius: 8,
    p: 4,
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const autocompleteChipStyle = {
    marginTop: "10px",
    "& .MuiAutocomplete-endAdornment": {
      right: "-10px !important",
    },
    "& input": {
      padding: "0 !important",
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    borderRadius: "10px",
    border: "0.713px solid var(--mui-palette-text-primary)",
    background: "var(--mui-palette-inputBackground)",
    padding: "12px 20px",
    "& .MuiAutocomplete-tag": {
      background: theme.palette.background.default,
      "& svg": {
        color: "#676767 !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      background: "#fff !important",
    },
  };

  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };
  return (
    <>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            className="ticketModel"
            sx={{ ...style, pt: "10px", px: "15px" }}
          >
            <Box className="scroll-wrap">
              <Box
                sx={{ m: "20px 0px 40px 30px" }}
                justifyContent={"space-between"}
                display="flex"
                alignItems={"center"}
                // onClick={dialogClose}
              >
                <p
                  style={{
                    color: `${theme.palette.text.primary}`,
                    fontSize: "30px",
                    fontWeight: "600",
                    textAlign: "center",
                    flex: "1",
                  }}
                >
                  {t("Create Vote")}
                </p>

                <CancelIcon onClick={dialogClose} className="close-icon" />
              </Box>
              <Box>
                <Box>
                  <div className="formDropdownField margin-bottom">
                    <InputLabel id="typeId1">{t("Vote Type")}</InputLabel>
                    <Select
                      labelId="typeId1"
                      id="typeId"
                      value={voteData.type}
                      placeholder={t("Select Type")}
                      name="typeId"
                      displayEmpty
                      onChange={(event) => handleTypeChange(event.target.value)}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: `${theme.palette.textBlack}`,
                          },
                          "& .MuiMenuItem-root.Mui-disabled": {
                            opacity: 0.7,
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: `${theme.palette.selectedItem}`,
                          },
                          "& .MuiSvgIcon-root-MuiSelect-icon": {
                            color: `${theme.palette.textWhite}`,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        {t("Select Vote Type")}
                      </MenuItem>

                      {typeOptions.map((type: string) => (
                        <MenuItem className="SelectBox" key={type} value={type}>
                          {t(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="ticketModel__topField">
                    <Box
                      className="e-input-group "
                      sx={{ width: "100% !important" }}
                    >
                      <label>{t("Title")}</label>
                      <input
                        style={{
                          width: "100%",
                          border: "var(--mui-palette-inputBorderColor)",
                        }}
                        name="title"
                        type="text"
                        value={voteData?.title}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={handleChange}
                        placeholder={t("Enter Title")}
                        color={`${theme.palette.text.primary}`}
                        className="request-support-field"
                      />
                      <span
                        color={`${theme.palette.text.primary}`}
                        className="e-icons e-input-group-icon e-input-popup-date"
                        onMouseDown={onIconMouseDown}
                        onMouseUp={onIconMouseUp}
                      />
                      {validationErrors.title && (
                        <div style={{ color: `${theme.palette.invalid}` }}>
                          {t(validationErrors.title)}
                        </div>
                      )}
                    </Box>
                  </div>
                  <div className="formDropdownField margin-bottom">
                    <InputLabel id="votingGroupId">
                      {t("Voting Group")}
                    </InputLabel>
                    <Select
                      labelId="votingGroupId"
                      id="votingGroupId"
                      value={voteData.votingGroup}
                      placeholder={t("Select Voting Group")}
                      name="votingGroupId"
                      displayEmpty
                      onChange={(event) =>
                        handleGroupChange(event.target.value)
                      }
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: "text.primary",
                          },
                          "& .css-olsweu-MuiButtonBase-root-MuiButton-root": {
                            color: `${theme.palette.text.primary}`,
                            border: `1px solid ${theme.palette.activeColor}`,
                            width: "100%",
                            backgroundColor: `${theme.palette.activeColor}`,
                            display: "block",
                            textAlign: "center",
                            margin: "8px",
                            "&:hover": {
                              color: `${theme.palette.text.primary}`,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled className="SelectBox">
                        {t("Select Voting Group")}
                      </MenuItem>

                      {groupOptions.map((votingGroup: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={votingGroup.value}
                          value={votingGroup.value}
                        >
                          {t(votingGroup.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {(voteData.type === "Rule Change" ||
                    voteData.type === "Other") && (
                    <div className="formDropdownField margin-bottom">
                      <InputLabel id="ruleId">{t("Rule")}</InputLabel>
                      <Select
                        labelId="ruleId"
                        id="ruleId"
                        value={voteData.ruleId}
                        placeholder={t("Select Rule")}
                        name="rule"
                        displayEmpty
                        onChange={handleDropdownChange}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          sx: {
                            "& .MuiMenu-paper": {
                              backgroundColor: `${theme.palette.textWhite}`,
                              color: "text.dark",
                            },
                            "& .css-olsweu-MuiButtonBase-root-MuiButton-root": {
                              color: `${theme.palette.textWhite}`,
                              border: `1px solid ${theme.palette.activeColor}`,
                              width: "100%",
                              backgroundColor: `${theme.palette.activeColor}`,
                              display: "block",
                              textAlign: "center",
                              margin: "8px",
                              "&:hover": {
                                color: `${theme.palette.textWhite}`,
                              },
                            },
                          },
                        }}
                      >
                        {AllRules?.map((rule: any) => (
                          <MenuItem
                            className="SelectBox"
                            key={rule.ruleId}
                            value={rule.ruleId}
                          >
                            {t(rule.title)}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  {(voteData.type === "Position Change" ||
                    voteData.type === "Other") && (
                    <Box>
                      <div className="formDropdownField margin-bottom">
                        <FormLabel
                          sx={{ fontSize: "20px", color: "primary.dark" }}
                        >
                          {t("Owners")}
                        </FormLabel>

                        <Autocomplete
                          PopperComponent={MuiDropDownPopper}
                          sx={autocompleteChipStyle}
                          multiple
                          id="vendorsId1"
                          options={owners}
                          getOptionLabel={(option) => option?.name || ""}
                          onChange={(event, newValue) => {
                            const selectedIds = newValue.map(
                              (owner: any) => owner.personId
                            );
                            setSelectedOwner(selectedIds);
                            setVoteData((prevState) => ({
                              ...prevState,
                              persons: selectedIds,
                            }));
                          }}
                          value={owners?.filter((owner: any) =>
                            selectedOwner.includes(owner.personId)
                          )}
                          filterSelectedOptions
                          PaperComponent={(props) => (
                            <Paper sx={autoCompletePaperStyle} {...props} />
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("Select Owners")}
                              // fullWidth
                            />
                          )}
                        />
                      </div>
                    </Box>
                  )}
                  {(voteData.type === "Assessment" ||
                    voteData.type === "Other") && (
                    <Box sx={{ marginBottom: "16px" }}>
                      <label>{t("Amount")}</label>
                      <OutlinedInput
                        id="amount"
                        name="amount"
                        placeholder={t("Amount")}
                        fullWidth
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={handleChange}
                        className="add-document-amount"
                        sx={{ width: "50%" }}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                      {validationErrors.amount && (
                        <div style={{ color: `${theme.palette.invalid}` }}>
                          {t(validationErrors.amount)}
                        </div>
                      )}
                    </Box>
                  )}
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "16px",
                        width: "100% !important",
                      }}
                    >
                      <label>
                        <Typography
                          id="assetId1"
                          sx={{ marginLeft: "16px", marginRight: "16px" }}
                        >
                          {t("Start Date")}
                        </Typography>
                      </label>
                      <DatePickerComp
                        disablePast={true} // Enable this to disallow past dates
                        disableFuture={false} // Disable this to allow future dates
                        isEditMode={isEditMode}
                        className="custom-datepicker"
                        value={
                          voteData.startDate ? dayjs(voteData.startDate) : null
                        }
                        onChange={(newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          handleDateChange("startDate", formattedDate);
                        }}
                      />
                      <label>
                        <Typography
                          id="assetId1"
                          sx={{ marginLeft: "16px", marginRight: "16px" }}
                        >
                          {t("End Date")}
                        </Typography>
                      </label>
                      <DatePickerComp
                        disablePast={true} // Enable this to disallow past dates
                        disableFuture={false} // Disable this to allow future dates
                        isEditMode={isEditMode}
                        className="custom-datepicker"
                        value={
                          voteData.endDate ? dayjs(voteData.endDate) : null
                        }
                        onChange={(newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          handleDateChange("endDate", formattedDate);
                        }}
                      />
                    </Box>
                  </Box>
                  <div className="formField">
                    <label>{t("Description")}</label>
                    <textarea
                      className="textAreaInput"
                      name="description"
                      value={voteData?.description || ""}
                      onChange={handleChange}
                      placeholder={t("Write your Description here...")}
                    />
                  </div>
                  <div className="fileUploaderBox">
                    <label>{t("Attachments")}</label>
                    <Button
                      variant="outlined"
                      className="browseButton"
                      onClick={handleBrowseClick}
                    >
                      {t("Browse")}
                    </Button>
                    <input
                      ref={uploaderRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      multiple
                    />
                  </div>
                  {tempFile.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "20px 0",
                      }}
                    >
                      {tempFile.map((file: any, index: number) => (
                        <div className="selected-image" key={index}>
                          <Box sx={{ display: "contents" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              alt="Selected"
                              className="selected-image-preview"
                            />
                          </Box>

                          <CloseIcon onClick={() => handleRemoveFile(index)} />
                        </div>
                      ))}
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="no-item-selected"
                      >
                        {t("No attachment selected")}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <div className="SubmitBtn ticketModel__footer">
                  <Button
                    className="sBtn e-control e-btn"
                    id="targetButton1"
                    onClick={handleSubmit}
                    disabled={isFetching === true}
                  >
                    {loading ? (
                      <CircularProgress
                        size={23}
                        sx={{ color: `${theme.palette.textWhite}` }}
                      />
                    ) : (
                      t("Submit")
                    )}
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default CreateVoteModal;
