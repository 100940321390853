import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import "../assetModal.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";

interface EditUnitPersonConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  emails: string[];
  isFetching: boolean;
}

const EditUnitPersonConfirmModal: React.FC<EditUnitPersonConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  emails,
  isFetching,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="deleteUniWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon className="close-icon" />
        </Box>
        <Typography
          id="delete-unit-description"
          sx={{ mt: 2, marginBottom: "30px" }}
        >
          {t("Are you sure you want to add")}
          {` ${emails?.length > 0 ? emails[0] : ""} ?`}
        </Typography>

        <Button
          variant="outlined"
          onClick={onConfirm}
          className="deleteUnitButtonYes"
        >
          {isFetching ? (
            <CircularProgress
              size={33}
              sx={{ color: `${theme.palette.activeColor}` }}
            />
          ) : (
            t("Yes")
          )}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          className="deleteUnitButtonCancel"
        >
          {t("Cancel")}
        </Button>
      </Box>
    </Modal>
  );
};

export default EditUnitPersonConfirmModal;
