import { useEffect, useMemo, useState } from "react";
import "./App.css";
import "./style.scss";
import "./modal.scss";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PublicRoute from "./Routes/Public.Route";
import ProtectedRoute from "./Routes/Private.Routes";
import Layout from "./Layout/Layout/Layout";
import PublicLayout from "./Layout/Layout/PublicLayout";
import authSlice from "./Redux/Feature/Auth-slice";
import { getDesignTokens } from "./Theme";
import { CssBaseline } from "@mui/material";
import { updateTheme } from "./Redux/Feature/Theme-slice";
import MyAccount from "./Pages/MyAccount/MyAccount";
import Setting from "./Pages/Settings/Setting";
import { ModalContext } from "./Component/Modals/HeaderModals/Context/ModalContext";
import CreateTicketModal from "./Component/Modals/HeaderModals/CreateTicketModal";
import SupportTickets from "./Pages/Tickets/Tickets";
import UnitDirectory from "./Pages/Assets/Units/UnitDirectory";
import Balances from "./Pages/Finances/Balances/Balances";
import Map from "./Pages/Map/Map";
import CommonAssetsDirectory from "./Pages/Assets/CommonAssets/CommonAssetsDirectory";
import { LoadScript } from "@react-google-maps/api";
import Board from "./Pages/Association/Board/Board";
import Vendors from "./Pages/Association/Vendors/Vendors";
import Ccrs from "./Pages/Governance/Ccrs";
import ByLaws from "./Pages/Governance/ByLaws";
import RulesAndRegulations from "./Pages/Governance/RulesAndRegulations";
import Association from "./Pages/Association/Association/Association";
import { useAuth0 } from "@auth0/auth0-react";
import AuthMiddleScreen from "./Pages/AuthMiddleScreen/AuthMiddleScreen";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import Transactions from "./Pages/Finances/Transactions/Transactions";
import Budgets from "./Pages/Finances/Budgets/Budgets";
import Policies from "./Pages/Governance/Policies";
import axios from "axios";
import "./i18n";
import i18n from "i18next";
import Votes from "./Pages/Governance/Votes";
import Meetings from "./Pages/Manage/Meetings/Meetings";

const publicPage = ["/", "/auth-verify"];

const testMode = JSON.parse(localStorage.getItem("testMode") || "false");
axios.defaults.baseURL = testMode
  ? process.env.REACT_APP_BACKEND_URL_TEST
  : process.env.REACT_APP_BACKEND_URL;

const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY as string;

function App() {
  const userAssociations = useSelector(
    (state: any) => state.Auth.userAssociations
  );
  const language = useSelector((state: any) => state.Auth.user.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const [mode, setMode] = useState<string>("dark");
  const darkMode = useSelector((state: any) => state.Auth.user.darkMode);

  const [show, setShow] = useState({ hideDialog: false });

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user && !isAuthenticated) {
        await loginWithRedirect({
          appState: { returnTo: "/auth-verify" },
          authorizationParams: { connection: "safe-database" },
        });
      }
    })();
  }, [isLoading, !isAuthenticated]);

  const checkUserToken = async () => {
    const userToken = localStorage.getItem("user");
    if (userToken) {
      dispatch(authSlice.actions.updateUser());
    } else {
      localStorage.clear();
    }
  };
  useEffect(() => {
    const isPublicPage = publicPage.includes(pathname);
    if (!isPublicPage) {
      checkUserToken();
    }
    if (localStorage.getItem("zayukTheme")) {
      dispatch(updateTheme());
    } else {
      localStorage.setItem("zayukTheme", "dark");
    }
  });

  useMemo(() => {
    const body = document.body;
    if (darkMode === true) {
      setMode("dark");
      body.classList.remove("light-mode");
      body.classList.add("dark-mode");
    } else {
      setMode("light");
      body.classList.remove("dark-mode");
      body.classList.add("light-mode");
    }
  }, [darkMode]);

  const authorizationToken = localStorage.getItem("safe-token");

  useEffect(() => {
    const personAssociationLength = userAssociations
      ? userAssociations.length
      : 0;
    if (isAuthenticated) {
      if (
        authorizationToken === "" ||
        authorizationToken === null ||
        authorizationToken === undefined
      ) {
        navigation("/auth-verify");
      }
      if (
        personAssociationLength === 0 &&
        !publicPage.includes(pathname) &&
        pathname !== "/setting" &&
        pathname !== "/map"
      ) {
        navigation("/setting");
      } else if (personAssociationLength >= 0 && pathname === "/") {
        navigation("/setting");
      }
    }
  }, [isAuthenticated, pathname, navigation]);

  const theme = extendTheme(getDesignTokens(mode));

  return (
    <>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <ModalContext.Provider value={{ show, setShow }}>
          <LoadScript
            googleMapsApiKey={GoogleMapsApiKey}
            libraries={["places"]}
          >
            <Routes>
              <Route element={<PublicLayout />}>
                <Route element={<PublicRoute />}>
                  <Route path="/auth-verify" element={<AuthMiddleScreen />} />
                </Route>
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route element={<Layout />}>
                  <Route path="/">
                    <Route index path="account" element={<MyAccount />}></Route>
                  </Route>
                  <Route path="/finances">
                    <Route
                      index={true}
                      path="balances"
                      element={<Balances />}
                    />
                    <Route
                      index={false}
                      path="transactions"
                      element={<Transactions />}
                    />
                    <Route index={false} path="budgets" element={<Budgets />} />
                  </Route>
                  <Route path="/manage">
                    <Route
                      index={true}
                      path="meetings"
                      element={<Meetings />}
                    />
                    <Route
                      index={false}
                      path="tickets"
                      element={<SupportTickets />}
                    />
                  </Route>
                  <Route path="/map">
                    <Route index element={<Map />} />
                  </Route>
                  <Route path="/assets">
                    <Route
                      index={false}
                      path="directory"
                      element={<UnitDirectory />}
                    />
                    <Route
                      index={false}
                      path="common-assets-directory"
                      element={<CommonAssetsDirectory />}
                    />
                  </Route>
                  <Route path="/setting">
                    <Route index element={<Setting />}></Route>
                  </Route>

                  <Route path="/association">
                    <Route
                      index
                      path="details"
                      element={<Association />}
                    ></Route>
                    <Route index={false} path="board" element={<Board />} />
                    <Route index={false} path="vendors" element={<Vendors />} />
                  </Route>
                  <Route path="/governance">
                    <Route index={false} path="votes" element={<Votes />} />
                    <Route index={false} path="ccrs" element={<Ccrs />} />
                    <Route index={false} path="bylaws" element={<ByLaws />} />
                    <Route
                      index={false}
                      path="rulesandregulations"
                      element={<RulesAndRegulations />}
                    />
                    <Route
                      index={false}
                      path="policies"
                      element={<Policies />}
                    />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </LoadScript>
          <CreateTicketModal />
        </ModalContext.Provider>
      </CssVarsProvider>
    </>
  );
}

export default App;
