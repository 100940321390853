import * as React from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { PersonAdd, Settings, Logout } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";
import ConfirmLogout from "../../Component/Modals/ConfirmLogout";
import { useTranslation } from "react-i18next";
import ThemeSwitchBtn from "../../Component/Common/ThemeSwitch/ThemeSwitchBtn";
import FeedbacKModal from "../../Component/Modals/HeaderModals/FeedbackModal";
import FeedbackIcon from "@mui/icons-material/Feedback";

const ProfileDropdown = (props: any) => {
  // State to handle menu visibility
  const { userData, theme, showToast, setLoading } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          background: `${theme.palette.drawerBackground}`,
        }}
      >
        <Tooltip title={t("Profile Menu")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              src={userData.profileImageUrl}
              sx={{ width: 32, height: 32 }}
            >
              {userData.email[0]?.toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              background: `${theme.palette.drawerBackground}`,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
                background: `${theme.palette.drawerBackground}`,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {userData.organizationId ? (
          <MenuItem
            onClick={() => {
              navigate("/map");
              handleClose();
            }}
          >
            <ListItemIcon>
              <MapIcon fontSize="small" />
            </ListItemIcon>
            {t("Map")}
          </MenuItem>
        ) : (
          <ListItemButton disabled>
            <ListItemIcon
              sx={{
                minWidth: "35px",
              }}
            >
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary={t("Available to Vendors")} />
          </ListItemButton>
        )}
        <MenuItem
          onClick={() => {
            navigate("/setting");
            handleClose();
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("Settings")}
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <ThemeSwitchBtn
            showToast={showToast}
            userData={userData}
            setLoading={setLoading}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowFeedback(true);
          }}
        >
          <ListItemIcon>
            <FeedbackIcon fontSize="small" />
          </ListItemIcon>
          {t("Feedback")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowConfirm(true);
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <ConfirmLogout
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
      />
      <FeedbacKModal
        isOpen={showFeedback}
        onClose={() => setShowFeedback(false)}
        showToast={showToast}
      />
    </React.Fragment>
  );
};

export default ProfileDropdown;
